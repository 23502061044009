import { useCallback, useMemo, useState } from "react";
import { getNew3dPiecesWithModifiedPositioning } from "utils/garment";

const useGarmentPositioning = (editedGarment, setValues, errors) => {
  const [positioningFilter, setPositioningFilter] = useState("all");

  /* ******* METHODS ******* */

  const changePositioning = useCallback(
    (piece3dId, newValues) => {
      const new3dPieces = getNew3dPiecesWithModifiedPositioning(
        editedGarment,
        piece3dId,
        newValues
      );
      setValues({ ...editedGarment, "3d_pieces": new3dPieces });
    },
    [editedGarment, setValues]
  );

  const getPositioningError = useCallback(
    (piece3dId, fieldKey) => {
      return errors?.positioning?.find(
        (e) => e.piece3dId === piece3dId && e.fieldKey === fieldKey
      );
    },
    [errors]
  );

  const getHasPositioningError = useCallback(
    (piece3dId) => {
      if (!piece3dId) {
        return errors?.positioning?.length > 0;
      } else {
        return errors?.positioning?.some((e) => e.piece3dId === piece3dId);
      }
    },
    [errors]
  );

  const sorted3dPieces = useMemo(() => {
    return {
      all: editedGarment?.["3d_pieces"] ?? [],
      notPositioned:
        editedGarment?.["3d_pieces"].filter((p) =>
          getHasPositioningError(p.id)
        ) ?? [],
      positioned:
        editedGarment?.["3d_pieces"].filter(
          (p) => !getHasPositioningError(p.id)
        ) ?? [],
    };
  }, [editedGarment, getHasPositioningError]);

  return {
    positioningFilter,
    setPositioningFilter,
    sorted3dPieces,
    changePositioning,
    getPositioningError,
    getHasPositioningError,
  };
};

export default useGarmentPositioning;
