import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ImagePreview = ({ src }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, [src]);

  return (
    <div
      className={`relative w-full h-0 pt-[100%] bg-gray-200 rounded-md overflow-hidden`}
    >
      {src && (
        <>
          {isLoading && (
            <div className="absolute inset-0 w-full h-full flex items-center justify-center bg-[rgba(255,255,255,0.7)] z-10 text-xs">
              {t("loading")}
            </div>
          )}
          <img
            className="absolute inset-0 object-cover"
            src={src}
            alt="preview"
            loading="lazy"
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default ImagePreview;
