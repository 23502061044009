import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { makeGarmentDataQueryOptions } from "api/react-query/queryFunctions";
import {
  makeGarmentMutationOptions,
  makeValidateGarmentMutationOptions,
} from "api/react-query/mutationFunctions";
import { useModal } from "context/ModalProvider";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { removeUnsavedGarment } from "store";
import { getChangesObject } from "utils/garment";
import useGarmentPieces from "./useGarmentPieces";
import useGarmentPositioning from "./useGarmentPositioning";
import usePreventLooseUnsavedData from "./usePreventLooseUnsavedData";
import useGarmentStitching from "./useGarmentStitching";
import useGarmentPreview from "./useGarmentPreview";
import useGarmentPartnerAndBrandIds from "hooks/useGarmentPartnerAndBrandIds";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useErrors from "./useErrors";

const CurrentGarmentContext = createContext({});

export const useCurrentGarmentContext = () => useContext(CurrentGarmentContext);

export const CurrentGarmentProvider = ({ children }) => {
  const { garmentId, partnerId } = useGarmentPartnerAndBrandIds();
  const { state } = useLocation();
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // FETCH garment from API
  const {
    isInitialLoading: isGarmentInitialLoading,
    isFetching: isGarmentFetching,
    isFetched: isGarmentFetched,
    data: garment,
  } = useQuery(makeGarmentDataQueryOptions(garmentId, partnerId));

  // MUTATION requests
  const patchGarmentMutation = useMutation(
    makeGarmentMutationOptions(garmentId, partnerId)
  );
  const validateGarmentMutation = useMutation(
    makeValidateGarmentMutationOptions(garmentId, partnerId)
  );

  // Local state of garment
  const { handleSubmit, handleReset, setValues, values } = useFormik({
    initialValues: garment, // Initialize formik state into garment from cache if already fetched or undefined
    enableReinitialize: true,
    onSubmit: async (values) => {
      await patchGarmentMutation.mutateAsync(values);
      dispatch(removeUnsavedGarment(garmentId));
    },
  });

  const { errors, getIsValidValues } = useErrors(values);

  useEffect(() => {
    // Initialize data if not already unsaved data or garment cache when the garment just finished loading
    if (!values && isGarmentFetched) {
      setValues(garment);
    }
  }, [isGarmentFetched, garment, values, setValues]);

  const hasChanges = useMemo(() => {
    return getChangesObject(values, garment);
  }, [garment, values]);

  /* ******* METHODS ******* */

  const changeEditedGarment = useCallback(
    (changes) => {
      const newValues = { ...values, ...changes };
      setValues(newValues);
    },
    [values, setValues, isGarmentInitialLoading]
  );

  const resetChanges = useCallback(() => {
    showModal("error", {
      titleKey: "notSaveCancelTitle",
      messageKey: "notSaveCancelMsg",
      onValidate: () => {
        handleReset();
        dispatch(removeUnsavedGarment(garment.garment_id));
      },
      onCancel: () => {},
      isDestructive: true,
    });
  }, [garment, isGarmentInitialLoading]);

  const saveChanges = useCallback(() => {
    // Unvalidate garment
    setValues((values) => ({ ...values, workshop_status: "IN_REVIEW" }));
    if (getIsValidValues()) {
      handleSubmit();
    } else {
      showModal("warning", {
        titleKey: "garmentSavingWithErrorTitle",
        messageKey: "garmentSavingWithErrorMsg",
        onValidate: () => {
          handleSubmit();
        },
        onCancel: () => {},
        validateKey: "saveChanges",
        isDestructive: true,
      });
    }
  }, [getIsValidValues, handleSubmit, showModal]);

  const validateGarment = useCallback(() => {
    if (isGarmentInitialLoading) {
      toast.error(t("garmentIsFetchingError"));
    } else {
      // Check if there aren't any errors
      if (!getIsValidValues()) {
        showModal("error", {
          titleKey: "garmentValidationWithErrorTitle",
          messageKey: "garmentValidationWithErrorMsg",
        });
      } else {
        validateGarmentMutation.mutateAsync(
          {},
          {
            onError: () => {
              showModal("error", {
                titleKey: "garmentValidationErrorTitle",
                messageKey: "garmentValidationErrorMsg",
              });
            },
            onSuccess: () => {
              showModal("success", {
                titleKey: "garmentValidationSuccessTitle",
                messageKey: "garmentValidationSuccessMsg",
              });
            },
          }
        );
      }
    }
  }, [isGarmentInitialLoading, getIsValidValues, validateGarmentMutation]);

  // Manage not saved data
  usePreventLooseUnsavedData(
    garmentId,
    changeEditedGarment,
    values,
    hasChanges,
    isGarmentFetched
  );

  // State and methods for garment pieces page (key dxf_pieces)
  const piecesDataAndMethods = useGarmentPieces(
    values,
    setValues,
    changeEditedGarment,
    errors
  );

  // State and methods for positioning page (key 3d_pieces)
  const positioningsDataAndMethods = useGarmentPositioning(
    values,
    setValues,
    errors
  );

  // State and methods for stitching page (key stitches)
  const stitchingDataAndMethods = useGarmentStitching(
    values,
    setValues,
    errors,
    positioningsDataAndMethods.sorted3dPieces.positioned
  );

  // State and methods for preview page
  const previewDataAndMethods = useGarmentPreview(garment, hasChanges);

  return (
    <CurrentGarmentContext.Provider
      value={{
        // Global garment methods and states
        isGarmentInitialLoading,
        isGarmentFetching,
        isDataReady: Boolean(garment) || Boolean(values),
        garment: garment || state, // Fetched garment or data from navigation state
        editedGarment: values || {},
        errors,
        patchGarment: patchGarmentMutation.mutate,
        changeEditedGarment,
        hasChanges,
        resetChanges,
        saveChanges,
        validateGarment,
        isValidating: validateGarmentMutation.isLoading,
        // Pieces methods and states
        ...piecesDataAndMethods,
        // Positioning methods and states
        ...positioningsDataAndMethods,
        // Stitching methods and states
        ...stitchingDataAndMethods,
        // Preview methods and states
        ...previewDataAndMethods,
      }}
    >
      {children}
    </CurrentGarmentContext.Provider>
  );
};
