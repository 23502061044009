import React, { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useGarments } from "context/GarmentsProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SpinnerAnimatedSvg from "components/SpinnerAnimatedSvg";
import { BrandIcon, GarmentIcon, PartnerIcon } from "constants/icons";
import { pageUrls } from "routes/paths";
import Dropdown from "components/dropdown/Dropdown";

function SearchForm({ placeholder, align = "right" }) {
  const { t } = useTranslation();

  const { query, setQuery, searchLoading, searchResults } = useGarments();
  const [searchOpen, setSearchOpen] = useState(false);

  // Open search modal
  useEffect(() => {
    if (query) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
    }
  }, [query]);

  return (
    <div id="action-search" className="relative">
      {/* Modal dialog */}
      <Dropdown
        dropdownOpen={searchOpen}
        setDropdownOpen={setSearchOpen}
        onClickOutside={() => {
          setSearchOpen(false);
          setQuery("");
        }}
        align="right"
        customClassName="md:min-w-[400px] p-4"
        buttonComponent={
          <>
            <label htmlFor="search-input" className="sr-only">
              Search
            </label>
            <input
              id="search-input"
              className="form-input pl-9 focus:border-gray-300 xs:w-[300px]"
              type="search"
              placeholder={placeholder}
              value={query}
              onChange={({ target: { value } }) => setQuery(value)}
            />
            <div
              className="absolute inset-0 right-auto pointer-events-none flex justify-center my-2 px-3"
              aria-label="Search"
            >
              <FiSearch className="w-4 h-4 shrink-0 stroke-current text-gray-400 group-hover:text-gray-500 self-center" />
            </div>
          </>
        }
      >
        {searchLoading ? (
          <div className="flex items-center justify-center w-full gap-2">
            <SpinnerAnimatedSvg className="self-center" />
            <span className="text-sm text-gray-400">{t("loading")}</span>
          </div>
        ) : (
          <div className="mb-3 last:mb-0">
            <SearchFormSections {...{ searchResults }} />
          </div>
        )}
      </Dropdown>
    </div>
  );
}

export default SearchForm;

const SearchFormSections = ({ searchResults }) => {
  const { t } = useTranslation();
  const translateKeys = {
    garments: "garment",
    partners: "partner",
    brands: "brand",
  };

  return (
    <>
      {Object.keys(searchResults).map((s) => {
        return (
          <div key={s} className="mb-2 last:mb-0">
            <SearchFormModalTitle title={t(translateKeys[s], { count: 2 })} />
            <ul className="text-sm gap-2">
              {searchResults[s].items.length > 0 ? (
                searchResults[s].items.slice(0, 4).map((item, i) => {
                  return (
                    <SearchFormModalItem
                      key={"query-result" + i}
                      {...{ item }}
                      type={s}
                    />
                  );
                })
              ) : (
                <SearchFormModalNoItem />
              )}
            </ul>
          </div>
        );
      })}
    </>
  );
};

const SearchFormModalTitle = ({ title }) => {
  return (
    <div className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">
      {title}
    </div>
  );
};

const SearchFormModalItem = ({ item, type }) => {
  const { changeFilters, setQuery } = useGarments();
  const navigate = useNavigate();

  const config = useMemo(
    () => ({
      garments: {
        onClick: () => {
          navigate(
            pageUrls.garment.general.replace(":garment_id", item.garment_id),
            {
              state: {
                garment_id: item.garment_id,
                name: item.name,
                reviewStatus: item.reviewStatus,
              },
            }
          );
          setQuery("");
        },
        label: item.name,
        Icon: GarmentIcon,
      },
      partners: {
        onClick: () => {
          changeFilters("partner", item);
          setQuery("");
        },
        label: item.name,
        Icon: PartnerIcon,
      },
      brands: {
        onClick: () => {
          changeFilters("brand", item);
          setQuery("");
        },
        label: item.name,
        Icon: BrandIcon,
      },
    }),
    [type, item]
  );

  const Icon = config[type].Icon;

  return (
    <li>
      <button
        className="flex w-full items-center p-2 text-gray-800 hover:text-white hover:bg-blue-avumi-300 rounded group"
        onClick={config[type].onClick}
      >
        <Icon className="w-4 h-4 text-blue-avumi-300 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" />
        <span>{config[type].label}</span>
      </button>
    </li>
  );
};

const SearchFormModalNoItem = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center p-2 pt-0 text-gray-400">
      {t("noResults")}
    </div>
  );
};
