import { useGarments } from "context/GarmentsProvider";
import { useLocation } from "react-router-dom";

/**
 * This hooks can be use inside any component in /garment/{garment_id}/* and returns partnerId, garmentId and brandId
 */
const useGarmentPartnerAndBrandIds = () => {
  const { state, pathname } = useLocation();
  const { selectedPartner } = useGarments();

  if (!pathname.includes("garment/")) {
    console.error(
      ">>> useGarmentPartnerAndBrandIds should only be used inside garment/* page. The error come from component inside this root : ",
      pathname
    );
  }

  return {
    garmentId: state.garment_id || pathname.split("/")[2],
    brandId: state.brand_id,
    partnerId: selectedPartner?.id,
  };
};

export default useGarmentPartnerAndBrandIds;
