import colorVariantsClass from "./colorVariantsButtons";
import useNavigateTo from "./useNavigateTo";

const IconsButton = ({
  children,
  buttonRef,
  onClick: onClickHandler,
  to,
  replace,
  colorVariant = "grey",
  className,
  ...rest
}) => {
  const onClick = useNavigateTo(to, onClickHandler, replace);

  return (
    <button
      ref={buttonRef}
      className={`btn w-[38px] h-[38px] p-0 ${
        colorVariantsClass[colorVariant]
      } ${className || ""}`}
      {...{ onClick }}
      {...rest}
    >
      {children}
    </button>
  );
};

export default IconsButton;
