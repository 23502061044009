import React from "react";

import NotFoundImage from "images/404-illustration.svg";
import BigButton from "components/buttons/BigButton";
import { useTranslation } from "react-i18next";
import { pageUrls } from "routes/paths";

function PageNotFound() {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="max-w-2xl m-auto mt-16">
              <div className="text-center px-4">
                <div className="inline-flex mb-8">
                  <img
                    src={NotFoundImage}
                    width="300"
                    height="300"
                    alt="404 illustration"
                  />
                </div>
                <div className="mb-6">{t("notFoundLabel")}</div>
                <div className=" flex items-center justify-center mt-6">
                  <BigButton
                    to={pageUrls.home}
                    label={t("goBackDashboard")}
                    replace
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PageNotFound;
