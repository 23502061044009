import { useLayoutEffect, useRef, useState } from "react";
import { HiOutlineEyeSlash, HiOutlineEye } from "react-icons/hi2";

const InputText = ({
  title,
  type,
  id,
  disabled,
  error,
  inputClassName,
  customWidthClass,
  onChange,
  value,
  ...rest
}) => {
  const inputRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [selection, setSelection] = useState(null);

  useLayoutEffect(() => {
    if (selection && inputRef.current && type === "text") {
      [inputRef.current.selectionStart, inputRef.current.selectionEnd] =
        selection;
    }
  }, [selection]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    onChange(e);
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  return (
    <div className={customWidthClass ? customWidthClass : ""}>
      <label className="block text-sm text-gray-500 font-medium mb-1">
        {title}
      </label>
      <div className="relative">
        <input
          ref={inputRef}
          className={`form-input w-full ${
            error
              ? "!border-red-300 hover:!border-red-500 !placeholder-red-700 focus:!border-red-500"
              : ""
          } ${type === "password" ? "pr-9" : ""} ${inputClassName}`}
          {...{ disabled, value }}
          {...rest}
          onChange={handleChange}
          type={type === "password" && showPassword ? "text" : type}
        />

        {type === "password" && (
          <button
            className="icon_button absolute top-0 bottom-0 right-1 w-6"
            onClick={handleClickShowPassword}
            type="button"
          >
            <div className="absolute inset-0 flex items-center justify-center ">
              {showPassword ? (
                <HiOutlineEyeSlash className="h-5 w-5 " />
              ) : (
                <HiOutlineEye className="h-5 w-5 " />
              )}
            </div>
          </button>
        )}
      </div>
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default InputText;
