import { useTranslation } from "react-i18next";
import SearchForm from "components/inputs/SearchForm";

import ReviewStatusFilters from "components/filters/ReviewStatusFilters";
import DropdownSelector from "components/dropdown/DropdownSelector";
import { useSelector } from "react-redux";
import { useGarments } from "context/GarmentsProvider";

const HomePageHeader = ({ title }) => {
  const { t } = useTranslation();
  const { partners } = useSelector((state) => state.referential);
  const { selectedPartner, setSelectedPartner } = useGarments();

  return (
    <>
      <div className="sm:flex sm:justify-between sm:items-center mb-5">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            {title} ✨
          </h1>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <DropdownSelector
            options={partners}
            getLabelInputFromOption={(o) => o.name}
            getLabelOptionFromOption={(o) => o.name}
            selectedOption={selectedPartner}
            getIsSelectedFromOption={(o) => o.id === selectedPartner.id}
            onChange={(o) => setSelectedPartner(o)}
            customWidthClass="w-50"
          />
          {/* Search form */}
          <SearchForm placeholder={t("searchPlaceholder")} />
        </div>
      </div>
      <div className="flex flex-wrap items-center mb-5 ">
        <span className="ml-2 text-xs font-semibold text-gray-400 uppercase">
          Filters |
        </span>
        <ReviewStatusFilters />
      </div>
    </>
  );
};

export default HomePageHeader;
