import readableTranslations from "./readableTranslations";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const formatTranslations = (translations = {}) => {
  const output = {};
  Object.keys(translations).forEach((word) => {
    const wordTranslations = translations[word];
    Object.keys(wordTranslations).forEach((language) => {
      if (!output[language]) {
        output[language] = {};
      }

      if (typeof translations[word][language] === "object") {
        // plurals objects
        const pluralsObj = translations[word][language];
        Object.keys(pluralsObj).forEach((plural) => {
          const pluralWord = `${word}_${plural}`;
          output[language][pluralWord] = translations[word][language][plural];
        });
        output[language][word] = translations[word][language]["one"];
      } else {
        output[language][word] = translations[word][language];
      }
    });
  });
  return output;
};

const languageObj = formatTranslations(readableTranslations);

export const languages = {
  en: "English",
  fr: "Français",
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: languageObj.en,
      },
      fr: {
        translation: languageObj.fr,
      },
    },
  });

export default i18n;
