import { createSlice } from "@reduxjs/toolkit";
import { clearPrivate } from "store/globalActions";

const initialState = {
  vtoIdInProcess: null, // id of vto in progress after postVto request to launch polling of vto
  // Current selectors of preview page
  selectedPreviewedAvatarId: null,
  selectedPreviewColorVariantId: null,
  // In fitting process
  inFittingProcessAvatarId: null,
  inFittingProcessColorVariantId: null,
  // Previewed
  previewedAvatarId: null,
  previewedColorVariantId: null,
  previewedVtoUrl: null,
};

const userSlice = createSlice({
  name: "previewSlice",
  initialState,
  reducers: {
    setInFittingProcess(state, action) {
      const { colorVariantId, avatarId } = action.payload;
      return {
        ...state,
        inFittingProcessAvatarId: avatarId,
        inFittingProcessColorVariantId: colorVariantId,
      };
    },
    setVtoIdInProcess(state, action) {
      return {
        ...state,
        vtoIdInProcess: action.payload,
      };
    },
    clearInFittingProcess(state) {
      return {
        ...state,
        inFittingProcessAvatarId: null,
        inFittingProcessColorVariantId: null,
        vtoIdInProcess: null,
      };
    },
    setSelectedPreviewedAvatarId(state, action) {
      return {
        ...state,
        selectedPreviewedAvatarId: action.payload,
      };
    },
    setSelectedPreviewColorVariantId(state, action) {
      return {
        ...state,
        selectedPreviewColorVariantId: action.payload,
      };
    },
    setPreviewed(state, action) {
      const { avatarId, colorVariantId, vtoUrl } = action.payload;
      return {
        ...state,
        previewedAvatarId: avatarId,
        previewedColorVariantId: colorVariantId,
        previewedVtoUrl: vtoUrl,
      };
    },
    clearPreviewed(state) {
      return {
        ...state,
        previewedAvatarId: null,
        previewedColorVariantId: null,
        previewedVtoUrl: null,
      };
    },
    clearSelectedPreview(state) {
      return {
        ...state,
        selectedPreviewedAvatarId: null,
        selectedPreviewColorVariantId: null,
      };
    },
  },
  extraReducers: (build) => {
    build.addCase(clearPrivate, (_state, action) => {
      return initialState;
    });
  },
});

const { actions, reducer } = userSlice;
export const {
  setInFittingProcess,
  setVtoIdInProcess,
  clearInFittingProcess,
  setSelectedPreviewedAvatarId,
  setSelectedPreviewColorVariantId,
  setPreviewed,
  clearPreviewed,
  clearSelectedPreview,
} = actions;
export default reducer;
