import { useTranslation } from "react-i18next";
import BadgeIcons from "components/icons/BadgeIcons";
import DropdownInput from "components/inputs/DropdownInput";
import { origins, processes } from "constants/garments";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import InputNumber from "components/inputs/InputNumber";
import { getReadableNullpos } from "utils/garment";
import { useR3fContext } from "context/R3fProvider";
import CheckBox from "components/inputs/CheckBox";

const PositioningCard = ({ piece3d }) => {
  const { highlightedPiece3dId, setHighlightedPiece3dId } = useR3fContext();
  const { getHasPositioningError } = useCurrentGarmentContext();

  const hasError = getHasPositioningError(piece3d.id);

  return (
    <div
      onMouseEnter={() =>
        highlightedPiece3dId !== piece3d.id &&
        setHighlightedPiece3dId(piece3d.id)
      }
      onMouseLeave={() => highlightedPiece3dId && setHighlightedPiece3dId(null)}
      className={`max-w-full text-sm hover:shadow rounded-md border ${
        hasError ? "border-red-300" : "border-gray-200"
      } ${
        highlightedPiece3dId === piece3d.id && hasError
          ? "border-red-500"
          : "border-blue-avumi-300 hover:border-blue-avumi-300"
      }`}
    >
      <div className="flex flex-row flex-1 px-5 py-3 items-center cursor-pointer border-b border-b-gray-200">
        <div className="truncate flex-1">
          <div className="font-medium text-gray-800">
            {piece3d.blender_object_name}
          </div>
        </div>
        <PositioningBadge {...{ hasError }} />
      </div>
      {/* Piece name input */}
      <div id={`description-${piece3d.id}`}>
        <div className="px-2 first:pl-5 last:pr-5 px-1">
          <PositionAxe {...{ piece3d }} direction="left" />
          <PositionAxe {...{ piece3d }} direction="top" />
          <PositionAxe {...{ piece3d }} direction="backward" />
        </div>
      </div>
    </div>
  );
};

export default PositioningCard;

const PositioningBadge = ({ hasError }) => {
  const { t } = useTranslation();
  return (
    <div className=" whitespace-nowrap text-gray-400">
      <div className="flex items-center gap-2">
        {t(hasError ? "notPositioned" : "positioned")}
        <BadgeIcons type={hasError ? "error" : "success"} />
      </div>
    </div>
  );
};

/* Blender axes are the following : 
  left: x,
  top: z,
  backward : y
*/
const AXE_VALUES = {
  left: {
    titleKey: "axeLeftTitle",
    blenderAxe: "x",
    threeAxe: "x",
    colorClassName: "text-orange-700",
    highlightedClassNameOption: "bg-orange-100",
    indexRotation: 0,
  },
  top: {
    titleKey: "axeTopTitle",
    blenderAxe: "z",
    threeAxe: "y",
    colorClassName: "text-blue-700",
    highlightedClassNameOption: "bg-blue-100",
    indexRotation: 2,
  },
  backward: {
    titleKey: "axeBackwardTitle",
    blenderAxe: "y",
    threeAxe: "z",
    colorClassName: "text-green-700",
    highlightedClassNameOption: "bg-green-100",
    indexRotation: 1,
  },
};

const PositionAxe = ({ piece3d, direction }) => {
  const { t } = useTranslation();
  const { changePositioning, getPositioningError } = useCurrentGarmentContext();
  const { nullposes, setHighlightedNullposName, nullposesPositionings } =
    useR3fContext();

  const nullposKey = "nullpos_" + AXE_VALUES[direction].blenderAxe;
  const offsetKey = "offset_" + AXE_VALUES[direction].blenderAxe;
  const rotationKey = "rotation_" + AXE_VALUES[direction].blenderAxe;
  const originKey = "replace_origin_" + AXE_VALUES[direction].blenderAxe;
  const processKey = "process_" + AXE_VALUES[direction].blenderAxe;
  const isXAxis = AXE_VALUES[direction].blenderAxe === "x";

  // Get active nullpose by axe depending of process
  const activeNullpose =
    nullposesPositionings[piece3d.id]?.[AXE_VALUES[direction].threeAxe].name;

  const errorNullpos = getPositioningError(piece3d.id, nullposKey);
  const errorOffset = getPositioningError(piece3d.id, offsetKey);
  const errorRotation = getPositioningError(piece3d.id, rotationKey);
  const errorOrigin = getPositioningError(piece3d.id, originKey);
  const errorProcess = getPositioningError(piece3d.id, processKey);

  return (
    <div className="flex flex-col pt-3 pb-5 flex-wrap border-b border-b-gray-200 last:border-b-0">
      <h3
        className={`text-xs ${AXE_VALUES[direction].colorClassName} font-semibold uppercase tracking-widest mb-1 mt-1`}
      >
        {t(AXE_VALUES[direction].titleKey)}
      </h3>
      <div className="flex flex-row pl-2 gap-3 flex-wrap">
        <div className="w-full">
          <DropdownInput
            title={t("nullpos")}
            rightTitleComponent={
              isXAxis && (
                <CheckBox
                  reverseLabelPosition
                  onChange={(value) =>
                    changePositioning(piece3d.id, {
                      reverse_x: value,
                    })
                  }
                  checked={piece3d.positioning.reverse_x}
                  label={t("reverseXAxis")}
                />
              )
            }
            options={nullposes.map((n) => n.name)}
            getLabelOptionFromOption={getReadableNullpos}
            selectedOptions={piece3d.positioning[nullposKey]}
            onChange={(option) =>
              changePositioning(piece3d.id, {
                [nullposKey]: piece3d.positioning[nullposKey].includes(option)
                  ? piece3d.positioning[nullposKey].filter((o) => o !== option)
                  : [...piece3d.positioning[nullposKey], option],
              })
            }
            onMouseOverOption={(option) => {
              setHighlightedNullposName(option);
            }}
            multiSelectedOptionsCustomStyle={{
              selector: (option) => option === activeNullpose,
              className: AXE_VALUES[direction].highlightedClassNameOption,
            }}
            multiSelect
            getIsSelectedFromOption={(option, selectedOptions) => {
              return selectedOptions.includes(option);
            }}
            fullWidth
            keyTextNoOptions="noNullpos"
            error={t(errorNullpos?.errorKeyText)}
          />
        </div>
        <InputNumber
          label={"offset"}
          value={piece3d.positioning[offsetKey]}
          onChange={(value) =>
            changePositioning(piece3d.id, {
              [offsetKey]: value,
            })
          }
          type="decimal"
          customWidthClass="w-[80px]"
          error={t(errorOffset?.errorKeyText)}
        />

        <InputNumber
          label={"rotation en °"}
          value={
            piece3d.positioning.rotation[AXE_VALUES[direction].indexRotation]
          }
          onChange={(value) => {
            const newRotation = [...piece3d.positioning.rotation];
            newRotation[AXE_VALUES[direction].indexRotation] = value;
            changePositioning(piece3d.id, {
              rotation: newRotation,
            });
          }}
          type="integer"
          customWidthClass="w-[100px]"
          error={t(errorRotation?.errorKeyText)}
        />
        <DropdownInput
          title={t("origin")}
          options={origins}
          selectedOption={piece3d.positioning[originKey] || "center"}
          onChange={(option) =>
            changePositioning(piece3d.id, {
              [originKey]: option,
            })
          }
          customWidthClass="min-w-[100px]"
          error={t(errorOrigin?.errorKeyText)}
        />
        {piece3d.positioning[nullposKey].length > 1 && (
          <DropdownInput
            title={t("process")}
            options={processes[direction]}
            selectedOption={processes[direction].find(
              (o) => o.value === piece3d.positioning[processKey]
            )}
            onChange={(option) =>
              changePositioning(piece3d.id, {
                [processKey]: option.value,
              })
            }
            getLabelOptionFromOption={(option) => t(option.keyText)}
            getLabelInputFromOption={(option) => t(option?.keyText)}
            customWidthClass="min-w-[140px]"
            error={t(errorProcess?.errorKeyText)}
          />
        )}
      </div>
    </div>
  );
};
