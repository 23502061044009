/*
  We use i18n translate function which handle count argument. If you want to use this functionality, you need to add an object instead of the string value of translation with these keys:
  {
      one: `singular sentence`,
      other: `plural sentence`,
      zero: `null sentence`,
   }
*/

const readableTranslations = {
  /********************************
       GENERAL WORDING
    ********************************/
  garment: {
    en: {
      one: "Garment",
      other: "Garments",
    },
    fr: {
      one: "Vêtement",
      other: "Vêtements",
    },
  },
  partner: {
    en: {
      one: "Partner",
      other: "Partners",
    },
    fr: {
      one: "Partenaire",
      other: "Partenaires",
    },
  },
  brand: {
    en: {
      one: "Brand",
      other: "Brands",
    },
    fr: {
      one: "Marque",
      other: "Marques",
    },
  },
  filters: {
    en: "Filters",
    fr: "Filtres",
  },

  loading: {
    en: "Loading...",
    fr: "Chargement...",
  },
  loadingGarment: {
    en: "Garment is loading...",
    fr: "Chargement du vêtement...",
  },
  vtoIsLoading: {
    en: "Preview is loading...",
    fr: "Votre prévisualisation charge...",
  },
  noResults: {
    en: "No results found",
    fr: "Aucun résultat trouvé",
  },
  item: {
    en: {
      one: "item",
      other: "items",
    },
    fr: {
      one: "élément",
      other: "éléments",
    },
  },
  refetch: {
    en: "Refetch",
    fr: "Rafraîchir",
  },
  delete: {
    en: "Delete",
    fr: "Supprimer",
  },
  createdAt: {
    en: "Created at",
    fr: "Créé le",
  },
  approvalAt: {
    en: "Approved at",
    fr: "Approuvé le",
  },
  noDescription: {
    en: "No description",
    fr: "Pas de description",
  },
  error: {
    en: "Error",
    fr: "Erreur",
  },
  all: {
    en: "All",
    fr: "Tout",
  },
  allFemal: {
    en: "All",
    fr: "Toutes",
  },

  uncontrolled: {
    en: "Uncontrolled",
    fr: "Non contrôlé",
  },
  controlled: {
    en: "Controlled",
    fr: "Contrôlé",
  },
  doNotUse: {
    en: "Not used",
    fr: "Non utilisé",
  },
  face: {
    en: "Face",
    fr: "Face",
  },
  several: { fr: "plusieurs", en: "several" },
  noFoundOptions: { fr: "Aucune option trouvée", en: "No found options" },
  nullpos: {
    en: "body point",
    fr: "point du corps",
  },
  origin: {
    en: "origin",
    fr: "origine",
  },
  process: {
    en: "use the",
    fr: "utiliser le plus",
  },
  furthestRight: {
    en: "furthest right",
    fr: "à droite",
  },
  furthestLeft: {
    en: "furthest left",
    fr: "à gauche",
  },
  highest: {
    en: "highest",
    fr: "haut",
  },
  lowest: {
    en: "lowest",
    fr: "bas",
  },
  closest: {
    en: "closest",
    fr: "en avant",
  },
  mostBehind: {
    en: "most behind",
    fr: "en arrière",
  },

  /********************************
       HEADER
    ********************************/
  signOut: {
    en: "Sign out",
    fr: "Se déconnecter",
  },
  avumiUser: {
    en: "Avumi user",
    fr: "Utilisateur Avumi",
  },
  titleEdition: {
    en: "Edition",
    fr: "Édition",
  },

  /********************************
       SIDEBAR
    ********************************/
  sideBarReviewStatusTitle: {
    en: "Review status",
    fr: "Review statut",
  },
  sideBarGarmentEditionTitle: {
    en: "Garment edition",
    fr: "Édition vêtement",
  },
  sideBarRenderingTitle: {
    en: "Rendering",
    fr: "Rendu",
  },
  sideBarGarment: {
    en: "Garment",
    fr: "Vêtement",
  },
  sideBarPieces: {
    en: "Pieces",
    fr: "Pièces",
  },
  sideBarPositioning: {
    en: "Positioning",
    fr: "Positionnement",
  },
  sideBarStitching: {
    en: "Stitching",
    fr: "Coûture",
  },
  sideBarTexture: {
    en: "Texture",
    fr: "Texture",
  },
  sideBarSecondaryPieces: {
    en: "Secondary Pieces",
    fr: "sideBarSecondaryPieces",
  },
  sideBar3dPreview: {
    en: "3D preview",
    fr: "Prévisualisation 3D",
  },

  /********************************
       YUP VALIDATION
    ********************************/
  requiredField: {
    en: "This field is required",
    fr: "Ce champ est requis",
  },
  badMail: {
    en: "Please enter a valid email address",
    fr: "Veuillez entrer une adresse email valide",
  },
  badPasswordPolicy: {
    en: "Must contains at least 6 characters, 1 special character, 1 number, 1 uppercase and lowercase  letter",
    fr: "Doit contenir au moins 6 caractères, 1 caractère spéciale, 1 chiffre, 1 numéro et 1 lettre minuscule et majuscule",
  },

  /********************************
       SIGNIN
    ********************************/
  emailAccount: {
    en: "Office 365 Email",
    fr: "Email Office 365",
  },
  password: {
    en: "Password",
    fr: "Mot de passe",
  },
  oldPassword: {
    en: "Current password",
    fr: "Mot de passe actuel",
  },
  newPasswordRequired: {
    en: "Please enter a new password",
    fr: "Merci de saisir un nouveau mot de passe",
  },
  signIn: {
    en: "Sign in",
    fr: "Se connecter",
  },
  signInError1: {
    en: "There was an error logging you in. Please try again",
    fr: "Une erreur s'est produite lors de la connexion. Veuillez réessayer",
  },
  /********************************
       HOME PAGE
    ********************************/
  homeTitle: {
    en: "Garment",
    fr: "Vêtements",
  },
  searchPlaceholder: {
    en: "Garment, brands, ...",
    fr: "Vêtement, marque, ...",
  },
  loadingGarments: {
    en: "Loading garments...",
    fr: "Chargement des vêtements ...",
  },
  loadingGarmentsError: {
    en: "There was an error loading garments. Please try again",
    fr: "Une erreur s'est produite lors de la chargement des vêtements. Veuillez réessayer",
  },

  /********************************
       GARMENT GENERAL PAGE
    ********************************/
  generalPageInformationTitle: {
    en: "Information",
    fr: "Informations",
  },
  generalPageMeasurementsTitle: {
    en: "Measurements",
    fr: "Mesures",
  },
  garmentTypesTitle: {
    en: "Type / Name",
    fr: "Type / Nom",
  },
  garmentDescriptionTitle: {
    en: "Description",
    fr: "Description",
  },
  garmentNameTitle: {
    en: "Name",
    fr: "Name",
  },
  garmentTopTitle: {
    en: "Piece top direction",
    fr: "Direction du haut de la pièce",
  },
  piecesPagePropertiesTitle: {
    en: "Pieces properties",
    fr: "Propriétés de pièces",
  },
  piecesPagePOIJunctionTitle: {
    en: "POI and Junctions",
    fr: "POI et Junctions",
  },
  pieceQuantityTitle: {
    en: "Quantity",
    fr: "Quantité",
  },
  piecesPoiType: {
    en: "Type",
    fr: "Type",
  },
  measurementsAlert: {
    en: "BEWARE: Modifying the core measurements of the garment impacts the size recommendation and grading of the garment during virtual try-on.",
    fr: "ATTENTION : la modification des mesures de base du vêtement a un impact sur la recommandation de taille et l'ajustement du vêtement lors de l'essayage virtuel.",
  },

  /********************************
       GARMENT PIECES PAGE
    ********************************/
  piecesTypeList: {
    en: "Pieces type list",
    fr: "Types de pièces",
  },
  piecesPageDXFTitle: {
    en: "DXF annotation",
    fr: "DXF annotation",
  },
  poiCoordinates: {
    en: "Coordinates",
    fr: "Coordonnées",
  },
  addPoi: {
    en: "Add POI",
    fr: "Ajouter un POI",
  },
  svgViewerSpacePan: {
    en: "Hold space to move the piece",
    fr: "Maintenir espace pour déplacer la pièce",
  },

  /********************************
   POSITIONING PIECES PAGE
   ********************************/
  positioned: {
    en: "Positioned",
    fr: "Positionné",
  },
  notPositioned: {
    en: "Not positioned",
    fr: "Non positionné",
  },
  positioningPieces: {
    en: "Pieces to be positioned",
    fr: "Pièces à positioner",
  },
  axeLeftTitle: {
    en: "Right",
    fr: "Right",
  },
  axeTopTitle: {
    en: "Top",
    fr: "Top",
  },
  axeBackwardTitle: {
    en: "Back",
    fr: "Back",
  },
  placeholderNullpos: {
    en: "Search nullpos...",
    fr: "Chercher un nullpos...",
  },
  noNullpos: {
    en: "No nullpos found",
    fr: "Aucun nullpos trouvé",
  },
  no3dpieces: {
    en: "There are no pieces to position.\nPlease check that there is at least a piece with type and without error in the pieces menu",
    fr: "Il n'y a aucune pièce à positionner.\nVeuillez vérifier que vous avez à minima une pièce avec un type et sans erreur dans le menu pièces",
  },
  reverseXAxis: {
    en: "Inverse x position",
    fr: "Inverser position x",
  },

  /********************************
   PAGE STICHING
   ********************************/
  no3dStiching: {
    en: "There are no stiching between the selected pieces.",
    fr: "Il n'y a aucune coutûre entre les pièces séléctionnées.",
  },
  no3dStichingForOnePiece: {
    en: "There are selected pieces without stitch. Each pieces must have at least one stitch.",
    fr: "Il y a des pièces sélectionnées sans couture. Chaque pièce doit avoir au moins une couture.",
  },
  onePieceFilterRequired: {
    en: "You need to select at least one piece to create or see stitching",
    fr: "Vous devez sélectionner au moins une pièce pour créer ou voir les coutûres",
  },
  pieceFilter: {
    en: "Pièces",
    fr: "Pieces",
  },
  addStitch: {
    en: "Add stitch",
    fr: "Ajouter une coutûre",
  },

  /********************************
   PAGE NOT FOUND
   ********************************/
  notFoundLabel: {
    en: "Hmm...this page doesn’t exist. You have not used the right needle!",
    fr: "Hmm...cette page n'existe pas. Vous n'avez pas utilisé la bonne aiguille!",
  },
  goBackDashboard: {
    en: "Go back to dashboard",
    fr: "Revenir à la page d'accueil",
  },

  /********************************
       MODAL
    ********************************/
  notSaveCancelTitle: {
    en: "Changes not saved",
    fr: "Modifications non enregistrées",
  },
  notSaveCancelMsg: {
    en: "If you wish to cancel your changes, click on the Validate button below, otherwise click on Cancel to keep your changes.",
    fr: "Si vous souhaitez annuler vos modifications, cliquez sur le bouton Valider ci-dessous, sinon cliquer sur Annuler pour conserver les modifications.",
  },
  unsavedGarmentsTitle: {
    en: "Unsaved changes",
    fr: "Modifications non enregistrées",
  },
  unsavedGarmentsMsg: {
    en: "You have unsaved changes for this garment. Would you like to restore this changes or not ?",
    fr: "Vous avez des changements non sauvés pour ce vêtement. Souhaitez-vous restaurer ces changements ou non?",
  },
  pieceTypeAlreadyUsedTitle: {
    en: "Piece type already used",
    fr: "Type de pièce déjà utilisé",
  },
  pieceTypeAlreadyUsedMsg: {
    en: "If you want add another piece with same type, please use the quantity.",
    fr: "Si vous souhaitez ajouter un autre pièce avec ce type, veuillez utiliser la quantité.",
  },
  poiNameDuplicatedTitle: {
    en: "Duplicated POI name",
    fr: "Doublon de nom de POI",
  },
  poiNameDuplicatedMsg: {
    en: "The name {{name}} has already been used. Please use a different name.",
    fr: "Le nom {{name}} est déjà utilisé. Veuillez en utiliser un autre",
  },
  pieceTypeValidationWithErrorTitle: {
    en: "Impossible to mark the piece as controlled",
    fr: "Impossible de marqué la pièce contrôlé",
  },
  pieceTypeValidationWithErrorMsg: {
    en: "This piece has an error. Please fix it and try again.",
    fr: "Cette pièce a une erreur. Corrigez la et réessayez.",
  },
  garmentValidationWithErrorTitle: {
    en: "Review incomplete or invalid",
    fr: "Review non complète ou invalide",
  },
  garmentValidationWithErrorMsg: {
    en: "This garment has an error or the review isn't complete. Please fix it and try again.",
    fr: "Ce vêtement comporte une erreur ou la review n'est pas complète. Corrigez la et réessayez.",
  },
  garmentSavingWithErrorTitle: {
    en: "Garment not valid",
    fr: "Vêtement non valide",
  },
  garmentSavingWithErrorMsg: {
    en: "This garment contains errors. If you save it, the review will be unvalidated too (if actually validated). Are you sure you want to save with errors?",
    fr: "Ce vêtement comprend des erreurs. Si vous le sauvegardez, la review sera également invalidé (si actuellement validé). Êtes-vous certain de vouloir sauvegarder avec des erreurs ?",
  },
  garmentValidationSuccessTitle: {
    en: "Validation success",
    fr: "Validation réussie",
  },
  garmentValidationSuccessMsg: {
    en: "The garment has been validated successfully.",
    fr: "Le vêtement a été validé avec succès.",
  },
  savingRequiredTitle: {
    en: "Saving required",
    fr: "Sauvegarde requise",
  },
  savingError: {
    en: "Error on garment save",
    fr: "Erreur lors de la sauvegarde de vêtement",
  },
  savingErrorMsg: {
    en: "This piece has an error. Please fix it before saving : ",
    fr: "Cette pièce a une erreur. Corrigez la pièce avant de sauvegarder :",
  },
  poiRemoveTitle: {
    en: "Remove POI",
    fr: "Supprimer le POI",
  },
  poiRemoveMsg: {
    en: "Are you sure you want to remove this poi?",
    fr: "Êtes-vous sûr de vouloir supprimer ce POI?",
  },
  stitchRemoveTitle: {
    en: "Remove stitch",
    fr: "Supprimer la coutûre",
  },
  stitchRemoveMsg: {
    en: "Are you sure you want to remove this stitch?",
    fr: "Êtes-vous sûr de vouloir supprimer cette coutûre?",
  },
  stitchedPoiRemoveMsg: {
    en: {
      one: "This point is used in one stitching! It is recommended to remove this point from the associated stitching before deleting it. Do you still want to delete this point AND THE ATTACHED STITCHING?",
      other:
        "This point is used in {{count}} stitchings! It is recommended to remove this point from all stitchings before deleting it. Do you still want to delete this point AND THE ATTACHED STITCHINGS?",
    },
    fr: {
      one: "Ce poi est utilisé dans une coûture ! Il est conseillé de retirer ce poi de la coutûre en question avant de supprimer ce poi. Voulez-vous quand même supprimer ce poi ET LA COUTÛRE RATTACHÉE ?",
      other:
        "Ce poi est utilisé dans {{count}} coûtures ! Il est conseillé de retirer ce poi de toutes les coutûres avant de supprimer ce poi. Voulez-vous quand même supprimer ce poi ET LES COUTÛRES RATTACHÉES ?",
    },
  },
  stitchingDisabledPieceTitle: {
    en: "Piece not positioned",
    fr: "Pièce non positinnée",
  },
  stitchingDisabledPieceMsg: {
    en: "This piece is not positioned. Please check that the piece's position is correctly configured.",
    fr: "Cette pièce n'est pas positionnée. Veuillez vérifier que la position de la pièce est bien configurée",
  },
  remove3dPiecesStitchedTitle: {
    en: "All pieces stitched",
    fr: "Pièces toutes cousues",
  },
  remove3dPiecesStitchedMsg: {
    en: "We cannot decrease the quantity of this type of piece because all pieces are stitched. Please remove all stitches from at least one of the pieces of this type before reducing the quantity.",
    fr: "Nous ne pouvons pas diminuer la quantité de ce type de pièce car toutes les pièces sont cousues. Veuillez supprimer toutes les coutures d'au moins une des pièces de ce type avant de réduire la quantité.",
  },
  dxfPieceSwitchToUnusedTitle: {
    en: "Warning",
    fr: "Attention",
  },
  dxfPieceSwitchToUnusedMsg: {
    en: "{{pieces3dLength}} piece(s) and {{stitchesLength}} stitche(s) are attached to this piece. Do you really want to delete this piece and lose all associated positioning and stitching configurations?",
    fr: "{{pieces3dLength}} pièce(s) et {{stitchesLength}} coutûre(s) sont rattachées à cette pièce. Voulez-vous vraiment supprimer cette pièce et perde toutes les configurations de positionnement et de coutûres rattachées ?",
  },

  /********************************
       TOAST
   ********************************/
  closeToastWithEsc: {
    en: "ESC key to close this mode",
    fr: "ESC pour quitter ce mode",
  },
  cancelPoiCreationWithEsc: {
    en: "Select the poi position on drawing or press ESC key to cancel",
    fr: "Sélectionnez la position du POI ou appuyez sur ESC pour annuler",
  },
  selectPoi1: {
    en: "Select 1st POI (piece 1 - start)",
    fr: "Sélectionnez 1er POI (pièce 1 - début)",
  },
  selectPoi2: {
    en: "Select 2nd POI (piece 2 - start)",
    fr: "Sélectionnez 2nd POI (pièce 2 - début)",
  },
  selectPoi3: {
    en: "Select 3rd POI ({{pieceName}} - end)",
    fr: "Sélectionnez 3ème POI ({{pieceName}} - fin)",
  },
  selectPoi4: {
    en: "Select 4th POI ({{pieceName}} - end)",
    fr: "Sélectionnez 4ème POI ({{pieceName}} - fin)",
  },
  errorPoiSelectionPiece: {
    en: "You need to select a POI on the {{pieceName}}",
    fr: "Vous devez selectionner un POI surla pièce ({{pieceName}}",
  },
  vpnRequired: {
    en: "The VPN seems to be disabled",
    fr: "Le VPN ne semble pas activé",
  },
  requestError: {
    en: "An error occured while requesting",
    fr: "Une erreur s'est produite lors de la requête",
  },
  dxfPieceIncrementation: {
    en: "Creating a new 3D piece of type {{type}} to position.",
    fr: "Création d'une nouvelle pièce 3D de type {{type}} à positionner.",
  },
  dxfPieceDecrementing: {
    en: "Deleting a 3D piece of type {{type}} not used in the stitches.",
    fr: "Suppression d'une pièce 3D de type {{type}} non utilisée dans les coutures.",
  },
  dxfPieceQtyChangeImpossibleForUnused: {
    en: "Changing the quantity of an unused piece is not possible.",
    fr: "Changer la quantité d'une pièce non-utilisée est impossible.",
  },

  /********************************
       BUTTON
    ********************************/
  clear: {
    en: "Clear",
    fr: "Réinitialiser",
  },
  confirm: {
    en: "Confirm",
    fr: "Confirmer",
  },
  apply: {
    en: "Apply",
    fr: "Appliquer",
  },
  close: {
    en: "Close",
    fr: "Fermer",
  },
  create: {
    en: "Create",
    fr: "Créer",
  },
  cancel: {
    en: "Cancel",
    fr: "Annuler",
  },
  back: {
    en: "Back",
    fr: "Retour",
  },
  saveChanges: {
    en: "Saves changes",
    fr: "Sauvegarder",
  },
  restore: {
    en: "Restore",
    fr: "Récuperer",
  },
  remove: {
    en: "Delete",
    fr: "Supprimer",
  },
  removeAnyway: {
    en: "Remove poi and stitching(s)",
    fr: "Supprimer poi et coutûre(s)",
  },
  markControlledPiece: {
    en: "Mark controlled",
    fr: "Marquer contrôlé",
  },
  markUncontrolledPiece: {
    en: "Mark uncontrolled",
    fr: "Marquer non contrôlé",
  },
  validateGarment: {
    en: "Validate review",
    fr: "Valider la review",
  },
  validatedGarment: {
    en: "Reviewed",
    fr: "Validé",
  },
  preview: {
    en: "Preview",
    fr: "Prévisualiser",
  },

  /********************************
       ERROR
    ********************************/
  garmentIsFetchingError: {
    en: "Garment is fetching please wait...",
    fr: "Vêtement en cours de chargement, veuillez patienter...",
  },
  defaultErrorRequestTitle: {
    en: "Error",
    fr: "Erreur",
  },
  defaultErrorRequestMsg: {
    en: "Oops, an error occured during your request.",
    fr: "Oups, une erreur s'est produite lors de votre requête",
  },
  yupGarmentNotValidTitle: {
    en: "Garment not valid",
    fr: "Vêtement non valide",
  },
  yupMeasurementsNotNumber: {
    en: "The value of the measurement '{{key}}' is not a number.",
    fr: "La valeur de la mesure '{{key}}' n'est pas un nombre",
  },
  dxfPiece_typeNotFilled: {
    en: "The type is missing",
    fr: "Le type est manquant",
  },
  dxfPiece_duplicatedTypes: {
    en: "Type already used for this piece",
    fr: "Type déjà utilisé pour cette pièce",
  },
  dxfPiece_incorrectQuantity: {
    en: "Incorrect quantity",
    fr: "Quantité incorrecte",
  },
  dxfPiece_topPositionNotFilled: {
    en: "Piece top position is missing",
    fr: "Direction du haut de la pièce manquante",
  },
  dxfPiece_poisMin: {
    en: "A used piece should have at least 2 POIs",
    fr: "Une pièce utilisée doit avoir au moins 2 POIs",
  },
  dxfPiece_poiDuplicatedNames: {
    en: "POI's name already used for this piece",
    fr: "Nom du POI déjà utilisé pour cette pièce",
  },
  piece3d_nullposRequired: {
    en: "Min. 1 nullpos is required",
    fr: "Min. 1 nullpos requis",
  },
  piece3d_offsetBadValue: {
    en: "Incorrect value",
    fr: "Valeur incorrecte",
  },
  piece3d_rotationBadValue: {
    en: "Incorrect value",
    fr: "Valeur incorrecte",
  },
  piece3d_originBadValue: {
    en: "Incorrect value",
    fr: "Valeur incorrecte",
  },
  piece3d_processRequired: {
    en: "Required value",
    fr: "Valeur requise",
  },
  piece3d_atLeastOnStitch: {
    en: "Required value",
    fr: "Valeur requise",
  },
  texture_textureDontCoverAllPieces: {
    en: "All pieces of this garment must be referenced by at least one texture of the color varaiant",
    fr: "Toutes les pièces de ce vêtement doivent être référencées par au moins une texture de la variante de couleur. Cette variante n'est plus valide. Supprimez la et créez en une nouvelle.",
  },

  /********************************
       MEASUREMENTS
    ********************************/
  height_cm: {
    en: "Height cm",
    fr: "Hauteur cm",
  },
  neck_circumference: {
    en: "Neck circumference",
    fr: "Circonférence du cou",
  },
  chest_circumference: {
    en: "Chest circumference",
    fr: "Tour de poitrine",
  },
  bicep_circumference: {
    en: "Bicep circumference",
    fr: "Circonférence du bicep",
  },
  waist_circumference: {
    en: "Waist circumference",
    fr: "Tour de taille",
  },
  hip_circumference: {
    en: "Hip circumference",
    fr: "Circonférence des hanches",
  },
  thigh_circumference: {
    en: "Thigh circumference",
    fr: "Tour de cuisse",
  },
  arm_length: {
    en: "Arm length",
    fr: "Longueur des bras",
  },
  upperleg_length: {
    en: "Upperleg length",
    fr: "Longueur de la cuisse",
  },
  upperarm_length: {
    en: "Upperarm length",
    fr: "Longueur du haut du bras",
  },
  neck_to_ankle_vertical_length: {
    en: "Neck to ankle vertical length",
    fr: "Longueur verticale cou-cheville",
  },
  outerleg_length: {
    en: "Outerleg length",
    fr: "Longueur jambe extérieure",
  },
  torso_vertical_length: {
    en: "Torso vertical length",
    fr: "Longueur verticale du torse",
  },
  /********************************
      PAGE PREVIEW
    ********************************/
  noAvatarFound: {
    en: "Sorry, no avatar matches the selected filters.",
    fr: "Désolé, aucun avatar ne correspond aux filtres sélectionnés.",
  },
  avatarFilters: {
    en: "Avatar filters",
    fr: "Filtre avatar",
  },
  textureSelector: {
    en: "Texture",
    fr: "Texture",
  },
  preview_measurements: {
    en: "Measurements",
    fr: "Mesures",
  },
  all_gender: {
    en: "Genders",
    fr: "Genres",
  },
  male: {
    en: "Male",
    fr: "Homme",
  },
  female: {
    en: "Female",
    fr: "Femme",
  },
  all_size: {
    en: "Sizes",
    fr: "Tailles",
  },
  size_XS: {
    en: "XS, 34",
    fr: "XS, 34",
  },
  size_S: {
    en: "S, 36",
    fr: "S, 36",
  },
  size_M: {
    en: "M, 38",
    fr: "M, 38",
  },
  size_L: {
    en: "L, 42",
    fr: "L, 42",
  },
  size_XL: {
    en: "XL, 44",
    fr: "XL, 44",
  },
  all_body_shape: {
    en: "Body shapes",
    fr: "Formes corporelles",
  },
  rectangle: {
    en: "Rectangle",
    fr: "Rectangle",
  },
  triangle: {
    en: "Triangle",
    fr: "Triangle",
  },
  inverted_triangle: {
    en: "Inverted triangle",
    fr: "Triangle inversé",
  },
  circle: {
    en: "Circle",
    fr: "Cercle",
  },
  hourglass: {
    en: "Hourglass",
    fr: "Sablier",
  },
  onlySavedTexture: {
    en: "Only saved textures will be displayed in the preview selector",
    fr: "Seules les textures sauvegardées sont affichées dans le sélecteur de prévisualisation",
  },
  previewNotPossibleModalTitle: {
    en: "Preview not possible",
    fr: "Prévisualisation impossible",
  },
  previewNotPossibleModalMsg: {
    en: "You need to select one texture and one avatar before request a preview.",
    fr: "Vous devez sélectionner une texture et un avatar avant de demander une prévisualisation.",
  },
  previewErrorVtoModalTitle: {
    en: "Preview failed",
    fr: "Échec de la prévisualisation",
  },
  previewErrorVtoModalMsg: {
    en: "An error occured during preview. Please try again.",
    fr: "Une erreur est survenue lors de la prévisualisation. Veuillez réessayer.",
  },
  previewWithUnsavedModalTitle: {
    en: "Unsaved changes detected",
    fr: "Changements non sauvegardés",
  },
  previewWithUnsavedModalMsg: {
    en: "You have unsaved changes. These changes will not be reflected in the preview if you do not save beforehand.",
    fr: "Vous avez des modifications non enregistrées. Ces modifications ne seront pas reflétées dans l'aperçu si vous n'enregistrez pas au préalable.",
  },
  previewError403ModalTitle: {
    en: "Preview expirated",
    fr: "Prévisualisation expirée",
  },
  previewError403ModalMsg: {
    en: "Please launch a new preview",
    fr: "Merci de lancer une nouvelle prévisualisation",
  },
  previewErrorOtherModalTitle: {
    en: "Error with preview",
    fr: "Erreur de prévisualisation",
  },
  previewErrorOtherModalMsg: {
    en: "Please try to launch a new preview.  If the error persists, the problem lies in the generation of the 3D avatar.",
    fr: "Merci de réessayer une nouvelle prévisualisation. Si l‘erreur persiste, c’est que le problème provient de la génération de l’avatar 3D.",
  },
  /********************************
      PAGE Texture
    ********************************/
  createColorVariantModalTitle: {
    en: "Please confirm this action",
    fr: "Veuillez confirmer cette action",
  },
  createColorVariantModalMsg: {
    en: "Textures are saved instantly. Please make sure the information is correct before uploading",
    fr: "Les textures sont enregistrées instantanément. Veuillez vous assurer que les informations sont correctes avant de les télécharger",
  },
  colorVariantSectionTitle: {
    en: "Color variants",
    fr: "Variantes de couleur",
  },
  newColorVariant: {
    en: "Color variant (pending)",
    fr: "Nouvelle variante de couleur (en attente)",
  },
  textureSectionTitle: {
    en: "Textures",
    fr: "Textures",
  },
  logoSectionTitle: {
    en: "Logos",
    fr: "Logos",
  },
  texturePreviewSectionTitle: {
    en: "Preview",
    fr: "Aperçu",
  },
  addTexture: {
    en: "Add texture",
    fr: "Ajouter une texture",
  },
  addLogo: {
    en: "Add logo",
    fr: "Ajouter un logo",
  },
  texture: {
    en: "Texture",
    fr: "Texture",
  },
  logo: {
    en: "Logo",
    fr: "Logo",
  },
  dropFileText: {
    en: "Drag and drop your file or click area above, max size 9mb",
    fr: "Glissez-déposez votre fichier ou cliquez dans la zone ci-dessus, taille maximale 9 Mo",
  },
  noTextureAdded: {
    en: "No texture added",
    fr: "Aucune texture ajoutée",
  },
  noLogoAdded: {
    en: "No logo added",
    fr: "Aucun logo ajouté",
  },
  noLogoSelected: {
    en: "No logo selected",
    fr: "Aucun logo sélectionné",
  },
  texturePiecesTitle: {
    en: "Pieces",
    fr: "Pièces",
  },
  textureTitle: {
    en: "Texture",
    fr: "Texture",
  },
  textureNameTitle: {
    en: "Name",
    fr: "Nom",
  },
  textureSizeTitle: {
    en: "Size (cm)",
    fr: "Taille (cm)",
  },
  textureRotationTitle: {
    en: "Rotation (degrees)",
    fr: "Rotation (degrés)",
  },
  normalMapTitle: {
    en: "Normal Map",
    fr: "Normal Map",
  },
  normalMapTypeTitle: {
    en: "Type",
    fr: "Type",
  },
  normalMapLibraryTitle: {
    en: "Library",
    fr: "Bibliothèque",
  },
  normalMapUploadsTitle: {
    en: "Uploads",
    fr: "Téléchargements",
  },
  textureWidthTitle: {
    en: "Width (cm)",
    fr: "Largeur (cm)",
  },
  textureHeightTitle: {
    en: "Height (cm)",
    fr: "Hauteur (cm)",
  },
  textureLogoPosition: {
    en: "Position",
    fr: "Position",
  },
  texturePlacesSearch: {
    en: "Search position...",
    fr: "Rechercher une position...",
  },
  texturePiecesError: {
    en: "You must create at least one texture for all (.*) places or create several textures for each place",
    fr: "Vous devez créer au moins une texture pour tous les endroits (.*) ou créer plusieurs textures pour chaque endroit",
  },
  fileFormatError: {
    en: "Wrong file format",
    fr: "Format de fichier incorrect",
  },
  fileSizeError: {
    en: "File is too large",
    fr: "Le fichier est trop volumineux",
  },
  fileUploadError: {
    en: "Problem with image upload",
    fr: "Problème de téléchargement de l'image",
  },
  textureColorVariantDeleteSuccess: {
    en: "Color variant deleted",
    fr: "Variante de couleur supprimée",
  },
  textureColorVariantDeleteError: {
    en: "Color variant delete problem",
    fr: "Problème de suppression de la variante de couleur",
  },
  textureColorVariantCreateSuccess: {
    en: "Color variant created",
    fr: "Variante de couleur créée",
  },
  textureColorVariantCreateError: {
    en: "Color variant create problem",
    fr: "Problème de création de la variante de couleur",
  },

  /********************************
    PAGE Secondary pieces
  ********************************/
  spCordsTitle: {
    en: "Cords",
    fr: "Cordes",
  },
  spZippersTitle: {
    en: "Zippers",
    fr: "Fermetures éclair",
  },
  spPocketsTitle: {
    en: "Pockets",
    fr: "Poches",
  },
  spButtonLineTitle: {
    en: "Button Line",
    fr: "Ligne de boutons",
  },
  spPending: {
    en: "Pending...",
    fr: "En attente...",
  },
  spCordTitleName: {
    en: "Cord",
    fr: "Nom de la corde",
  },
  spZipperTitleName: {
    en: "Zipper",
    fr: "Nom de la fermeture éclair",
  },
  spPocketTitleName: {
    en: "Pocket",
    fr: "Nom de la poche",
  },
  spButtonLineTitleName: {
    en: "Button Line",
    fr: "Nom de la ligne de boutons",
  },
  spAddCord: {
    en: "Add Cord",
    fr: "Ajouter une corde",
  },
  spAddZipper: {
    en: "Add Zipper",
    fr: "Ajouter une fermeture éclair",
  },
  spAddPocket: {
    en: "Add Pocket",
    fr: "Ajouter une poche",
  },
  spAddButtonLine: {
    en: "Add Button Line",
    fr: "Ajouter une ligne de boutons",
  },
  spCordName: {
    en: "Cord name",
    fr: "Nom de la corde",
  },
  spCapsName: {
    en: "Caps name",
    fr: "Nom des embouts",
  },
  spPositionName: {
    en: "Position name",
    fr: "Nom de la position",
  },
  spLength: {
    en: "Length (cm)",
    fr: "Longueur (cm)",
  },
  spWidth: {
    en: "Width (cm)",
    fr: "Largeur (cm)",
  },
  spFlatCord: {
    en: "Flat cord",
    fr: "Corde plate",
  },
  spAssignTextureCord: {
    en: "Assign texture cord",
    fr: "Assigner une texture à la corde",
  },
  spAssignTextureCaps: {
    en: "Assign texture caps",
    fr: "Assigner une texture aux embouts",
  },
  spZipperName: {
    en: "Zipper name",
    fr: "Nom de la fermeture éclair",
  },
  spZipperComponentsName: {
    en: "Zipper components name",
    fr: "Nom des composants de la fermeture éclair",
  },
  spStartPositionName: {
    en: "Start position name",
    fr: "Nom de la position de départ",
  },
  spEndPositionName: {
    en: "End position name",
    fr: "Nom de la position de fin",
  },
  spAssignTextureFabric: {
    en: "Assign texture fabric",
    fr: "Assigner une texture au tissu",
  },
  spAssignTextureComponents: {
    en: "Assign texture components",
    fr: "Assigner une texture aux composants",
  },
  spPocketType: {
    en: "Pocket type",
    fr: "Type de poche",
  },
  spSize: {
    en: "Size",
    fr: "Taille",
  },
  spExpandingPocket: {
    en: "Expanding pocket",
    fr: "Poche extensible",
  },
  spPocketName: {
    en: "Pocket Name",
    fr: "Nom de la poche",
  },
  spAssignTexturePocket: {
    en: "Assign texture pocket",
    fr: "Assigner une texture à la poche",
  },
  spFlap: {
    en: "Flap",
    fr: "Rabat",
  },
  spFlapName: {
    en: "Flap name",
    fr: "Nom du rabat",
  },
  spAssignTextureFlap: {
    en: "Assign texture flap",
    fr: "Assigner une texture au rabat",
  },
  spOverstitch: {
    en: "Overstitch",
    fr: "Surpiqûre",
  },
  spSeamsName: {
    en: "Seams name",
    fr: "Nom des coutures",
  },
  spAssignTextureSeams: {
    en: "Assign texture seams",
    fr: "Assigner une texture aux coutures",
  },
  spButtons: {
    en: "Buttons",
    fr: "Boutons",
  },
  spButtonName: {
    en: "Button name",
    fr: "Nom du bouton",
  },
  spAssignTextureButton: {
    en: "Assign texture button",
    fr: "Assigner une texture au bouton",
  },
  spBandName: {
    en: "Band name",
    fr: "Nom de la bande",
  },
  spButtonsName: {
    en: "Buttons name",
    fr: "Nom des boutons",
  },
  spButtonsCount: {
    en: "Buttons count",
    fr: "Nombre de boutons",
  },
  spAssignTextureBand: {
    en: "Assign texture band",
    fr: "Assigner une texture à la bande",
  },
  spAssignTextureButtons: {
    en: "Assign texture buttons",
    fr: "Assigner une texture aux boutons",
  },
  spAssignTextureCapsSearchText: {
    en: "Search...",
    fr: "Rechercher...",
  },
};

export default readableTranslations;
