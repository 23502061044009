import { useTranslation } from "react-i18next";
import GarmentCard from "components/cards/GarmentCard";
import { useGarments } from "context/GarmentsProvider";
import SmallButton from "components/buttons/SmallButton";
import PaginationNumeric from "components/PaginationNumeric";

const GarmentsList = () => {
  const { garments, isLoading, isError, refetch, total } = useGarments();
  const { t } = useTranslation();

  if (isLoading || isError) {
    return (
      <div className="flex flex-col gap-4 h-60 w-full justify-center items-center">
        {isError ? t("loadingGarmentsError") : t("loadingGarments")}
        <SmallButton
          {...{ isLoading }}
          onClick={refetch}
          label={t("refetch")}
          colorVariant="white"
        />
      </div>
    );
  }

  return (
    <div>
      <div className="text-sm text-gray-500 italic mb-4">{` ${new Intl.NumberFormat().format(
        total
      )} ${t("item", {
        count: total,
      })}`}</div>
      <div className="grid grid-cols-1 grid2:grid-cols-2 grid3:grid-cols-3 grid4:grid-cols-4 gap-6">
        {garments &&
          garments.map((g, i) => (
            <GarmentCard key={`garment-${i}`} garment={g} />
          ))}
      </div>
      <PaginationNumeric />
    </div>
  );
};

export default GarmentsList;
