const PROPS_TYPE = {
  integer: {
    step: 1,
    pattern: "[0-9]*",
  },
  decimal: { step: 0.01, pattern: "[0-9]?[0-9]?(.[0-9][0-9]?)?" },
};

const InputNumber = ({
  label,
  id,
  disabled,
  error,
  onChange,
  value,
  type = "integer",
  customWidthClass,
  ...rest
}) => {
  const handleChange = ({
    nativeEvent: {
      target: { value },
    },
  }) => {
    if (value === "") {
      return onChange(0);
    }
    if (/[0-9][0-9]*/.test(value)) {
      onChange(Number(value));
    }
  };

  return (
    <div className={customWidthClass ? customWidthClass : "w-36"}>
      <label className="block text-sm text-gray-500 font-medium mb-1">
        {label}
      </label>
      <div
        className={`relative ${
          error ? "!border-red-300 hover:!border-red-500" : ""
        }`}
      >
        <input
          className={`form-input w-full ${
            error
              ? "!placeholder-red-700 !border-red-400 focus:!border-red-500"
              : ""
          }`}
          onChange={handleChange}
          value={value || 0}
          type="number"
          step={PROPS_TYPE[type].step}
          pattern={PROPS_TYPE[type].pattern}
          {...{ disabled, id }}
          {...rest}
        />
      </div>
      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default InputNumber;
