/**
 * List of scenes
 */
// TODO: clean scene unused
export const scenesConfig = [
  {
    name: "studio",
    keyText: "VFR.Scene.Studio",
    options: {
      ambientLight: {
        color: "#ffffff",
        intensity: 0.2,
      },
      pointLights: [
        {
          color: "#ffffff",
          intensity: 1,
          position: [0.6, 1.26, -0.48],
          decay: 0,
          castShadow: false,
        },
      ],
      spotLights: [
        {
          color: "#ffffff",
          intensity: 1.5,
          position: [0, 1.05, -2.06],
          "target-position": [0, 1, 0],

          distance: 2.7,
          decay: 0,
          castShadow: false,
          angle: 0.8,
          penumbra: 0.7,
        },
        {
          color: "#ffffff",
          intensity: 2,
          position: [0.11, 1.59, 2.1],
          "target-position": [0, 0.92, 0],

          distance: 5.6,
          decay: 0.8,
          castShadow: true,
          angle: 0.8,
          penumbra: 1,
        },
      ],
      directionalLights: [
        {
          position: [-0, 2, 0],
          "target-position": [0, 0, 0],
          color: "#ffffff",
          intensity: 0.5,
          castShadow: false,
        },
      ],
    },
  },
];

export const DEFAULT_OPTION_UPLOADED_SCENE = {
  ambientLight: {
    intensity: 1.3,
    color: "#ffffff",
  },
  pointLights: [
    {
      position: [0.2, 2, -1],
      color: "#ffffff",
      intensity: 3,
      distance: 10,
      castShadow: false,
    },
    {
      position: [1, 0.76, -0.6],
      color: "#ffffff",
      intensity: 2,
      castShadow: true,
    },
    {
      position: [-1.2, 1.5, -0.8],
      color: "#ffffff",
      intensity: 3,
      castShadow: true,
    },
  ],
  spotLights: [
    {
      position: [-0.2, 1.6, 2],
      "target-position": [0, 1, 0],
      color: "#ffffff",
      intensity: 7.7,
      angle: 0.9,
      penumbra: 0.25,
      decay: 1.2,
      isMainLight: true,
      castShadow: true,
    },
  ],
};
