const CheckBox = ({ checked, onChange, label, reverseLabelPosition }) => {
  return (
    <div
      className={`flex ${
        reverseLabelPosition ? "flex-row-reverse" : ""
      } items-center `}
    >
      <input
        {...{ checked }}
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
        className="form-checkbox"
      />
      <span
        className={`text-sm text-gray-500 font-medium ${
          reverseLabelPosition ? "mr-2" : "ml-2"
        }`}
      >
        {label}
      </span>
    </div>
  );
};

export default CheckBox;
