const colorVariantsClass = {
  blue: "text-white bg-blue-avumi-300 border-blue-avumi-300",
  orange: "text-orange-400 bg-orange-100 border-orange-100",
  red: "text-red-500 bg-red-100 border-red-500",
  lightRed: "text-red-500 bg-gray-100 border-red-300",
  green: "text-green-500 bg-green-100 border-green-100",
  grey: "bg-gray-300 text-gray-500 border-gray-500",
  lightGrey: "bg-gray-100 text-gray-400 border-gray-200",
  white: "bg-white text-gray-500 border-gray-200",
};

export const colorVariantsClassDropdown = {
  orange: "text-orange-400 hover:bg-orange-100 hover:bg-opacity-25",
  red: "text-red-500 hover:bg-red-100 hover:bg-opacity-25",
  green: "text-green-500 hover:bg-green-100 hover:bg-opacity-25 ",
};

export default colorVariantsClass;
