import { workshopApi } from "api";
import { queryClient } from ".";
import { t } from "i18next";
import { toast } from "react-toastify";

export const IS_CREATING_ID = "creation in progress";

export const makeGarmentMutationOptions = (garmentId, partnerId) => ({
  mutationFn: (body) => {
    return workshopApi.patch(`/garments/${garmentId}/parsing_data`, body, {
      params: { partner_id: partnerId },
    });
  },
  onMutate: async (body) => {
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({
      queryKey: [
        "garment",
        {
          garmentId,
          partnerId,
        },
      ],
    });
    // Snapshot the previous value
    const previousValue = queryClient.getQueryData([
      "garment",
      {
        garmentId,
        partnerId,
      },
    ]);

    // Optimistically update to the new value
    if (previousValue) {
      queryClient.setQueryData(
        [
          "garment",
          {
            garmentId,
            partnerId,
          },
        ],
        {
          ...previousValue,
          ...body,
        }
      );
    }

    // Add previous value to context mutation
    return {
      previousValue,
    };
  },

  onSettled: (_data, error, _variables, context) => {
    if (error) {
      if (context?.previousValue) {
        queryClient.setQueryData(
          [
            "garment",
            {
              garmentId,
              partnerId,
            },
          ],
          context.previousValue
        );
      }
      return;
    }

    queryClient.invalidateQueries({
      queryKey: [
        "garment",
        {
          garmentId,
          partnerId,
        },
      ],
      exact: true,
    });
    queryClient.invalidateQueries({ queryKey: ["garments"] });
  },
});

export const makeValidateGarmentMutationOptions = (garmentId, partnerId) => ({
  mutationFn: () => {
    return workshopApi.post(`/garments/${garmentId}/validate`, null, {
      params: { partner_id: partnerId },
    });
  },

  onSettled: (_data, _error, _variables, _context) => {
    queryClient.invalidateQueries({
      queryKey: ["garment", { garmentId, partnerId }],
      exact: true,
    });
    queryClient.invalidateQueries({ queryKey: ["garments"] });
  },
});

export const makePreviewGarmentMutationOptions = (garmentId, partnerId) => ({
  mutationFn: async (body) => {
    return workshopApi
      .post(`/garments/${garmentId}/preview`, body, {
        params: { partner_id: partnerId },
      })
      .then((res) => res.data);
  },
});

export const makeCreateGarmentColorVariantMutationOptions = (
  garmentId,
  partnerId
) => ({
  mutationFn: (body) => {
    return workshopApi.post(`/garments/${garmentId}/color_variants`, body, {
      params: { partner_id: partnerId },
    });
  },
  mutationKey: ["createGarmentColorVariant"],
  onMutate: async (body) => {
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({
      queryKey: [
        "garment",
        {
          garmentId,
          partnerId,
        },
      ],
    });

    // Snapshot the previous value
    const previousValue = queryClient.getQueryData([
      "garment",
      {
        garmentId,
        partnerId,
      },
    ]);

    // Optimistically update to the new value
    if (previousValue) {
      queryClient.setQueryData(
        [
          "garment",
          {
            garmentId,
            partnerId,
          },
        ],
        {
          ...previousValue,
          workshop_status: "IN_REVIEW", // unvalidated garment if new color variant is being created
          color_variants: [
            ...previousValue.color_variants,
            { ...body, id: IS_CREATING_ID },
          ],
        }
      );
    }

    // Add previous value to context mutation
    return {
      previousValue,
    };
  },
  onSettled: (data, error, _variables, context) => {
    if (error) {
      // Cancel optimistic update
      if (context?.previousValue) {
        queryClient.setQueryData(
          [
            "garment",
            {
              garmentId,
              partnerId,
            },
          ],
          context.previousValue
        );
      }
      // Log and toast error
      toast(t("textureColorVariantCreateError"), {
        type: "error",
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        style: {
          fontSize: "14px",
        },
      });

      return;
    }

    // Else success
    toast(t("textureColorVariantCreateSuccess"), {
      type: "success",
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      style: {
        fontSize: "14px",
      },
    });
    const newColorVariants = data.data.color_variants;
    // Replace the temporary optimistic update with the created value returned
    queryClient.setQueryData(
      [
        "garment",
        {
          garmentId,
          partnerId,
        },
      ],
      (curQueryData) => ({
        ...curQueryData,
        color_variants: [
          ...curQueryData.color_variants.filter((c) => c.id !== IS_CREATING_ID),
          ...newColorVariants,
        ],
      })
    );

    /* ❗️ Not invalid garment because if there is modification saved in BDD with other 3Ds workshop client we need to make a merging algorithm in front side. This is caused because of this :
     * color_variants are fetched via GET garment/{id} but color_variant creation or deletion is done via POST garment/{id}/color_variants or DELETE garment/{id}/color_variants
     * The rest of data are fetched via GET garment/{id} and the modification are saved in front side and we send the modification via PATCH garment/{id} => if modification aren't saved in front side, and user create or delete color_variant, the new GET garment will fetch a new initial garment object
     */
  },
});

export const makeDeleteGarmentColorVariantMutationOptions = (
  garmentId,
  partnerId
) => ({
  mutationFn: (colorVariantId) => {
    return workshopApi.delete(
      `/garments/${garmentId}/color_variants/${colorVariantId}`,
      { params: { partner_id: partnerId } }
    );
  },
  mutationKey: ["deleteGarmentColorVariant"],
  onMutate: async (colorVariantId) => {
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({
      queryKey: [
        "garment",
        {
          garmentId,
          partnerId,
        },
      ],
    });

    // Snapshot the previous value
    const previousValue = queryClient.getQueryData([
      "garment",
      {
        garmentId,
        partnerId,
      },
    ]);

    // Optimistically update to the new value
    if (previousValue) {
      queryClient.setQueryData(
        [
          "garment",
          {
            garmentId,
            partnerId,
          },
        ],
        {
          ...previousValue,
          workshop_status: "IN_REVIEW", // unvalidated garment if new color variant is being deleted
          color_variants: previousValue.color_variants.filter(
            (cv) => cv.id !== colorVariantId
          ),
        }
      );
    }

    // Add previous value to context mutation
    return {
      previousValue,
    };
  },
  onSettled: (data, error, _variables, context) => {
    if (error) {
      // Cancel optimistic update
      if (context?.previousValue) {
        queryClient.setQueryData(
          [
            "garment",
            {
              garmentId,
              partnerId,
            },
          ],
          context.previousValue
        );
      }
      // Log and toast error
      toast(t("textureColorVariantDeleteError"), {
        type: "error",
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        style: {
          fontSize: "14px",
        },
      });

      return;
    }

    // Else success
    toast(t("textureColorVariantDeleteSuccess"), {
      type: "success",
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      style: {
        fontSize: "14px",
      },
    });
    // Keep optimistic update without deleted item so do nothing

    /* ❗️ Not invalid garment because if there is modification saved in BDD with other 3Ds workshop client we need to make a merging algorithm in front side. This is caused because of this :
     * color_variants are fetched via GET garment/{id} but color_variant creation or deletion is done via POST garment/{id}/color_variants or DELETE garment/{id}/color_variants
     * The rest of data are fetched via GET garment/{id} and the modification are saved in front side and we send the modification via PATCH garment/{id} => if modification aren't saved in front side, and user create or delete color_variant, the new GET garment will fetch a new initial garment object
     */
  },
});
