import user from "./userSlice";
import auth from "./authSlice";
import referential from "./referentialSlice";
import unsavedGarments from "./unsavedGarmentsSlice";
import preview from "./previewSlice";

// Export actions
export * from "./userSlice";
export * from "./authSlice";
export * from "./referentialSlice";
export * from "./unsavedGarmentsSlice";
export * from "./previewSlice";

const reducers = {
  user,
  auth,
  referential,
  unsavedGarments,
  preview,
};

export default reducers;
