import ModalBlank from "components/modal/ModalBlank";
import { FeedbackModal } from "components/modal/ModalContent";
import { createContext, useContext, useState } from "react";

// Here there are the different type of modal (according to components files associated)
export const MODAL_TYPES = ["success", "error", "warning", "info"];

const MODAL_COMPONENTS = {
  success: FeedbackModal,
  error: FeedbackModal,
  warning: FeedbackModal,
  info: FeedbackModal,
};

const initialState = {
  modalType: null,
  modalProps: null,
  modalOpen: false,
};

export const ModalContext = createContext(initialState);
export const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
  const [store, setStore] = useState(initialState);

  /**
   * Show a modal with UI of modalType and modalProps content
   * @param {string} modalType The modal component to display (success/error/warning/info)
   * @param {object} modalProps The props to pass to the FeedBackModal component
   * @param {string} modalProps.titleKey The key of the title to display
   * @param {string} modalProps.messageKey The key of the message to display
   * @param {object} modalProps.messageDynamicValues The dynamic values to pass to i18n translation function
   * @param {function} modalProps.onValidate A function to call when the modal is validated
   * @param {function} modalProps.onCancel A function to call when the modal is cancelled
   * @param {boolean} modalProps.isDestructive If the validation is destructive : display the validate button in red and cancel button in grey
   * @param {boolean} modalProps.isCancelDestructive If the cancel button is destructive : display the cancel button in red
   * @param {string} modalProps.validateKey The translation key of the validate button (by default OK)
   * @param {string} modalProps.cancelKey The translation key of the cancel button (by default cancel)
   */
  const showModal = (modalType, modalProps) => {
    if (!MODAL_TYPES.includes(modalType)) {
      throw new Error("Invalid modal type");
    }
    setStore({
      ...store,
      modalOpen: true,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalOpen: false,
      modalType: null,
      modalProps: {},
    });
  };

  const renderModal = () => {
    const ModalContent = MODAL_COMPONENTS[store.modalType];
    if (!ModalContent) {
      if (store.modalType) {
        throw new Error(
          `>>> Modal.js : no modal component for ${store.modalType}`
        );
      } else {
        return null;
      }
    }
    return (
      <ModalBlank id={store.modalType} modalOpen={store.modalOpen}>
        <ModalContent id="global-modal" />
      </ModalBlank>
    );
  };

  return (
    <ModalContext.Provider
      value={{ ...store, showModal, hideModal, renderModal }}
    >
      {renderModal()}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
