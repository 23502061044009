import { createContext, useCallback, useContext, useState } from "react";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import { useDispatch, useSelector } from "react-redux";
import { parseCognitoUser } from "utils/parse";
import { setUser } from "store";
import { clearPrivate } from "store/globalActions";
import { useTranslation } from "react-i18next";
import { setSignedIn } from "store/slices/authSlice";

export const AuthState = createContext({});

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const authState = useSelector((state) => state.auth); // Slice to allow to persist auth state (not display loader and signin page on each page reloading)
  const dispatch = useDispatch();

  const [authIsChecking, setAuthIsChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isChangePasswordChallenge, setIsChangePasswordChallenge] =
    useState(false);

  const _handleLoggedInUser = async (res) => {
    dispatch(setUser(parseCognitoUser(res.attributes)));
    dispatch(setSignedIn());
    setErrorMessage(null);
    setAuthIsChecking(false);
  };

  const _handleErrorLogin = async (err) => {
    setAuthIsChecking(false);
    console.error(">>> _handleErrorLogin error : ", err);
    Sentry.captureException(err);
  };

  const handleSignIn = async (values) => {
    setAuthIsChecking(true);

    await Auth.signIn(values.username, values.password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          console.debug(">>> handleSignIn challenge : NEW_PASSWORD_REQUIRED");
          setIsChangePasswordChallenge(true);
          setErrorMessage(null);
          if (values.newPassword) {
            console.log(user);
            return Auth.completeNewPassword(user, values.newPassword, {
              locale: navigator.language,
            })
              .then(async () => {
                const user = await Auth.currentAuthenticatedUser();
                _handleLoggedInUser(user);
              })
              .catch((err) => {
                setErrorMessage(t("signInError1"));
                _handleErrorLogin(err);
              });
          } else {
            setAuthIsChecking(false);
            return;
          }
        } else if (user.challengeName) {
          console.error(
            `>>> handleSignIn error : challenge name ${user.challengeName} not authorized. Deactivate it`
          );
          return;
        }
        _handleLoggedInUser(user);
      })
      .catch((err) => {
        setErrorMessage(t("signInError1"));
        _handleErrorLogin(err);
      });
  };

  const handleSignOut = async () => {
    await Auth.signOut()
      .then(() => {
        setAuthIsChecking(false);
        setErrorMessage(null);
        setIsChangePasswordChallenge(false);
        dispatch(clearPrivate()); // Reset auth state
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  };

  const checkUserIsSignedIn = useCallback(async () => {
    setAuthIsChecking(true);
    await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((res) => {
        _handleLoggedInUser(res);
      })
      .catch((err) => {
        setAuthIsChecking(false);
        _handleErrorLogin(err);
      });
  }, []);

  return (
    <AuthState.Provider
      value={{
        ...authState,
        authIsChecking,
        errorMessage,
        isChangePasswordChallenge,
        checkUserIsSignedIn,
        signOut: handleSignOut,
        signIn: handleSignIn,
      }}
    >
      {children}
    </AuthState.Provider>
  );
};

export const useAuth = () => useContext(AuthState);
