import SpinnerAnimatedSvg from "components/SpinnerAnimatedSvg";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import R3fPreviewViewer from "threeJs/R3fPreviewViewer";

function PreviewView() {
  const { t } = useTranslation();
  const { vtoIdInProcess } = useSelector((state) => state.preview);

  return (
    <div className="flex relative flex-nowrap overflow-x-scroll no-scrollbar flex-1">
      <div className="flex flex-1 w-full h-full">
        <R3fPreviewViewer />
        {vtoIdInProcess && (
          <div className="absolute inset-0 bg-black/50 flex justify-center items-center">
            <div className="flex items-center flex-col gap-5">
              <SpinnerAnimatedSvg white size={40} />
              <span className="text-sm font-light text-gray-300">
                {t("vtoIsLoading")}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PreviewView;
