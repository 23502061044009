import AvatarCard from "components/cards/AvatarCard";
import DropdownInput from "components/inputs/DropdownInput";
import { avatarsFilterOptions } from "constants/garments";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function PreviewSideBar() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const {
    filteredAvatars,
    isAvatarsLoading,
    isAvatarsError,
    previewGender,
    previewSize,
    previewBodyShape,
    selectedPreviewedAvatarId,
    changePreviewGender,
    changePreviewSize,
    changePreviewBodyShape,
    changePreviewSelectedAvatarId,
  } = useCurrentGarmentContext();

  useEffect(() => {
    //on change filters
    //back to the top of view
    ref.current?.scrollTo(0, 0);
  }, [
    previewGender,
    previewSize,
    previewBodyShape,
    changePreviewSelectedAvatarId,
  ]);

  const handleSetSelected = (id) => {
    if (id === selectedPreviewedAvatarId) {
      changePreviewSelectedAvatarId("");
    } else {
      changePreviewSelectedAvatarId(id);
    }
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col md:flex-row md:items-center gap-2 mt-4 pb-4 px-2 border-b border-gray-200">
        <div className="flex md:items-center gap-2">
          <span className="w-full ml-2 text-xs font-semibold text-gray-400 uppercase">
            {t("avatarFilters")}
          </span>
          <span className="md:block hidden w-[1px] h-5 bg-gray-400" />
        </div>
        <div className="flex flex-col md:flex-row flex-wrap md:items-center gap-2">
          <DropdownInput
            options={avatarsFilterOptions.gender}
            selectedOption={previewGender}
            onChange={(option) => {
              changePreviewGender(option);
            }}
            getLabelOptionFromOption={(option) =>
              option.includes("all") ? t("all") : t(option)
            }
            getLabelInputFromOption={(option) => t(option)}
            customWidthClass="min-w-[120px]"
          />
          <DropdownInput
            options={avatarsFilterOptions.size}
            selectedOption={previewSize}
            onChange={(option) => {
              changePreviewSize(option);
            }}
            getLabelOptionFromOption={(option) =>
              option.includes("all") ? t("all") : t(option)
            }
            getLabelInputFromOption={(option) => t(option)}
            customWidthClass="min-w-[120px]"
          />
          <DropdownInput
            options={avatarsFilterOptions.body_shape}
            selectedOption={previewBodyShape}
            onChange={(option) => {
              changePreviewBodyShape(option);
            }}
            getLabelOptionFromOption={(option) =>
              option.includes("all") ? t("all") : t(option)
            }
            getLabelInputFromOption={(option) => t(option)}
            customWidthClass="min-w-[120px]"
          />
        </div>
      </div>

      <div
        ref={ref}
        className="no-scrollbar overflow-y-auto lg:min-w-[540px] p-3 border-b md:border-b-0 md:border-r border-gray-200 flex-1 space-y-3"
      >
        {isAvatarsLoading ? (
          <div className="flex w-full h-full items-center justify-center">
            <p className="text-sm text-gray-500 p-10 text-center">
              {t("loading")}
            </p>
          </div>
        ) : isAvatarsError ? (
          <div className="flex w-full h-full items-center justify-center">
            <p className="text-sm text-gray-500 p-10 text-center">
              {t("defaultErrorRequestMsg")}
            </p>
          </div>
        ) : filteredAvatars.length ? (
          filteredAvatars.map((item) => (
            <AvatarCard
              key={item.id}
              id={item.id}
              preview={item.preview}
              gender={item.gender}
              bodyShape={item.body_shape}
              heightCm={item.height_cm}
              name={item.name}
              size={item.size}
              measurements={item.measurements}
              isSelected={selectedPreviewedAvatarId === item.id}
              setSelected={handleSetSelected}
            />
          ))
        ) : (
          <div className="flex w-full h-full items-center justify-center">
            <p className="text-sm text-gray-500 p-10 text-center">
              {t("noAvatarFound")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default PreviewSideBar;
