const TableInputs = ({ data, onChange, inputsProps = {} }) => {
  return (
    <ul className="max-w-[800px] w-full rounded-md overflow-hidden border  border-gray-200">
      {data &&
        Object.entries(data).map(([key, value]) => {
          return (
            <li
              key={`line-${key}`}
              className="child:p-4 w-full inline-flex border-b last:border-b-0"
            >
              <div className="w-8/12 bg-gray-100 break-words">{key}</div>
              <input
                type="number"
                step="0.01"
                onChange={({
                  nativeEvent: {
                    target: { value },
                  },
                }) => onChange(key, value)}
                value={Math.round(value * 100) / 100}
                pattern="[0-9]?[0-9]?(\.[0-9][0-9]?)?
                "
                className="flex-1 w-4/12 bg-white border-none outline-none focus:outline-none focus:ring-0 text-center"
                {...inputsProps}
              />
            </li>
          );
        })}
    </ul>
  );
};

export default TableInputs;
