const LinkTextButton = ({
  onClick,
  Icon = () => null,
  className,
  iconClassName,
  label,
  noPadding,
  type,
}) => {
  return (
    <div
      className={`flex justify-center m-auto text-blue-avumi-300 hover:text-blue-avumi-400 ${
        className ? className : ""
      }`}
    >
      <button
        type={type}
        {...{ onClick }}
        className={`block text-center text-sm font-medium ${
          noPadding ? "" : "px-3 py-4"
        }`}
      >
        <div className="flex items-center justify-center">
          <Icon className={`w-5 h-5 mr-2 ${iconClassName}`} />
          <span>{label}</span>
        </div>
      </button>
    </div>
  );
};

export default LinkTextButton;
