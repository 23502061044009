import { workshopApi } from "api";
import { parseGarmentFromApi } from "utils/parse";

/**
 * @param {Object} params Object of params required to build queryKey and queryFn
 * @param {number} params.page Current page to fetch
 * @param {number} params.count Number of items per page
 * @param {string} params.brandId Brand id to filter by
 * @param {string} params.partnerId Partner id to filter by
 * @param {string[]} params.reviewStatuses Array of review statuses to filter by
 * @returns the options to pass to useQuery function
 */
export const makeGarmentsQueryOptions = ({
  page,
  brandId,
  partnerId,
  reviewStatuses,
}) => ({
  queryKey: [
    "garments",
    {
      page,
      reviewStatuses,
      brandId,
      partnerId,
    },
  ],
  queryFn: async () => {
    return workshopApi
      .get("/garments", {
        params: {
          page,
          brand_id: brandId,
          partner_id: partnerId,
          statuses: reviewStatuses.join(","),
        },
      })
      .then((res) => res.data);
  },
  // Not use `select` because the getQueryData return the res if we use it
  enabled: !!partnerId,
});

/**
 * @param {object} locationState location state object from useLocation hook of react-router-dom
 * @param {string} partnerId partner_id of garment to fetch
 * @returns the options to pass to useQuery function
 */
export const makeGarmentDataQueryOptions = (garmentId, partnerId) => {
  return {
    queryKey: [
      "garment",
      {
        garmentId,
        partnerId,
      },
    ],
    queryFn: async () => {
      return workshopApi
        .get(`/garments/${garmentId}/parsing_data`, {
          params: {
            partner_id: partnerId,
          },
        })
        .then((res) => parseGarmentFromApi(res.data));
    },
  };
};

/**
 * @param {string} query query to search for garment, brand or partner
 * @param {string} partnerId the partner ID to search for it's garments and brands
 * @returns An object with garments, partners and brands array of matching items
 */
export const makeSearchQueryOptions = ({ query, partnerId }) => {
  return {
    queryKey: ["search", { query, partnerId }],
    queryFn: async () => {
      return workshopApi
        .get("/search", {
          params: { query, partner_id: partnerId },
        })
        .then((res) => res.data);
    },
    // Not use `select` because the getQueryData return the res if we use it
    keepPreviousData: true,
    enabled: !!partnerId,
  };
};

/**
 * @returns An object with items of avatars
 */
export const makeStandardAvatarsQueryOptions = () => ({
  queryKey: ["standard_avatars"],
  queryFn: async () => {
    return workshopApi.get("/standard_avatars").then((res) => res.data.items);
  },
  // Not use `select` because the getQueryData return the res if we use it
});

/**
 * @returns An object with items of partners
 */
export const makePartnersQueryOptions = () => ({
  queryKey: ["partners"],
  queryFn: () => {
    return workshopApi.get("/partners");
  },
  // No other keys because not triggers via useQuery but via queryClient.fetchQuery
});

/**
 * @param {string} params.brandId Brand id to filter by
 * @returns An object with items of normal maps
 */
export const makeNormalMapsQueryOptions = (params) => ({
  queryKey: [
    "normal_maps",
    {
      brandId: params.brandId,
    },
  ],
  queryFn: async () => {
    return workshopApi
      .get(`/normal_maps?brand_id=${params.brandId}`)
      .then((res) => res.data);
  },
  // Not use `select` because the getQueryData return the res if we use it
});
