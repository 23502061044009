import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partners: [],
};

const referentialSlice = createSlice({
  name: "referential",
  initialState,
  reducers: {
    setPartners(state, action) {
      return {
        ...state,
        partners: action.payload,
      };
    },
  },
});

const { actions, reducer } = referentialSlice;
export const { setPartners } = actions;
export default reducer;
