import { useRef } from "react";
import {
  DirectionalLightHelper,
  PointLightHelper,
  SpotLightHelper,
} from "three";
import { useHelper } from "@react-three/drei";
import { useR3fPreviewContext } from "context/R3fPreviewProvider";

const DEFAULT_LIGHTS = {
  ambientLight: { color: 0xffffff },
  directionalLight: { color: 0xffffff },
  spotLight: { color: 0xffffff },
  pointLight: { color: 0xffffff },
};

const R3fPreviewLights = ({ withHelpers }) => {
  const { sceneOptions } = useR3fPreviewContext();

  if (!sceneOptions) {
    return null;
  }

  return (
    <group name="lights">
      {/* Ambient light */}
      <ambientLight
        {...DEFAULT_LIGHTS.ambientLight}
        {...sceneOptions?.ambientLight}
        name="ambientLight"
      />
      {/* DirectionalLights */}
      {sceneOptions.directionalLights?.map((options, index) => (
        <Light
          {...{ options, index, withHelpers }}
          key={`directionalLight-${index}`}
          type="directionalLight"
        />
      ))}
      {/* SpotLights */}
      {sceneOptions.spotLights?.map((options, index) => (
        <Light
          {...{ options, index, withHelpers }}
          key={`spotLight-${index}`}
          type="spotLight"
          shadow-normalBias={0.01}
        />
      ))}
      {/* PointLights */}
      {sceneOptions.pointLights?.map((options, index) => (
        <Light
          {...{ options, index, withHelpers }}
          key={`pointLight-${index}`}
          type="pointLight"
        />
      ))}
    </group>
  );
};

export default R3fPreviewLights;

const HELPER_CONFIG = {
  directionalLight: {
    component: DirectionalLightHelper,
    color: "plum",
    size: 2,
  },
  spotLight: { component: SpotLightHelper, color: "skyblue" },
  pointLight: { component: PointLightHelper, color: "lime", size: 0.1 },
};

const Light = ({ options, type, withHelpers, index, ...otherProps }) => {
  const lightRef = useRef();

  const helpersLastArgs = [
    options.castShadow ? "red" : HELPER_CONFIG[type].color,
  ];
  if (["directionalLight", "pointLight"].includes(type)) {
    helpersLastArgs.unshift(HELPER_CONFIG[type].size);
  }

  useHelper(
    withHelpers.lights && lightRef,
    HELPER_CONFIG[type].component,
    ...helpersLastArgs
  );

  switch (type) {
    case "spotLight":
      return (
        <spotLight
          ref={lightRef}
          {...DEFAULT_LIGHTS.spotLight}
          {...options}
          {...otherProps}
          name={`spotLight-${index}`}
        />
      );
    case "directionalLight":
      return (
        <directionalLight
          ref={lightRef}
          {...DEFAULT_LIGHTS.directionalLight}
          {...options}
          {...otherProps}
          name={`directionalLight-${index}`}
        />
      );
    case "pointLight":
      return (
        <pointLight
          ref={lightRef}
          {...DEFAULT_LIGHTS.pointLight}
          {...options}
          {...otherProps}
          name={`pointLight-${index}`}
        />
      );
    default:
      console.error(">>> Light component in R3fPreviewLights : type not found");
      return null;
  }
};
