import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useAuth } from "context/AuthProvider";
import { useTranslation } from "react-i18next";
import AvumiLogo from "images/logo-avumi-workshop-192.png";
import Dropdown from "./Dropdown";

function DropdownProfile({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const { signOut } = useAuth();
  const { t } = useTranslation();

  return (
    <Dropdown
      {...{ dropdownOpen, setDropdownOpen, align }}
      buttonComponent={
        <button
          className="inline-flex justify-center items-center group"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <img
            className="w-8 h-8 rounded-full"
            src={AvumiLogo}
            width="32"
            height="32"
            alt="User"
          />
          <div className="flex items-center truncate">
            <HiOutlineChevronDown className="w-4 h-4 shrink-0 ml-1 text-gray-500" />
          </div>
        </button>
      }
    >
      <>
        <div className="pt-2  pb-2 px-3 mb-1 border-b border-gray-200 max-w-[350px]">
          <div className="font-medium mb-2 text-gray-800 whitespace-no-wrap truncate w-full overflow-hidden">
            {user.email}
          </div>
          <div className="text-xs text-gray-500 italic">{t("avumiUser")}</div>
        </div>
        <ul>
          {/* <li>
              <Link
              className="font-medium text-sm text-blue-avumi-300 hover:text-blue-avumi-400 flex items-center py-1 px-3"
              to="/settings"
              onClick={() => setDropdownOpen(!dropdownOpen)}
              >
              Settings
              </Link>
            </li> */}
          <li>
            <Link
              className="font-medium text-sm text-blue-avumi-300 hover:text-blue-avumi-400 flex items-center py-1 px-3"
              onClick={signOut}
            >
              {t("signOut")}
            </Link>
          </li>
        </ul>
      </>
    </Dropdown>
  );
}

export default DropdownProfile;
