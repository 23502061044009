import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ReactSVGPanZoom, TOOL_NONE, TOOL_PAN } from "react-svg-pan-zoom";

import colors from "css/colors";
import { throttle } from "lodash";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import {
  getClosestPointOnPolygon,
  cleanGetClosestPointOnPolygonCache,
} from "utils/geometry";

import { useTranslation } from "react-i18next";
import { PiCrosshairBold } from "react-icons/pi";
import { useTopPositionOnScroll } from "hooks/useTopPositionOnScroll";

const getViewerSize = () => {
  if (window.innerWidth > 1600) {
    return 450;
  } else if (window.innerWidth > 1280) {
    return 450;
  } else if (window.innerWidth > 1180) {
    return 350;
  } else {
    return 250;
  }
};

const getInitialValues = (points) => {
  let minX = 0;
  let maxX = 0;
  let minY = 0;
  let maxY = 0;
  if (points.length > 0) {
    minX = points[0][0];
    maxX = points[0][0];
    minY = -points[0][1];
    maxY = -points[0][1];
    for (let point of points) {
      if (point[0] < minX) minX = point[0];
      if (point[0] > maxX) maxX = point[0];
      if (-point[1] < minY) minY = -point[1];
      if (-point[1] > maxY) maxY = -point[1];
    }
  }
  const maxDim = Math.max(Math.abs(maxX - minX), Math.abs(maxY - minY));
  const centerPoint = [minX + (maxX - minX) / 2, minY + (maxY - minY) / 2];
  return {
    centerPoint: [centerPoint[0], -centerPoint[1]],
    maxDim,
    SVGWidth: maxDim * 1.2,
    SVGHeight: maxDim * 1.2,
    SVGMinX: centerPoint[0] - (maxDim * 1.2) / 2,
    SVGMinY: centerPoint[1] - (maxDim * 1.2) / 2,
    stokeWidth: maxDim / 400,
  };
};

const SvgViewer = ({ points, pois, highlightedPoiId, setHighlightedPoiId }) => {
  const { t } = useTranslation();
  const Viewer = useRef(null);
  const wrapperRef = useRef(null);
  const [initialValues, setInitialValues] = useState(getInitialValues(points));
  const [poiSize, setPoiSize] = useState(10);
  const [value, setValue] = useState({});
  const [tool, setTool] = useState(TOOL_NONE);
  const [viewerSize, setViewerSize] = useState(getViewerSize());

  const [isMouseHover, setIsMouseHover] = useState(false);
  const {
    switchToEditPoiCoordMode,
    editedCoordPoiId,
    changeEditedPiece,
    selectedDxfPiece,
  } = useCurrentGarmentContext();

  const [closestPointCoord, setClosestPointCoord] = useState(null);

  const topPosition = useTopPositionOnScroll(
    viewerSize,
    "poi-accordions-wrapper"
  );
  usePressSpaceKeyToPan(setTool);

  useEffect(() => {
    if (!editedCoordPoiId) {
      setClosestPointCoord(null);
      cleanGetClosestPointOnPolygonCache();
      setHighlightedPoiId(null);
    } else {
      // Fix bug for onMouseMove event not triggered because of rect element are pointer-events: none style
      const rectBg =
        Viewer.current.ViewerDOM.getElementsByTagName("rect").item(0);
      rectBg.style.pointerEvents = "auto";
    }
  }, [editedCoordPoiId]);

  useEffect(() => {
    const resizeViewer = throttle((...args) => {
      setViewerSize(getViewerSize());
    }, 1000);

    window.addEventListener("resize", resizeViewer);
    return () => {
      window.removeEventListener("resize", resizeViewer);
    };
  }, []);

  useLayoutEffect(() => {
    if (points) {
      const initialValues = getInitialValues(points);
      setInitialValues(initialValues);
      setPoiSize(initialValues.stokeWidth * 3);

      Viewer.current.fitSelection(
        initialValues.SVGMinX,
        initialValues.SVGMinY,
        initialValues.SVGWidth,
        initialValues.SVGHeight
      );
      // Viewer.current.fitToViewer();
    }
  }, [points]);

  const handleMouseHover = (event) => {
    if (!editedCoordPoiId) return;
    const { x, y, scaleFactor } = event; // x and y are in svg coordinates referential

    // Calculate the diagonal length of the SVG
    const diagonalLength = Math.sqrt(
      initialValues.SVGWidth ** 2 + initialValues.SVGHeight ** 2
    );
    // Set the snapThreshold as a fraction of the scaled diagonal length
    const snapThreshold = diagonalLength / 100;

    setClosestPointCoord(
      getClosestPointOnPolygon(
        [x, -y],
        points,
        selectedDxfPiece.id,
        snapThreshold
      )
    );
  };

  const handleSelectNewPoiValue = () => {
    if (tool === TOOL_PAN) {
      return;
    } else {
      changeEditedPiece({
        pois: selectedDxfPiece.pois.map((poi) => {
          if (poi.id === editedCoordPoiId) {
            return {
              ...poi,
              point: closestPointCoord.map(
                (coord) => Math.round(coord * 100) / 100
              ),
            };
          } else return poi;
        }),
      });
      switchToEditPoiCoordMode(null);
    }
  };

  return (
    <div
      className={`px-3 relative ${
        editedCoordPoiId ? "pointer-events-auto" : ""
      }`}
      style={{ top: `${topPosition}px` }}
      onMouseEnter={() => {
        setIsMouseHover(true);
      }}
      onMouseLeave={() => {
        setIsMouseHover(false);
        setClosestPointCoord(null);
      }}
    >
      <div ref={wrapperRef} id="svg-viewer">
        <ReactSVGPanZoom
          width={viewerSize}
          height={viewerSize}
          background="#000000"
          SVGBackground="#000000"
          SVGStyle={{ pointerEvents: "auto" }}
          ref={Viewer}
          value={value}
          onChangeValue={setValue}
          onMouseMove={handleMouseHover}
          onMouseDown={handleSelectNewPoiValue}
          tool={tool}
          onChangeTool={setTool}
          detectAutoPan={false}
          preventPanOutside={false}
          customMiniature={() => null}
          customToolbar={() => <CustomToolBar {...{ Viewer, initialValues }} />}
        >
          <svg width={viewerSize} height={viewerSize}>
            <polygon
              points={points.reduce((a, b) => `${a} ${b[0]},${-b[1]}`, "")} // Reverted y-coordinate
              fill="none"
              stroke="white"
              strokeWidth={initialValues.stokeWidth}
            />

            {pois?.map((poi) => {
              const isHighlithed = poi.id === highlightedPoiId;
              const isEditing = poi.id === editedCoordPoiId;
              if (isEditing) return null;
              return (
                <circle
                  key={poi.id}
                  cx={poi.point[0]}
                  cy={-poi.point[1]} // Reverted y-coordinate
                  r={poiSize}
                  fill={isHighlithed ? "#f56565" : colors.blueAvumi300}
                  stroke="transparent"
                  strokeWidth={initialValues.stokeWidth}
                />
              );
            })}
            {editedCoordPoiId && isMouseHover && closestPointCoord && (
              <circle
                key={"editedPoiCoordinates"}
                cx={closestPointCoord[0]}
                cy={-closestPointCoord[1]} // Reverted y-coordinate
                r={poiSize}
                fill={"#f56565"}
                stroke="transparent"
                strokeWidth={initialValues.stokeWidth}
              />
            )}
          </svg>
        </ReactSVGPanZoom>
        <div className="flex p-1 mx-auto text-gray-400 text-xs z-50 pointer-events-none self-center w-full justify-center">
          {t("svgViewerSpacePan")}
        </div>
      </div>
    </div>
  );
};

export default SvgViewer;

const CustomToolBar = ({ Viewer, initialValues }) => {
  return (
    <div className="absolute top-2 right-2 flex flex-col justify-between items-center">
      <button
        className="w-8 h-8 bg-white rounded flex items-center justify-center"
        onClick={() =>
          Viewer.current.fitSelection(
            initialValues.SVGMinX,
            initialValues.SVGMinY,
            initialValues.SVGWidth,
            initialValues.SVGHeight
          )
        }
      >
        <PiCrosshairBold className="w-5 h-5" />
      </button>
      {/* <button>
        <RiDragMove2Line />
      </button> */}
    </div>
  );
};

const usePressSpaceKeyToPan = (setTool) => {
  const [isSpacePressed, setIsSpacePressed] = useState(false);

  useEffect(() => {
    // Avoid space document scroll
    const handleKeyDown = (event) => {
      if (event.code === "Space") {
        setIsSpacePressed(true);
        if (event.target === document.body) {
          event.preventDefault(); // Avoid auto scrolling
        }
      }
    };

    // Event handler for key up event
    const handleKeyUp = (event) => {
      if (event.code === "Space") {
        setIsSpacePressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (isSpacePressed) {
      setTool(TOOL_PAN);
    } else {
      setTool(TOOL_NONE);
    }
  }, [isSpacePressed]);
};
