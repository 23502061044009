import { useGLTF } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FrontSide, SRGBColorSpace } from "three";
import { getBodyGender } from "utils/r3f";
import { withErrorBoundary } from "react-error-boundary";
import { useModal } from "context/ModalProvider";
import { clearPreviewed } from "store";

const GARMENT_MATERIAL_SIDE = FrontSide;
const TEXTURE_ENCODING = SRGBColorSpace;

const R3fPreviewAvatar = () => {
  const { previewedVtoUrl } = useSelector((state) => state.preview);
  const groupRef = useRef();

  const [gender, setGender] = useState();
  const [boundaryBox, setBoundaryBox] = useState();

  const gltf = useGLTF(previewedVtoUrl, true);

  useEffect(() => {
    if (gltf?.scene) {
      // Set gender of vto
      const gender = getBodyGender(gltf.scene);
      setGender(gender);

      // Configured avatar object
      const avatarObject = gltf.scene.getObjectByName("avatar");
      if (avatarObject) {
        // Calculate bounding box of avatar for privacy feature (updateOpacity)
        avatarObject.traverse((node) => {
          if (node.isMesh) {
            setBoundaryBox(node.geometry.boundingBox);
            // Configure avatar shadow
            node.castShadow = true;
            node.receiveShadow = true;
            node.material.side = FrontSide;
          }
        });
      } else {
        console.error(
          ">>> Any object with name 'avatar' found in gltf file. This is required for avatar. Some features will not work or crash."
        );
      }

      // Configured garment object
      const garmentObject = gltf.scene.getObjectByName("garment");
      if (garmentObject) {
        // Configure garment shadow
        garmentObject.traverse((node) => {
          if (node.isMesh) {
            node.castShadow = true;
            node.receiveShadow = true;
            node.material.side = GARMENT_MATERIAL_SIDE;
            if (node.material.name.includes("stamp")) {
              // Fix renderOrder issue after viewThrough activation (material began transparent and a zindex figthing appear between stamp and garment)
              node.renderOrder = 1;
            } else {
              node.renderOrder = 0;
            }
          }
        });
      } else {
        console.error(
          ">>> Any object with name 'garments' found in gltf file. This is required for avatar. Some features will not work or crash."
        );
      }
    }
  }, [gltf]);

  useEffect(() => {
    if (gltf) {
      // Update materials
      if (gltf?.materials) {
        Object.values(gltf.materials).forEach((m) => {
          if (m.map) m.map.colorSpace = TEXTURE_ENCODING;
          if (m.emissiveMap) m.emissiveMap.colorSpace = TEXTURE_ENCODING;
          if (m.map || m.emissiveMap) m.needsUpdate = true;
        });
      }
      // Added extras properties of gltf into
      for (const node of gltf.parser.json.nodes) {
        if (node.extras) {
          const object3d = gltf.scene.getObjectByName(node.name);
          Object.assign(object3d.userData, node.extras);
        }
      }
    }
  }, [gltf]);

  return (
    <>
      <group ref={groupRef} name="vto">
        {/* {previewedVtoUrl && gltf && ( */}
        {true && gltf && (
          <primitive
            object={gltf.scene}
            // onPointerMove={(e) => null} // Required to raycast
          />
        )}
      </group>
    </>
  );
};

const ErrorBoundaryComponent = ({ error, resetBoundary }) => {
  const { previewedVtoUrl } = useSelector((state) => state.preview);

  const { showModal } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    showModal("warning", {
      titleKey: error?.message?.includes("403: Forbidden")
        ? "previewError403ModalTitle"
        : "previewErrorOtherModalTitle",
      messageKey: error?.message?.includes("403: Forbidden")
        ? "previewError403ModalMsg"
        : "previewErrorOtherModalMsg",
      isDestructive: true,
    });
    dispatch(clearPreviewed());
  }, []);

  useEffect(() => {
    if (previewedVtoUrl === null) {
      resetBoundary();
    }
  }, [previewedVtoUrl]);

  return <group name="vto" position={[0, 1, 0]}></group>;
};

const withNullableUrl = (Component) => (props) => {
  const { previewedVtoUrl } = useSelector((state) => state.preview);
  return typeof previewedVtoUrl === "string" ? <Component {...props} /> : null;
};

export default withNullableUrl(
  withErrorBoundary(R3fPreviewAvatar, {
    FallbackComponent: ErrorBoundaryComponent,
    onError(error, info) {
      console.error(">>> R3fPreviewAvatar error boundary", error, info);
    },
  })
);
