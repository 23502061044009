import { useGLTF } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { useThree } from "@react-three/fiber";
import { getYPositionOfCeil } from "utils/r3f";
import { scenesConfig } from "constants/scenesConfig";
import { useR3fPreviewContext } from "context/R3fPreviewProvider";

const OFFSET_Y_CAMERA = 1;
const CONTROL_MAX_DISTANCE_DEFAULT = 3;
const CONTROL_MIN_DISTANCE_DEFAULT = 0;

const SCENES_URLS = scenesConfig.reduce(
  (acc, o) => ({ ...acc, [o.name]: "/three/studio.glb" }),
  {}
);

const R3fPreviewScene = () => {
  const { camera, controls } = useThree();
  const { setSceneOptions, setCeilY, ceilY } = useR3fPreviewContext();
  const groupRef = useRef();

  const [selectedScene] = useState("studio");

  const gltf = useGLTF(SCENES_URLS[selectedScene]);

  useEffect(() => {
    if (gltf?.scene) {
      // Set ceil y position
      const ceilY = getYPositionOfCeil(gltf?.scene);
      setCeilY(ceilY);

      // Set scene options
      const config = scenesConfig.find(
        (config) => config.name === selectedScene
      );
      const options = config.options;
      setSceneOptions(config.options);

      // Set camera and controls position
      camera.position.set(0, OFFSET_Y_CAMERA, 2);
      camera.lookAt(0, OFFSET_Y_CAMERA, 0);
      camera.updateProjectionMatrix();

      if (controls) {
        controls?.reset();
        controls.target.set(0, OFFSET_Y_CAMERA, 0);
        controls.maxDistance =
          options.controls?.maxDistance || CONTROL_MAX_DISTANCE_DEFAULT;
        controls.minDistance =
          options.controls?.minDistance || CONTROL_MIN_DISTANCE_DEFAULT;
        controls?.saveState();
      }

      // Set receiveShadow of scene
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          node.receiveShadow = true;
        }
      });
    }
  }, [gltf, controls, camera]);

  return (
    <>
      <group ref={groupRef} name="backgroundScene">
        {ceilY && (
          <primitive
            object={gltf.scene}
            // onPointerMove={(e) => null} // Required to raycast
          />
        )}
      </group>
    </>
  );
};

export default R3fPreviewScene;
