import { Amplify, Auth } from "aws-amplify";
import axios from "axios";

const withoutCredentials = ["health"];

export const workshopApi = axios.create({
  baseURL: process.env.REACT_APP_API_endpoint,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Handle jwt token authentication
workshopApi.interceptors.request.use(
  async (config) => {
    const isWithCredentials = !withoutCredentials.some((path) =>
      config.url.includes(path)
    );
    config.withCredentials = false; // If true CORS error
    config.headers["Content-Type"] = "application/json";
    if (isWithCredentials) {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle 401 unauthorized
workshopApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errMessage = error.code;
    if (errMessage.includes("UNAUTHORIZED") && !originalRequest._retry) {
      originalRequest._retry = true;
      return workshopApi(originalRequest);
    }
    return Promise.reject(error);
  }
);

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AMPLIFY_AUTH_region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_userPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_userPoolWebClientId,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: process.env.REACT_APP_AMPLIFY_AUTH_mandatorySignIn,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_name,
        endpoint: process.env.REACT_APP_API_endpoint,
      },
    ],
  },
});
