import { createSlice } from "@reduxjs/toolkit";
import { clearPrivate } from "store/globalActions";

const initialState = {
  id: null,
  email: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      return action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(clearPrivate, (state, action) => initialState);
  },
});

const { actions, reducer } = userSlice;
export const { setUser } = actions;
export default reducer;
