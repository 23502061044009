import { useTranslation } from "react-i18next";
import Badge from "components/badge/Badge";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import { useModal } from "context/ModalProvider";
import DropdownInput from "components/inputs/DropdownInput";
import BigButton from "components/buttons/BigButton";

/* CONTENT COMPONENTS */

const Filters = ({
  onChange,
  leftTitleKey,
  labelsKeys,
  labels,
  disabledFilters = [],
  onClickDisabled,
  currentFilter,
  currentFilters,
  erroredFilters,
}) => {
  const { t } = useTranslation();

  const isTranslatedLabels = Boolean(labels);

  return (
    <div className="inline-flex items-center">
      {leftTitleKey && (
        <span className="ml-2 text-xs font-semibold text-gray-400 uppercase">
          {t(leftTitleKey)} |
        </span>
      )}
      {(labelsKeys || labels).map((f) => (
        <Badge
          {...{ onClickDisabled }}
          key={f}
          label={isTranslatedLabels ? f : t(f)}
          colorVariant={
            (currentFilters ? currentFilters.includes(f) : currentFilter === f)
              ? erroredFilters?.includes(f)
                ? "red"
                : "blue"
              : erroredFilters?.includes(f)
              ? "lightRed"
              : "white"
          }
          disabled={disabledFilters?.includes(f)}
          onClick={() => onChange(f)}
        />
      ))}
    </div>
  );
};

const HeaderFiltersPieces = () => {
  const { piecesFilter, setPiecesFilter } = useCurrentGarmentContext();

  return (
    <Filters
      onChange={setPiecesFilter}
      labelsKeys={["all", "error", "uncontrolled", "controlled"]}
      currentFilter={piecesFilter}
    />
  );
};

const HeaderFiltersPositioning = () => {
  const { positioningFilter, setPositioningFilter } =
    useCurrentGarmentContext();

  return (
    <Filters
      onChange={setPositioningFilter}
      labelsKeys={["all", "notPositioned", "positioned"]}
      currentFilter={positioningFilter}
    />
  );
};

const HeaderFiltersStiching = () => {
  const {
    stitchingFilters,
    changeStitchingFilters,
    sorted3dPieces,
    getStitchError,
  } = useCurrentGarmentContext();
  const { showModal } = useModal();

  const { all, notPositioned } = sorted3dPieces;

  return (
    <Filters
      onChange={changeStitchingFilters}
      onClickDisabled={() =>
        showModal("info", {
          titleKey: "stitchingDisabledPieceTitle",
          messageKey: "stitchingDisabledPieceMsg",
        })
      }
      labels={all.map((p) => p.blender_object_name)}
      disabledFilters={notPositioned.map((p) => p.blender_object_name)}
      erroredFilters={all
        .filter((p) =>
          Boolean(getStitchError(undefined, p.id, "atLeastOnStitch"))
        )
        .map((p) => p.blender_object_name)}
      currentFilters={stitchingFilters}
      leftTitleKey="pieceFilter"
    />
  );
};

const HeaderPreview = () => {
  const { t } = useTranslation();

  const {
    selectedPreviewColorVariantId,
    changePreviewSelectedColorVariant,
    garment,
    handlePreview,
    isPreviewInitializing,
    isGarmentInitialLoading,
  } = useCurrentGarmentContext();

  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2 mt-4 mb-2">
      <div className="flex flex-row">
        <div className="flex md:items-center gap-2">
          <span className="w-full ml-2 text-xs font-semibold text-gray-400 uppercase">
            {t("textureSelector")} :
          </span>
        </div>
        <div
          className="flex flex-col md:flex-row md:items-center gap-2 "
          title={t("onlySavedTexture")}
        >
          <DropdownInput
            isLoading={isGarmentInitialLoading}
            options={garment?.color_variants?.map((c) => c.id) || []}
            selectedOption={selectedPreviewColorVariantId}
            onChange={(option) => {
              changePreviewSelectedColorVariant(option);
            }}
            getLabelOptionFromOption={(option) => option}
            getLabelInputFromOption={(option) => option}
            customWidthClass="min-w-[240px]"
            fullWidth
          />
        </div>
      </div>
      <BigButton
        onClick={handlePreview}
        label={t("preview")}
        colorVariant="lightBlue"
        isLoading={isPreviewInitializing}
      />
    </div>
  );
};

/* CONTENT COMPONENTS */

const HEADERS = {
  general: { firstRow: { titleKey: "sideBarGarment", titleEmote: "👕" } },
  pieces: {
    firstRow: { titleKey: "sideBarPieces", titleEmote: "🧩" },
    secondRow: { leftComponent: <HeaderFiltersPieces /> },
  },
  positioning: {
    firstRow: { titleKey: "sideBarPositioning", titleEmote: "🎯" },
    secondRow: { leftComponent: <HeaderFiltersPositioning /> },
  },
  stitching: {
    firstRow: { titleKey: "sideBarStitching", titleEmote: "🪡" },
    secondRow: { leftComponent: <HeaderFiltersStiching /> },
  },
  texture: { firstRow: { titleKey: "sideBarTexture", titleEmote: "🧶" } },
  preview: {
    firstRow: { titleKey: "sideBar3dPreview", titleEmote: "🪞" },
    secondRow: { leftComponent: <HeaderPreview /> },
  },
  secondaryPieces: {
    firstRow: { titleKey: "sideBarSecondaryPieces", titleEmote: "🎽" },
  },
};

const GarmentPageHeader = ({ type }) => {
  const { t } = useTranslation();
  const { firstRow, secondRow } = HEADERS[type];

  return (
    <>
      <div className="border-b border-gray-200">
        {firstRow && (
          <div className="sm:flex sm:justify-between items-start w-full mb-2">
            {/* Left: Title */}
            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
              {t(firstRow.titleKey)} {firstRow.titleEmote}
            </h1>

            {/* Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              {firstRow.rightActions}
            </div>
          </div>
        )}
        <div className="w-full mb-2 min-h-[20px]">
          {secondRow && (
            <>
              {/* Left */}
              <div className="">{secondRow.leftComponent}</div>

              {/* Right */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Here put your right action component if there is one depending of type */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GarmentPageHeader;
