import dayjs from "dayjs";
import { NORMAL_MAP_OPTIONS } from "layouts/texture/NormalMap";
import { v4 as uuidv4 } from "uuid";

/**
 * Parse a cognito user attributs object to match with front redux store keys
 * @param {object} cognitoAttributes Object of user's attributs return by cognito request
 * @returns
 */
export const parseCognitoUser = (cognitoAttributes) => {
  return {
    id: cognitoAttributes.sub,
    email: cognitoAttributes.email,
  };
};

/**
 *
 * @param {*} date A valid date
 * @param {string} format A valid dayjs format of https://day.js.org/docs/en/display/format
 * @returns
 */
export const getFormattedDateOrDash = (date, format = "L") => {
  if (dayjs(date).isValid()) {
    return dayjs(date).format(format);
  } else {
    return "-";
  }
};

export const parseGarmentFromApi = (garment) => {
  // Removed piece_type form positioning object inside 3d_pieces (unused)
  const parsedGarment = {
    ...garment,
    "3d_pieces": garment["3d_pieces"].map((p3d) => {
      delete p3d.positioning.piece_type;
      return p3d;
    }),
  };

  // Add id inside all pois, dxf_pieces and 3d_pieces of garment
  const garmentWithId = addIdInPiecesAndPoisAndStitches(parsedGarment);
  return garmentWithId;
};

export const generateId = (itemKey, index) => {
  switch (itemKey) {
    case "dxf":
      return index;
    case "poi":
      return `poi_${uuidv4()}-${index}`;
    case "3d":
      return `3d_${uuidv4()}-${index}`;
    case "stitch":
      return `stitch_${uuidv4()}-${index}`;
    default:
      console.error(
        ">>> generateId : itemKey is not dxf_piece, poi, 3d or stitch"
      );
  }
};

/**
 * Add id inside all pois, dxf_pieces and 3d_pieces of garment object. This keys is required to manage the pois and pieces in frontend because without unique id, the object cannot be updated without be unselected
 * @param {string} garment garment object received from backend
 * @returns New object with id keys added
 */
const addIdInPiecesAndPoisAndStitches = (garment) => {
  // Add id to dxf_pieces, 3d_pieces and stitches
  const withId = {
    ...garment,
    dxf_pieces: garment.dxf_pieces.map((piece, i) => ({
      id: generateId("dxf", i),
      ...piece, // After id to keep if already existing in backend
      pois: piece.pois.map((poi, j) => ({
        id: generateId("poi", j),
        ...poi, // After id to keep if already existing in backend
      })),
    })),
    "3d_pieces": garment["3d_pieces"].map((piece, i) => ({
      id: generateId("3d", i),
      ...piece, // After id to keep if already existing in backend
    })),
    stitches: garment.stitches.map((stitch, i) => ({
      id: generateId("stitch", i),
      ...stitch, // After id to keep if already existing in backend
    })),
  };

  // Added dxfPieceId to link 3d_pieces to the dxf_piece which corresponds to the same type (necessary to know which 3d_pieces need to be updated when we update the dxf_pieces list)
  withId["3d_pieces"].forEach((piece_3d, i) => {
    const dxfPiece = withId.dxf_pieces.find((p) => p.type === piece_3d.type);
    if (dxfPiece) {
      piece_3d.dxfPieceId = dxfPiece.id;
    } else {
      console.error(
        `>>> Impossible to find dxf_piece with type "${piece_3d.type}". The 3d_pieces with the blender_object_name "${piece_3d.blender_object_name}". Check if garment send by API are a matching dxf_pieces type for each 3d_pieces type`
      );
    }
  });

  // Added poi-start/end-id to link poi-start and poi-end of stitches to the dxf piece poi (necessary to rename pois without complexity to find the pois to modify inside stitches) AND piece3dId to link piece1 and piece2 to 3dpiece
  withId["stitches"].forEach((stitch, i) => {
    ["piece1", "piece2"].forEach((key) => {
      const p3d = withId["3d_pieces"].find(
        (p) => p.blender_object_name === stitch[key].blender_object_name
      );
      const dxfPiece = withId.dxf_pieces.find((p) => p.id === p3d.dxfPieceId);

      stitch[key].poiStartId = dxfPiece.pois.find(
        (poi) => poi.name === stitch[key].poi_start
      ).id;
      stitch[key].poiEndId = dxfPiece.pois.find(
        (poi) => poi.name === stitch[key].poi_end
      ).id;
      stitch[key].piece3dId = p3d.id;
    });
  });

  return withId;
};

/**
 * Calculate the Levenshtein distance between two strings.
 *
 * @param {string} a - The first string.
 * @param {string} b - The second string.
 * @returns {number} The Levenshtein distance between the two strings.
 */
const _levenshteinDistance = (a, b) => {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = Array.from({ length: b.length + 1 }, (_, i) => [i]);
  matrix[0] = Array.from({ length: a.length + 1 }, (_, i) => i);

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      const cost = a[j - 1] === b[i - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + cost
      );
    }
  }

  return matrix[b.length][a.length];
};

/**
 * Search for items in an array using a scoring algorithm based on Levenshtein distance.
 *
 * @param {string[]} dataArray - The array of strings to search in.
 * @param {string} searchTerm - The term to search for.
 * @returns {Object[]} An array of objects containing the search results with scores.
 * Each object in the array has the format: { item: string, score: number }
 * The 'item' property represents the matched string, and 'score' represents its relevance.
 * Higher scores indicate higher relevance.
 */

export const searchInArrayWithScoring = (dataArray, searchTerm) => {
  const results = [];
  for (const item of dataArray) {
    const distance = _levenshteinDistance(
      searchTerm.toLowerCase(),
      item.toLowerCase()
    );
    const score = 1 - distance / Math.max(searchTerm.length, item.length);
    if (score > 0) {
      results.push({ item, score });
    }
  }

  results.sort((a, b) => b.score - a.score);
  return results.map((result) => result.item);
};

/**
 * Parses the data of a normal map for 3D garment texture generation.
 *
 * @param {Object} normaMap - The normal map data to be parsed.
 * @returns {Object|null} - An object containing parsed normal map data, or null if the input is invalid.
 *
 * @typedef {Object} NormalMapData
 * @property {string} type - The type of the normal map (e.g., 'library', 'uploads', 'custom').
 * @property {string|null} id - The ID of the selected normal map (applicable for 'library' and 'uploads' types).
 * @property {string|null} name - The name of the normal map (applicable for 'custom' type).
 * @property {number} size - The size of the normal map (applicable for 'custom' type).
 * @property {number} rotation - The rotation angle of the normal map (applicable for 'custom' type).
 */
export const parseNormalMapData = (normaMap) => {
  if (!normaMap) return null;

  let normaMapObj = {
    type: normaMap.type,
  };

  if (
    normaMap.type === NORMAL_MAP_OPTIONS.LIBRARY ||
    normaMap.type === NORMAL_MAP_OPTIONS.UPLOADS
  ) {
    normaMapObj.id = normaMap?.selectedNormalMap?.id ?? null;
  }

  if (normaMap.type === NORMAL_MAP_OPTIONS.CUSTOM) {
    normaMapObj.name = normaMap?.name ?? null;
    normaMapObj.size = normaMap?.size ?? 0;
    normaMapObj.rotation = normaMap?.rotation ?? 0;
  }

  return normaMapObj;
};
