import { Outlet, useLocation } from "react-router-dom";
import GlobalGarmentSidebar from "layouts/sidebar/GlobalGarmentSidebar";
import { SideBarProvider } from "context/SideBarContext";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import { useMemo } from "react";

const WithSideBar = () => {
  const { pathname } = useLocation();
  const { editedCoordPoiId, editedStitchId } = useCurrentGarmentContext();

  const svgViewer = useMemo(() => {
    if (!pathname.includes("pieces") || !editedCoordPoiId) return null;
    let svgViewerOffset = { top: 0, left: 0, width: 0, height: 0 };
    if (editedCoordPoiId) {
      svgViewerOffset = document
        .getElementById("svg-viewer")
        .getBoundingClientRect();
    }
    return svgViewerOffset;
  }, [editedCoordPoiId, pathname]);

  const threeViewer = useMemo(() => {
    if (!pathname.includes("stitching") || !editedStitchId) return null;
    let threeViewerOffset = { top: 0, left: 0, width: 0, height: 0 };
    if (editedStitchId) {
      threeViewerOffset = document
        .getElementById("canvas-wrapper")
        .getBoundingClientRect();
    }
    return threeViewerOffset;
  }, [editedStitchId, pathname]);

  const boundingBox = svgViewer || threeViewer;

  return (
    <div
      className={`flex h-screen overflow-hidden ${
        boundingBox ? "pointer-events-none" : ""
      }`}
    >
      <SideBarProvider>
        <GlobalGarmentSidebar />
        <Outlet />
      </SideBarProvider>
      {boundingBox && (
        <div
          className="absolute inset-0 bg-gray-500 opacity-30 z-50"
          style={{
            clipPath: `polygon(0 0, ${boundingBox.left}px 0, ${
              boundingBox.left
            }px ${boundingBox.top + boundingBox.height}px, ${
              boundingBox.left + boundingBox.width
            }px ${boundingBox.top + boundingBox.height}px, ${
              boundingBox.left + boundingBox.width
            }px ${boundingBox.top}px, ${boundingBox.left}px ${
              boundingBox.top
            }px, ${boundingBox.left}px 0, 100% 0, 100% 100%, 0 100%)`,
          }}
        />
      )}
    </div>
  );
};

export default WithSideBar;
