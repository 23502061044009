const BadgeIcons = ({ type, grey, withRoundedBg }) => {
  const renderRoundedBg = (element) => {
    if (withRoundedBg) {
      return (
        <div
          className={`w-10 h-10 rounded-full flex items-center justify-center shrink-0 ${
            grey
              ? "bg-gray-100"
              : type === "error"
              ? "bg-red-100"
              : type === "success"
              ? "bg-green-100"
              : type === "warning"
              ? "bg-yellow-100"
              : type === "info"
              ? "bg-blue-avumi-100"
              : ""
          }`}
        >
          {element}
        </div>
      );
    } else return element;
  };

  switch (type) {
    case "error":
      return renderRoundedBg(
        <svg
          className={`w-4 h-4 shrink-0 fill-current ${
            grey ? "text-gray-300" : "text-red-500"
          }
          `}
          viewBox="0 0 16 16"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"></path>
        </svg>
      );
    case "success":
      return renderRoundedBg(
        <svg
          className={`w-4 h-4 shrink-0 fill-current ${
            grey ? "text-gray-300" : "text-green-500"
          }
        `}
          viewBox="0 0 16 16"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"></path>
        </svg>
      );
    case "warning":
      return renderRoundedBg(
        <svg
          className={`w-4 h-4 shrink-0 fill-current ${
            grey ? "text-gray-300" : "text-yellow-500"
          }
        `}
          viewBox="0 0 16 16"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"></path>
        </svg>
      );
    case "info":
      return renderRoundedBg(
        <svg
          className="w-4 h-4 shrink-0 fill-current text-blue-avumi-400"
          viewBox="0 0 16 16"
        >
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"></path>
        </svg>
      );

    default:
      return null;
  }
};

export default BadgeIcons;
