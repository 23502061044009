import React from "react";
import GarmentPagesWrapper from "layouts/contentPage/GarmentPagesWrapper";
import GarmentPageHeader from "layouts/headers/GarmentPageHeader";
import Cords from "layouts/secondaryPieces/Cords";
import Zippers from "layouts/secondaryPieces/Zippers";
import Pockets from "layouts/secondaryPieces/Pockets";
import ButtonLine from "layouts/secondaryPieces/ButtonLine";

const GarmentSecondaryPieces = () => {
  return (
    <GarmentPagesWrapper fullContentHeight>
      <GarmentPageHeader type="secondaryPieces" />
      <div className="bg-white shadow-lg rounded-sm mb-8 flex-grow p-4 overflow-hidden md:-mr-px">
        <div className="no-scrollbar overflow-y-auto h-full">
          <ul>
            <li>
              <Cords items={[{}, {}]} />
            </li>
            <li>
              <Zippers items={[{}]} />
            </li>
            <li>
              <Pockets items={[{ type: "PATCH" }, { type: "INSET" }]} />
            </li>
            <li>
              <ButtonLine items={[{}]} />
            </li>
          </ul>
        </div>
      </div>
    </GarmentPagesWrapper>
  );
};

export default GarmentSecondaryPieces;
