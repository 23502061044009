import { Canvas } from "@react-three/fiber";
import { Preload, useHelper, OrbitControls } from "@react-three/drei";
import { Suspense, useMemo, useRef, useState } from "react";
import R3fAvatar from "threeJs/R3fAvatar";
import { SpotLightHelper, SpotLight } from "three";
import R3fAxesHelpers from "threeJs/R3fAxesHelpers";
import R3fPieces from "threeJs/R3fPieces";
import R3fNullPoses from "threeJs/R3fNullPoses";
import { PiCursorThin, PiPuzzlePiece, PiPuzzlePieceFill } from "react-icons/pi";
import R3fPois from "./R3fPois";
import R3fStitches from "./R3fStitches";
import useDisableSelection from "./useDisableSelection";

const isDevEnv = process.env.NODE_ENV === "development";

const withHelpers = { lights: isDevEnv && false, grid: isDevEnv && true };
const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

const R3fViewer = ({
  mode, // positioning, stitching
}) => {
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
  const [isNullposesReady, setIsNullposesReady] = useState(false);
  const [isPiecesHidden, setIsPiecesHidden] = useState(false);

  const [message, setMessage] = useState(null);

  useDisableSelection();

  return (
    <div className="w-full h-full relative" id="canvas-wrapper">
      <Canvas
        shadows
        dpr={[1, 2]}
        onCreated={(state) => (state.gl.shadowMap.autoUpdate = false)}
      >
        <color attach="background" args={["#48494B"]} />

        <Lights />
        <Suspense fallback={null}>
          <Preload all />
          <R3fAvatar
            {...{ setIsAvatarLoaded, isAvatarLoaded, mode }}
            url="/three/workshop_test_avatar.gltf"
          />
          {isAvatarLoaded && (
            <R3fNullPoses
              {...{
                isAvatarLoaded,
                isNullposesReady,
                setIsNullposesReady,
                setMessage,
                mode,
              }}
            />
          )}
          {isNullposesReady && (
            <R3fPieces {...{ mode }} hidden={isPiecesHidden} />
          )}
          {mode === "stitching" && (
            <>
              <R3fPois {...{ setMessage }} />
              <R3fStitches />
            </>
          )}

          {withHelpers.grid && mode === "positioning" && <R3fAxesHelpers />}
        </Suspense>
        <OrbitControls makeDefault enablePan={true} />
      </Canvas>
      {/* Toast message */}
      {message && (
        <div className="absolute top-0 text-xs text-gray-600 z-50 m-2 px-2 bg-white">
          {message}
        </div>
      )}
      {/* Tutorial bottom */}
      <div className="absolute flex flex-col gap-2 bottom-2 left-0 text-xs text-white m-1 px-2">
        <div className="flex flex-row gap-2 ">
          <div className="px-1 bg-white text-black  rounded">
            {isMac ? "⌘" : "⇧"} + c
          </div>{" "}
          Center the camera
        </div>
        <div className="flex flex-row gap-2 ">
          <div className="px-1 bg-white text-black  rounded flex flex-row items-center">
            {isMac ? "⌘" : "⇧"} + <PiCursorThin className="w-4 h-4" />
          </div>{" "}
          Move the camera
        </div>
      </div>
      {/* Butttons */}
      <div className="absolute top-2 right-2 flex flex-col justify-between items-center gap-2">
        <button
          className={`w-8 h-8 ${
            isPiecesHidden ? "bg-gray-200" : "bg-white"
          } rounded flex items-center justify-center`}
          onClick={() => setIsPiecesHidden((cur) => !cur)}
        >
          {isPiecesHidden ? (
            <PiPuzzlePieceFill className="w-5 h-5 text-gray-600" />
          ) : (
            <PiPuzzlePiece className="w-5 h-5 text-gray-600" />
          )}
        </button>
      </div>
    </div>
  );
};

export default R3fViewer;

const Lights = () => {
  const spotlight1 = useMemo(() => new SpotLight("#fff"), []);
  const spotlight2 = useMemo(() => new SpotLight("#fff"), []);

  const spotlight1Ref = useRef();
  const spotlight2Ref = useRef();
  useHelper(withHelpers.lights && spotlight1Ref, SpotLightHelper, "cyan");
  useHelper(withHelpers.lights && spotlight2Ref, SpotLightHelper, "green");

  return (
    <>
      <ambientLight intensity={0.05} />
      <primitive
        ref={spotlight1Ref}
        object={spotlight1}
        position={[1, 4, 2]}
        angle={1}
        penumbra={1}
        intensity={0.5}
        castShadow
        shadow-mapSize={2048}
      />
      <primitive
        ref={spotlight2Ref}
        object={spotlight2}
        position={[-2, 4, -3]}
        intensity={0.3}
        angle={0.4}
        lookAt={[0, 5, 0]}
        penumbra={2}
        castShadow
        shadow-mapSize={1024}
      />
      <primitive object={spotlight1.target} position={[0, 1, 0]} />
      <primitive object={spotlight2.target} position={[0, 1, 0]} />
    </>
  );
};
