import React from "react";
import R3fViewer from "threeJs/R3fViewer";

function StitchingView() {
  return (
    <div className="flex flex-nowrap overflow-x-scroll flex-1">
      <div className="flex flex-1 w-full h-full">
        <R3fViewer mode="stitching" />
      </div>
    </div>
  );
}

export default StitchingView;
