import { useModal } from "context/ModalProvider";
import { uniq } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getNewStitchesWithModifiedStitches } from "utils/garment";
import { generateId } from "utils/parse";

const colors = [
  "#E63946", // Rouge vif
  "#F1C40F", // Jaune
  "#9B59B6", // Violet
  "#3498DB", // Bleu
  "#2ECC71", // Vert
  "#FA8072", // Saumon
  "#E8741E", // Orange
  "#16A085", // Vert océan
  "#D35400", // Citrouille
  "#2980B9", // Bleu océan
  "#8E44AD", // Violet foncé
  "#27AE60", // Vert foncé
  "#F39C12", // Jaune foncé
  "#C0392B", // Rouge foncé
  "#D4AC0D", // Or
  "#73C6B6", // Turquoise
  "#2874A6", // Bleu foncé
  "#A569BD", // Lavande
  "#45B39D", // Vert clair
  "#5499C7", // Bleu clair
];

const EMPTY_PIECE_STITCH_VALUES = {
  blender_object_name: null,
  piece_type: null,
  poi_start: null,
  poi_end: null,
  poiStartId: null,
  poiEndId: null,
};

const useGarmentStitching = (
  editedGarment,
  setValues,
  errors,
  positionedPieces3d
) => {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const editedGarmentRef = useRef(editedGarment);

  const [stitchingFilters, setStitchingFilters] = useState(
    positionedPieces3d.map((el) => el.blender_object_name)
  );
  const [filteredStitches, setFilteredStitches] = useState(
    editedGarment?.stitches.filter(
      (s) =>
        stitchingFilters.includes(s.piece1.blender_object_name) &&
        stitchingFilters.includes(s.piece2.blender_object_name)
    ) || []
  );

  const [editedStitchId, setEditedStitchId] = useState(null);
  const [editedStitch, setEditedStitch] = useState(null);
  const [edited3dPieces, setEdited3dPieces] = useState([]);

  useEffect(() => {
    editedGarmentRef.current = editedGarment;
  }, [editedGarment]);

  useEffect(() => {
    setStitchingFilters((cur) =>
      cur.filter((name) =>
        positionedPieces3d.map((p) => p.blender_object_name).includes(name)
      )
    );
  }, [positionedPieces3d]);

  useEffect(() => {
    const newFilteredStitches =
      editedGarment?.stitches
        .filter(
          (s) =>
            stitchingFilters.includes(s.piece1.blender_object_name) &&
            stitchingFilters.includes(s.piece2.blender_object_name)
        )
        .map((s, i) => ({
          ...s,
          color: colors[i % colors.length],
        })) || [];

    // Added editedStitch to draw the stitch on live when stitching is editing or creating
    if (editedStitch) {
      newFilteredStitches.push({
        ...editedStitch,
        id: "building",
        color:
          editedStitch.id === "creating"
            ? "#FFFFFF"
            : newFilteredStitches.find((s) => s.id === editedStitch.id).color,
      });
    }

    setFilteredStitches(newFilteredStitches ?? []);
  }, [editedGarment?.stitches, stitchingFilters, editedStitch, edited3dPieces]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        _closeEditionOrCreationMode();
      }
    };
    if (editedStitchId) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [editedStitchId]);

  useEffect(() => {
    // Handle step for edit or create stitching mode
    if (editedStitch) {
      const step = getStepOfEditOrCreateStitch(editedStitch);
      if (step === 5) {
        // Editing or creating is finished
        if (editedStitch.id === "creating") {
          editedStitch.id = generateId("stitch", "new");
          setValues({
            ...editedGarmentRef.current,
            stitches: [...editedGarmentRef.current.stitches, editedStitch],
          });
        } else {
          changeStitching(editedStitch.id, editedStitch);
        }
        _closeEditionOrCreationMode();
      } else {
        toast.dismiss(`edit-mode-toast-${step - 1}`);
        toast(
          <div>
            {t(`selectPoi${step}`, {
              pieceName:
                step === 3
                  ? editedStitch.piece1.blender_object_name
                  : step === 4
                  ? editedStitch.piece2.blender_object_name
                  : undefined,
            })}
            <br />
            {t("closeToastWithEsc")}
          </div>,
          {
            toastId: `edit-mode-toast-${step}`,
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          }
        );
      }
    } else {
      // Dismiss all toasts
      for (let i = 1; i <= 4; i++) {
        toast.dismiss(`edit-mode-toast-${i}`);
      }
      toast.dismiss("error-edit-mode-toast");
    }
  }, [editedStitch, editedStitchId]);

  /* ******* METHODS ******* */

  const _closeEditionOrCreationMode = () => {
    setEditedStitchId(null);
    setEdited3dPieces([]);
    toast.dismiss("edit-mode-toast");
    // Remove all poi not fully created (coordinates not set)
    setEditedStitch(null);
  };

  const changeStitchingFilters = useCallback((piece3dName) => {
    setStitchingFilters((cur) =>
      cur.includes(piece3dName)
        ? cur.filter((el) => el !== piece3dName)
        : [...cur, piece3dName]
    );
  }, []);

  const changeStitching = useCallback(
    (stitchingId, newValues) => {
      const newStitches = getNewStitchesWithModifiedStitches(
        editedGarment,
        stitchingId,
        newValues
      );
      setValues({ ...editedGarment, stitches: newStitches });
    },
    [editedGarment, setValues]
  );

  const handleDeleteStitch = useCallback(
    (stitchId) => {
      showModal("warning", {
        titleKey: "stitchRemoveTitle",
        messageKey: "stitchRemoveMsg",
        onValidate: () => {
          setValues({
            ...editedGarment,
            stitches: editedGarment.stitches.filter((s) => s.id !== stitchId),
          });
        },
        onCancel: () => {},
        isDestructive: true,
      });
    },
    [editedGarment]
  );

  const handleCreateStitch = useCallback(() => {
    const newStitch = {
      id: "creating",
      piece1: EMPTY_PIECE_STITCH_VALUES,
      piece2: EMPTY_PIECE_STITCH_VALUES,
    };
    // Set the newStitch into edit state
    setEditedStitch(newStitch);
    setEditedStitchId(newStitch.id);
  }, []);

  const handleEditStitch = useCallback(
    (stitchId) => {
      setEditedStitchId(stitchId);
      const currentStitch = editedGarment?.stitches.find(
        (s) => s.id === stitchId
      );
      // Display only edited stitch pieces for edition mode
      setEdited3dPieces(
        uniq([
          currentStitch.piece1.blender_object_name,
          currentStitch.piece2.blender_object_name,
        ])
      );

      setEditedStitch({
        ...currentStitch,
        piece1: { ...currentStitch.piece1, ...EMPTY_PIECE_STITCH_VALUES },
        piece2: { ...currentStitch.piece2, ...EMPTY_PIECE_STITCH_VALUES },
      });
    },
    [editedGarment]
  );

  const selectPoiForStitch = useCallback(
    (poiId, poiName, parent3dPiece) => {
      const step = getStepOfEditOrCreateStitch(editedStitch);
      switch (step) {
        case 1:
        case 2:
          setEditedStitch((cur) => ({
            ...cur,
            [`piece${step}`]: {
              ...cur[`piece${step}`],
              blender_object_name: parent3dPiece.blender_object_name,
              piece3dId: parent3dPiece.id,
              piece_type: parent3dPiece.type,
              poi_start: poiName,
              poiStartId: poiId,
            },
          }));
          break;
        case 3:
        case 4:
          // Check if piece is the same of step 1 or 2 (stitch.piece1.blender_object_name)
          const pieceNumber = step - 2;
          if (
            parent3dPiece.id === editedStitch[`piece${pieceNumber}`].piece3dId
          ) {
            setEditedStitch((cur) => ({
              ...cur,
              [`piece${pieceNumber}`]: {
                ...cur[`piece${pieceNumber}`],
                blender_object_name: parent3dPiece.blender_object_name,
                piece3dId: parent3dPiece.id,
                piece_type: parent3dPiece.type,
                poi_end: poiName,
                poiEndId: poiId,
              },
            }));
            break;
          } else {
            toast(
              t("errorPoiSelectionPiece", {
                pieceName:
                  editedStitch[`piece${pieceNumber}`].blender_object_name,
              }),
              {
                toastId: "error-edit-mode-toast",
                autoClose: true,
                closeOnClick: true,
                draggable: true,
                type: "error",
                position: "top-right",
                icon: false,
              }
            );
          }
          break;
        default:
          console.warn(">>> selectPoiForStitch : step is not 0, 1, 2 or 3");
      }
    },
    [editedStitch, t]
  );

  const getStitchError = useCallback(
    (stitchId, piece3dId, fieldKey) => {
      if (piece3dId) {
        return errors?.stitching?.find(
          (e) => e.piece3dId === piece3dId && e.fieldKey === fieldKey
        );
      } else {
        return errors?.stitching?.find(
          (e) => e.stitchId === stitchId && e.fieldKey === fieldKey
        );
      }
    },
    [errors]
  );

  const getHasStitchingError = useCallback(
    (stitchId, piece3dId) => {
      if (!stitchId && !piece3dId) {
        return errors?.stitching?.length > 0;
      } else {
        if (piece3dId) {
          return errors?.stitching?.some((e) => e.piece3dId === piece3dId);
        } else {
          return errors?.stitching?.some((e) => e.stitchId === stitchId);
        }
      }
    },
    [errors]
  );

  return {
    stitchingFilters,
    changeStitchingFilters,
    stitches: editedGarment?.stitches || [],
    filteredStitches,
    changeStitching,
    handleDeleteStitch,
    handleCreateStitch,
    handleEditStitch,
    editedStitchId,
    editedStitch,
    setEditedStitch,
    selectPoiForStitch,
    edited3dPieces,
    getStitchError,
    getHasStitchingError,
  };
};

export default useGarmentStitching;

const getStepOfEditOrCreateStitch = (editedStitch) => {
  const step = editedStitch.piece2.poi_end
    ? 5
    : editedStitch.piece1.poi_end
    ? 4
    : editedStitch.piece2.poi_start
    ? 3
    : editedStitch.piece1.poi_start
    ? 2
    : 1;
  return step;
};
