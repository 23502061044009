import { pageUrls } from "routes/paths";
import RequireAuth from "pages/hoc/RequireAuth";
import { Route, Routes } from "react-router-dom";

// Import pages
import PageNotFound from "pages/PageNotFound";
import SignIn from "pages/SignIn";
import Home from "pages/Home";
import WithSideBar from "layouts/sidebar/WithSideBar";
import GarmentGeneral from "pages/GarmentGeneral";
import GarmentStitching from "pages/GarmentStitching";
import GarmentPieces from "pages/GarmentPieces";
import GarmentPositioning from "pages/GarmentPositioning";
import GarmentTexture from "pages/GarmentTexture";
import GarmentPreview from "pages/GarmentPreview";
import { CurrentGarmentProvider } from "context/currentGarment/CurrentGarmentProvider";
import GarmentSecondaryPieces from "pages/GarmentSecondaryPieces";

const MyRouter = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route exact path={pageUrls.home} element={<Home />} />
        <Route
          element={
            <CurrentGarmentProvider>
              <WithSideBar />
            </CurrentGarmentProvider>
          }
        >
          <Route
            exact
            path={pageUrls.garment.general}
            element={<GarmentGeneral />}
          />
          <Route
            exact
            path={pageUrls.garment.pieces}
            element={<GarmentPieces />}
          />
          <Route
            exact
            path={pageUrls.garment.positioning}
            element={<GarmentPositioning />}
          />
          <Route
            exact
            path={pageUrls.garment.stitching}
            element={<GarmentStitching />}
          />
          <Route
            exact
            path={pageUrls.garment.texture}
            element={<GarmentTexture />}
          />
          <Route
            exact
            path={pageUrls.garment.preview}
            element={<GarmentPreview />}
          />
          <Route
            exact
            path={pageUrls.garment.secondary_pieces}
            element={<GarmentSecondaryPieces />}
          />
        </Route>
      </Route>
      <Route exact path={pageUrls.login} element={<SignIn />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MyRouter;
