import { OrbitControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useR3fPreviewContext } from "context/R3fPreviewProvider";
import { Vector3 } from "three";

const SECURE_HEIGHT_OFFSET = 0.1; // Keep offset for ceilY and floorY calculation before calculate max and min polar angle

const R3fPreviewControls = ({ ...props }) => {
  const { ceilY } = useR3fPreviewContext();

  useFrame(({ controls }) => {
    if (ceilY && controls) {
      var minPan = new Vector3(0, 0, 0);
      var maxPan = new Vector3(0, ceilY, 0);
      controls.target.clamp(minPan, maxPan);

      const adjacentCeil = Math.max(
        ceilY - controls.target.y - SECURE_HEIGHT_OFFSET,
        SECURE_HEIGHT_OFFSET
      );
      const adjacentFloor = Math.min(
        0 - controls.target.y + SECURE_HEIGHT_OFFSET,
        SECURE_HEIGHT_OFFSET
      );

      const hyp = controls.getDistance();

      // min and max polar angle : default Math.PI ad between 0 to Math.PI for min and max

      const maxPolarAngle = Math.acos(adjacentFloor / hyp) || 0;
      const minPolarAngle = Math.acos(adjacentCeil / hyp) || 0;

      if (minPolarAngle) {
        controls.minPolarAngle = minPolarAngle;
      }
      if (maxPolarAngle) {
        controls.maxPolarAngle = maxPolarAngle;
      }
    }
  });

  return <OrbitControls makeDefault enablePan={true} {...props} />;
};

export default R3fPreviewControls;
