import { useModal } from "context/ModalProvider";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUnsavedGarment } from "store";
import { removeUnsavedGarment } from "store";

const usePreventLooseUnsavedData = (
  garmentId,
  changeEditedGarment,
  values,
  hasChanges,
  isGarmentFetched
) => {
  const unsavedGarments = useSelector((state) => state.unsavedGarments);
  const { showModal } = useModal();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);

  const checkUnsavedData = useCallback(() => {
    if (unsavedGarments[garmentId]) {
      showModal("warning", {
        titleKey: "unsavedGarmentsTitle",
        messageKey: "unsavedGarmentsMsg",
        onValidate: () => {
          changeEditedGarment(unsavedGarments[garmentId]);
        },
        onCancel: () => {
          dispatch(removeUnsavedGarment(garmentId));
        },
        validateKey: "restore",
        cancelKey: "remove",
        isCancelDestructive: true,
      });
    }
  }, [changeEditedGarment, dispatch, garmentId, showModal, unsavedGarments]);

  useEffect(() => {
    if (isGarmentFetched && !isChecked) {
      checkUnsavedData();
      setIsChecked(true);
    }
  }, [isGarmentFetched, isChecked, checkUnsavedData]);

  useEffect(() => {
    if (isGarmentFetched) {
      if (hasChanges !== null) {
        if (Object.keys(hasChanges).some((key) => hasChanges[key])) {
          dispatch(addUnsavedGarment({ garmentId, garment: values }));
        } else {
          dispatch(removeUnsavedGarment(garmentId));
        }
      }
    }
  }, [hasChanges, values, dispatch, garmentId, isGarmentFetched]);
};

export default usePreventLooseUnsavedData;
