import { createContext, useContext, useState } from "react";

const R3fPreviewContext = createContext({});

export const R3fPreviewProvider = ({ children }) => {
  const [sceneOptions, setSceneOptions] = useState();
  const [ceilY, setCeilY] = useState();

  return (
    <R3fPreviewContext.Provider
      value={{ setSceneOptions, sceneOptions, ceilY, setCeilY }}
    >
      {children}
    </R3fPreviewContext.Provider>
  );
};

export const useR3fPreviewContext = () => useContext(R3fPreviewContext);
