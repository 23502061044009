export const garmentStatuses = [
  {
    key: "IN_REVIEW",
    label: "In review",
    color: "red",
  },
  {
    key: "TO_REVIEW",
    label: "To review",
    color: "orange",
  },
  {
    key: "REVIEWED",
    label: "Reviewed",
    color: "green",
  },
];

export const pieceList = [
  "CENTER_FRONT",
  "SIDE_FRONT",
  "CENTER_BACK",
  "SIDE_BACK",
  "BACK_WAISTBAND",
  "BACK_YOKE",
  "FRONT_WAISTBAND",
  "WAISTBAND",
  "COLLAR",
  "COLLAR_STAND",
  "NECKBAND",
  "RIGHT_BACK_BELT",
  "LEFT_BACK_BELT",
  "RIGHT_BACK_UNDERBELT",
  "LEFT_BACK_UNDERBELT",
  "RIGHT_FRONT_BELT",
  "LEFT_FRONT_BELT",
  "LEFT_FRONT_POCKET",
  "RIGHT_FRONT_POCKET",
  "LEFT_BACK_POCKET",
  "RIGHT_BACK_POCKET",
  "FRONT_NECKBAND",
  "BACK_NECKBAND",
  "RIGHT_FRONT",
  "LOWER_FRONT",
  "LOWER_BACK",
  "UPPER_FRONT",
  "UPPER_BACK",
  "RIGHT_BACK_WAISTBAND",
  "LEFT_BACK_WAISTBAND",
  "RIGHT_FRONT_WAISTBAND",
  "LEFT_FRONT_WAISTBAND",
  "UPPER_LEFT_BACK",
  "UPPER_RIGHT_BACK",
  "LOWER_LEFT_FRONT",
  "LOWER_RIGHT_FRONT",
  "UPPER_LEFT_FRONT",
  "UPPER_RIGHT_FRONT",
  "LEFT_FRONT",
  "RIGHT_BACK",
  "LEFT_BACK",
  "FRONT",
  "BACK",
  "SLEEVE",
  "RAGLAN_SLEEVE",
  "SIDE",
  "REFERENCE",
  "UPPER_SLEEVE",
  "LOWER_SLEEVE",
  "STRAP",
  "CUFF",
  "HOOD",
  "LEFT_HOOD",
  "RIGHT_HOOD",
];

export const topPositions = ["TOP", "LEFT", "BOTTOM", "RIGHT"];

export const DO_NOT_USE = null;

export const faces = ["front", "back", "left", "right"];
export const origins = ["min", "center", "max"];
export const processes = {
  left: [
    { value: "min", keyText: "furthestRight" },
    { value: "max", keyText: "furthestLeft" },
  ],
  top: [
    { value: "min", keyText: "lowest" },
    { value: "max", keyText: "highest" },
  ],
  backward: [
    { value: "min", keyText: "closest" },
    { value: "max", keyText: "mostBehind" },
  ],
};

export const avatarsFilterKeys = ["gender", "size", "body_shape"];

export const avatarsFilterOptions = {
  gender: ["all_gender", "male", "female"],
  size: ["all_size", "size_XS", "size_S", "size_M", "size_L", "size_XL"],
  body_shape: [
    "all_body_shape",
    "rectangle",
    "triangle",
    "inverted_triangle",
    "circle",
    "hourglass",
  ],
};

export const logoPositon = {
  TOP: ["FRONT_CENTER", "BACK_CENTER", "FRONT_TOP_LEFT", "FRONT_TOP_RIGHT"],
  BOTTOM: ["POCKET_LEFT", "POCKET_RIGHT"],
  SKIRT: ["POCKET_LEFT", "POCKET_RIGHT"],
  DRESS: ["FRONT_CENTER", "BACK_CENTER", "FRONT_TOP_LEFT", "FRONT_TOP_RIGHT"],
};
