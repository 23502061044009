import React from "react";
import UserMenu from "components/dropdown/DropdownProfile";
import AvumiBannerImage from "images/avumi-3ds-banner.png";
import { useTranslation } from "react-i18next";
import RoundedIconsButton from "components/buttons/RoundedIconsButton";
import { FiArrowLeft, FiSave, FiRotateCcw, FiMenu } from "react-icons/fi";
import { useSideBar } from "context/SideBarContext";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import { pageUrls } from "routes/paths";
import ValidateGarmentButton from "components/buttons/ValidateGarmentButton";
import { useLocation } from "react-router-dom";

function Header({ type = "main" }) {
  const renderContent = () => {
    switch (type) {
      case "main":
        return <HeaderContentMain />;
      case "garment":
        return <HeaderContentGarment />;
      default:
        return <HeaderContentMain />;
    }
  };

  return (
    <header
      id="sticky-header"
      className="sticky top-0 bg-white border-b border-gray-200 z-30"
    >
      <div className="px-4 sm:px-6 lg:px-8">{renderContent()}</div>
    </header>
  );
}

export default Header;

const HeaderContentMain = () => {
  return (
    <div className="flex items-center justify-between h-16 -mb-px">
      {/* Header: Left side */}

      <div className="flex flex-1 h-14">
        {/* Avumi Banner */}
        <img
          className="object-contain object-center "
          src={AvumiBannerImage}
          alt="Authentication"
        />
      </div>

      {/* Header: Right side */}
      <div className="flex items-center space-x-3">
        <UserMenu align="right" />
      </div>
    </div>
  );
};

const HeaderContentGarment = () => {
  const { sidebarOpen, toggleSidebar } = useSideBar();
  const { saveChanges, resetChanges, hasChanges } = useCurrentGarmentContext();
  const { state } = useLocation();

  const hasOneChange =
    hasChanges && Object.keys(hasChanges).some((k) => hasChanges[k]);

  return (
    <div className="flex items-center justify-between h-16 -mb-px">
      <div className="mr-3 flex flex-row">
        {/* Header: Left side */}
        <div className="flex lg:hidden mr-3">
          {/* Hamburger button */}
          <button
            className="text-gray-500 hover:text-gray-600 lg:hidden"
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            onClick={toggleSidebar}
          >
            <span className="sr-only">Open sidebar</span>
            <FiMenu className="w-6 h-6 fill-current" />
          </button>
        </div>
        <RoundedIconsButton colorVariant="greyLight" to={pageUrls.home} replace>
          <span className="sr-only">Close</span>
          <FiArrowLeft />
        </RoundedIconsButton>
        {/* Garment title */}
        <h2 className="font-normal ml-3 text-xl text-gray-900 truncate block">
          {state?.name}
        </h2>
      </div>
      {/* Header: Right side */}
      <div className="mr-3 flex flex-row space-x-3">
        {hasOneChange ? (
          <>
            {" "}
            <RoundedIconsButton colorVariant="greyLight" onClick={resetChanges}>
              <span className="sr-only">Cancel</span>
              <FiRotateCcw className="w-5 h-5" />
            </RoundedIconsButton>
            <RoundedIconsButton
              colorVariant={"lightBlue"}
              onClick={saveChanges}
              type="button"
            >
              <span className="sr-only">Save</span>
              <FiSave className="w-5 h-5" />
            </RoundedIconsButton>
          </>
        ) : (
          <ValidateGarmentButton />
        )}
      </div>
    </div>
  );
};
