import React from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useGarments } from "context/GarmentsProvider";

function PaginationNumeric({}) {
  const { total, page: currentPage, changePage, count } = useGarments();
  const totalPages = Math.ceil(total / count);

  const renderNextOrPrevious = (mode) => {
    const Chevron = mode === "previous" ? FiChevronLeft : FiChevronRight;
    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === totalPages - 1;
    const isDisabled =
      (isFirstPage && mode === "previous") || (isLastPage && mode === "next");
    return (
      <button
        disabled={isDisabled}
        onClick={() =>
          changePage(mode === "previous" ? currentPage - 1 : currentPage + 1)
        }
        className={`${mode === "previous" ? "mr-2" : "ml-2"} ${
          isDisabled ? "opacity-30" : ""
        }`}
      >
        <span className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-gray-200 text-gray-300">
          <span className="sr-only">
            {mode === "previous" ? "Previous" : "Next"}
          </span>
          <wbr />
          <Chevron className="h-4 w-4" />
        </span>
      </button>
    );
  };

  const renderPageButtons = () => {
    let buttonPages = Array.from({ length: totalPages }).map((_, i) => i + 1);

    const isMoreThan5Pages = buttonPages.length > 5;

    if (isMoreThan5Pages) {
      // Add ... in first position if currentPage is after third page
      if (currentPage > 2) {
        buttonPages.splice(0, 2, "...");
      }
      // Add ... in last position
      if (currentPage + 3 < totalPages) {
        buttonPages = buttonPages.slice(0, 4);
        buttonPages.push("...");
      } else {
        buttonPages = buttonPages.slice(0, 5);
      }
    }

    return buttonPages.map((p, i) => {
      const isCurrentPage = p === currentPage + 1;
      const isDisabled = isCurrentPage || p === "...";
      return (
        <li key={i}>
          <button
            className={`inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white  border border-gray-200 text-gray-600 ${
              isDisabled
                ? "opacity-30"
                : "hover:bg-blue-avumi-300 hover:text-whiten"
            }`}
            disabled={isDisabled}
            onClick={p !== "..." ? () => changePage(p - 1) : undefined}
          >
            {p}
          </button>
        </li>
      );
    });
  };

  if (total === 0) return null;

  return (
    <div className="flex justify-center mt-8">
      <nav className="flex pb-8" role="navigation" aria-label="Navigation">
        {renderNextOrPrevious("previous")}
        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          {renderPageButtons()}
        </ul>
        {renderNextOrPrevious("next")}
      </nav>
    </div>
  );
}

export default PaginationNumeric;
