import { createSlice } from "@reduxjs/toolkit";
import { clearPrivate } from "store/globalActions";

const initialState = {
  authenticated: false,
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSignedIn(state, action) {
      return {
        ...state,
        authenticated: true,
      };
    },
  },
  extraReducers: (build) => {
    build.addCase(clearPrivate, (state, action) => initialState);
  },
});

const { actions, reducer } = userSlice;
export const { setSignedIn } = actions;
export default reducer;
