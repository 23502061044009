import { useQuery } from "@tanstack/react-query";
import { makeNormalMapsQueryOptions } from "api/react-query/queryFunctions";
import FileUploader from "components/fileUploader/FileUploader";
import ImagePreview from "components/imagePreview/ImagePreview";
import DropdownInput from "components/inputs/DropdownInput";
import InputNumber from "components/inputs/InputNumber";
import InputText from "components/inputs/InputText";
import React from "react";
import { useTranslation } from "react-i18next";

//TEMP DATA
//TODO: replace with real data
const brand_id = "P546c3b92-a7d0-445b-82ff-244edc3f7103";
//#TEMP DATA

export const NORMAL_MAP_OPTIONS = {
  AUTO: "AUTO",
  LIBRARY: "LIBRARY",
  UPLOADS: "UPLOADS",
  CUSTOM: "CUSTOM",
};

const NormalMap = ({ normalMap, setValue, isReadonly, errors }) => {
  const { t } = useTranslation();
  const { data: colorVariants } = useQuery(
    makeNormalMapsQueryOptions({
      brandId: brand_id,
    })
  );

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="flex-1">
        <h3 className="text-lg text-gray-800 font-bold mb-2">
          {t("normalMapTitle")}
        </h3>
        {!isReadonly && (
          <DropdownInput
            title={t("normalMapTypeTitle")}
            options={Object.values(NORMAL_MAP_OPTIONS)}
            selectedOption={normalMap?.type ?? ""}
            onChange={(option) => {
              setValue("type", option);
            }}
            getLabelOptionFromOption={(option) => option}
            getLabelInputFromOption={(option) => option}
            customWidthClass="min-w-auto"
            fullWidth
          />
        )}
        <div className="mt-2">
          {normalMap?.type === NORMAL_MAP_OPTIONS.LIBRARY && (
            <DropdownInput
              title={t("normalMapLibraryTitle")}
              options={colorVariants?.avumi_library?.items ?? []}
              selectedOption={normalMap?.selectedNormalMap}
              onChange={(option) => {
                setValue("selectedNormalMap", option);
              }}
              getIsSelectedFromOption={(option, selectedOption) =>
                option?.id === selectedOption?.id
              }
              getLabelOptionFromOption={(option) => option?.name}
              getLabelInputFromOption={(option) => option?.name}
              customWidthClass="min-w-auto"
              fullWidth
              disabled={isReadonly}
              error={
                errors?.selectedNormalMap?.keyText &&
                t(errors?.selectedNormalMap?.keyText)
              }
            />
          )}
          {normalMap?.type === NORMAL_MAP_OPTIONS.UPLOADS && (
            <DropdownInput
              title={t("normalMapUploadsTitle")}
              options={colorVariants?.partner_library?.items ?? []}
              selectedOption={normalMap?.selectedNormalMap}
              onChange={(option) => {
                setValue("selectedNormalMap", option);
              }}
              getIsSelectedFromOption={(option, selectedOption) =>
                option?.id === selectedOption?.id
              }
              getLabelOptionFromOption={(option) => option?.name}
              getLabelInputFromOption={(option) => option?.name}
              customWidthClass="min-w-auto"
              fullWidth
              disabled={isReadonly}
              error={
                errors?.selectedNormalMap?.keyText &&
                t(errors?.selectedNormalMap?.keyText)
              }
            />
          )}
        </div>
        <div className="grid gap-2 md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 mt-2">
          {(normalMap?.type === NORMAL_MAP_OPTIONS.CUSTOM || isReadonly) && (
            <>
              <InputText
                title={t("textureNameTitle")}
                type="text"
                customWidthClass="auto"
                value={normalMap?.name ?? ""}
                onChange={(e) => {
                  setValue("name", e.target.value);
                }}
                disabled={isReadonly}
                error={errors?.name?.keyText && t(errors?.name?.keyText)}
              />
              <InputNumber
                label={t("textureSizeTitle")}
                type="decimal"
                customWidthClass="auto"
                value={normalMap?.size ?? 0}
                min={0}
                onChange={(quantity) => {
                  setValue("size", quantity);
                }}
                disabled={isReadonly}
                error={errors?.size?.keyText && t(errors?.size?.keyText)}
              />
              <InputNumber
                label={t("textureRotationTitle")}
                type="integer"
                customWidthClass="auto"
                value={normalMap?.rotation ?? 0}
                onChange={(quantity) => {
                  setValue("rotation", quantity);
                }}
                disabled={isReadonly}
              />
            </>
          )}
        </div>
      </div>
      <div className="overflow-x-scroll no-scrollbar w-full md:w-[160px]">
        {normalMap?.type !== NORMAL_MAP_OPTIONS.AUTO &&
          (isReadonly || normalMap?.type !== NORMAL_MAP_OPTIONS.CUSTOM ? (
            <ImagePreview
              src={
                normalMap?.selectedNormalMap
                  ? normalMap?.selectedNormalMap?.preview
                  : normalMap?.file_url
              }
            />
          ) : (
            <FileUploader
              garmentType="garmentTextures"
              value={normalMap?.file_path}
              onChange={(value) => setValue("file_path", value)}
              error={
                errors?.file_path?.keyText && t(errors?.file_path?.keyText)
              }
            />
          ))}
      </div>
    </div>
  );
};

export default NormalMap;
