export const pageUrls = {
  login: "/login",
  home: "/",
  garment: {
    general: "garment/:garment_id/general",
    pieces: "garment/:garment_id/pieces",
    positioning: "garment/:garment_id/positioning",
    stitching: "garment/:garment_id/stitching",
    texture: "garment/:garment_id/texture",
    preview: "garment/:garment_id/preview",
    secondary_pieces: "garment/:garment_id/secondary-pieces",
  },
};
