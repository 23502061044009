import SmallButton from "components/buttons/SmallButton";
import DropdownInput from "components/inputs/DropdownInput";
import InputNumber from "components/inputs/InputNumber";
import InputText from "components/inputs/InputText";
import React from "react";
import { useTranslation } from "react-i18next";
import NormalMap from "./NormalMap";
import FileUploader from "components/fileUploader/FileUploader";
import ImagePreview from "components/imagePreview/ImagePreview";

const TextureContent = ({
  texture,
  selectedPieces,
  piecesOptions = [],
  isReadonly,
  index,
  setValue,
  onRemove,
  errors,
}) => {
  const { t } = useTranslation();
  const options = (texture?.pieces || texture?.options) ?? [];

  return (
    <div className="border-t border-gray-200 px-5 py-4">
      <DropdownInput
        title={t("texturePiecesTitle")}
        options={[".*", ...piecesOptions]}
        getLabelOptionFromOption={(option) =>
          option === ".*" ? t("allFemal") : option
        }
        selectedOptions={options ?? []}
        onChange={(option) => {
          let newOptions = selectedPieces.includes(option)
            ? options.filter((texture) => texture !== option)
            : [...options, option];
          // Remove .* option if selected option is a piece
          if (option === ".*") {
            newOptions = options.includes(".*") ? [] : [".*"];
          } else {
            newOptions = newOptions.filter((o) => o !== ".*");
          }
          setValue("options", newOptions);
        }}
        getIsSelectedFromOption={(option) => {
          return selectedPieces?.includes(option);
        }}
        multiSelect
        fullWidth
        error={errors?.options?.keyText && t(errors?.options?.keyText)}
        disabled={isReadonly}
        searchText={t("texturePlacesSearch")}
      />
      <div className="flex flex-col md:flex-row gap-4 mt-4 mb-4 pb-4 border-b border-gray-200">
        <div className="flex-1">
          <h3 className="text-lg text-gray-800 font-bold mb-2">
            {t("textureTitle")}
          </h3>
          <div className="grid gap-2 md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3">
            <InputText
              title={t("textureNameTitle")}
              type="text"
              customWidthClass="auto"
              value={texture?.name ?? ""}
              onChange={(e) => {
                setValue("name", e.target.value);
              }}
              error={errors?.name?.keyText && t(errors?.name?.keyText)}
              disabled={isReadonly}
            />
            <InputNumber
              label={t("textureSizeTitle")}
              type="decimal"
              customWidthClass="auto"
              value={texture?.size ?? 0}
              min={0}
              onChange={(quantity) => {
                setValue("size", quantity);
              }}
              error={errors?.size?.keyText && t(errors?.size?.keyText)}
              disabled={isReadonly}
            />
            <InputNumber
              label={t("textureRotationTitle")}
              type="integer"
              customWidthClass="auto"
              value={texture?.rotation ?? 0}
              onChange={(quantity) => {
                setValue("rotation", quantity);
              }}
              disabled={isReadonly}
            />
          </div>
        </div>
        <div className="overflow-x-scroll no-scrollbar w-full md:w-[160px]">
          {isReadonly ? (
            <ImagePreview src={texture?.file_url} />
          ) : (
            <FileUploader
              garmentType="garmentTextures"
              value={texture?.file_path}
              onChange={(value) => setValue("file_path", value)}
              error={
                errors?.file_path?.keyText && t(errors?.file_path?.keyText)
              }
            />
          )}
        </div>
      </div>
      <NormalMap
        normalMap={texture.normal_map}
        isReadonly={isReadonly}
        setValue={(key, value) => {
          if (key === "type") {
            setValue("normal_map", { [key]: value });
          } else {
            setValue("normal_map", { ...texture.normal_map, [key]: value });
          }
        }}
        errors={errors?.normal_map}
      />
      {Boolean(onRemove) && index >= 0 && (
        <div className="flex justify-end mt-4 pt-4 border-t border-gray-200">
          <SmallButton
            type="button"
            label={t("delete")}
            colorVariant="red"
            onClick={() => onRemove(index)}
          />
        </div>
      )}
    </div>
  );
};

export default TextureContent;
