import useGarmentPartnerAndBrandIds from "hooks/useGarmentPartnerAndBrandIds";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "./ModalProvider";
import { useCurrentGarmentContext } from "./currentGarment/CurrentGarmentProvider";
import {
  makeCreateGarmentColorVariantMutationOptions,
  makeDeleteGarmentColorVariantMutationOptions,
} from "api/react-query/mutationFunctions";
import { logoPositon } from "constants/garments";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { parseNormalMapData } from "utils/parse";
import { yupValidation } from "utils/yupValidation";
import { NORMAL_MAP_OPTIONS } from "layouts/texture/NormalMap";
import { useMutation } from "@tanstack/react-query";
import store from "store";
import { setSelectedPreviewColorVariantId } from "store";

const TextureContext = createContext({});

export const useTextureContext = () => useContext(TextureContext);

const TextureContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { garmentId, partnerId, brandId } = useGarmentPartnerAndBrandIds();
  const { showModal } = useModal();
  const { editedGarment } = useCurrentGarmentContext();

  const { mutate: createColorVariantMutate, isLoading: isCreateLoading } =
    useMutation(
      makeCreateGarmentColorVariantMutationOptions(garmentId, partnerId)
    );
  const { mutate: deleteColorVariantMutate, isLoading: isDeleteLoading } =
    useMutation(
      makeDeleteGarmentColorVariantMutationOptions(garmentId, partnerId)
    );

  const [isCreatingColorVariant, setIsCreatingColorVariant] = useState(false);
  const [selectedColorVariantId, setSelectedColorVariantId] = useState("");
  const [textureOpenIndex, setTextureOpenIndex] = useState(-1);
  const [logoOpenIndex, setLogoOpenIndex] = useState(-1);
  const [selectedPieces, setSelectedPieces] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);

  const colorVariants = editedGarment?.color_variants ?? [];
  const selectedColorVariant = colorVariants?.find(
    (colorVariant) => colorVariant.id === selectedColorVariantId
  );
  const piecesOptions =
    editedGarment?.["3d_pieces"]?.map((piece) => piece.blender_object_name) ??
    [];
  const logoPositions = editedGarment?.category
    ? logoPositon[editedGarment.category]
    : [];

  const { handleSubmit, handleReset, setFieldValue, values, errors } =
    useFormik({
      initialValues: {
        preview_path: undefined,
        textures: [],
        logos: [],
      },
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: async (values) => {
        if (
          !selectedPieces.includes(".*") &&
          !piecesOptions.every((p) => selectedPieces.includes(p))
        ) {
          toast(t("texturePiecesError"), {
            type: "warning",
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            style: {
              fontSize: "14px",
            },
          });
          return;
        }

        const data = {
          color_variants: [
            {
              preview_path: values?.preview_path?.fileName ?? null,
              textures:
                values.textures?.map((texture) => ({
                  file_path: texture?.file_path?.fileName ?? null,
                  pieces: texture?.options ?? null,
                  size: texture?.size ?? null,
                  rotation: texture?.rotation ?? 0,
                  normal_map: parseNormalMapData(texture?.normal_map),
                })) ?? [],
              logos:
                values.logos?.map((logo) => ({
                  positions: logo?.position ? [logo?.position] : null,
                  height: logo?.height ?? null,
                  width: logo?.width ?? null,
                  rotation: logo?.rotation ?? 0,
                  texture: {
                    file_path: logo?.texture?.file_path?.fileName ?? null,
                    normal_map: parseNormalMapData(logo?.texture?.normal_map),
                  },
                })) ?? [],
            },
          ],
          brand_id: brandId,
        };

        showModal("warning", {
          titleKey: "createColorVariantModalTitle",
          messageKey: "createColorVariantModalMsg",
          onValidate: () => {
            createColorVariantMutate(data, {
              onSuccess: (data) => {
                cancelTextureCreation();
                setSelectedColorVariantId(data.data.color_variants[0].id);
              },
            });
          },
          onCancel: () => {},
          validateKey: "create",
          cancelKey: "cancel",
          isCancelDestructive: true,
        });
      },
      validate: yupValidation("colorVariants"),
    });

  useEffect(() => {
    // Set selected pieces
    if (values.textures.length >= 0) {
      let pieces = [];
      values.textures.forEach((texture) => {
        if (texture.options) {
          pieces = [...pieces, ...texture.options];
        }
      });
      setSelectedPieces(pieces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.textures]);

  useEffect(() => {
    // Set selected positions
    if (values.logos.length >= 0) {
      let positions = [];
      values.logos.forEach((logo) => {
        if (logo.position) {
          positions = [...positions, logo.position];
        }
      });
      setSelectedPositions(positions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.logos]);

  /* ******* METHODS ******* */

  const handleSetSelectedColorVariantId = (id) => {
    setSelectedColorVariantId((prev) => (prev === id ? "" : id));
  };

  const handleAddTexture = () => {
    setTextureOpenIndex(values.textures.length);
    setFieldValue("textures", [
      ...values.textures,
      {
        name: "",
        options: [], //pieces, renamed because "pieces" keyword is restricted in yup
        size: 0,
        rotation: 0,
        file_path: null,
        normal_map: {
          type: NORMAL_MAP_OPTIONS.AUTO,
        },
      },
    ]);
  };

  const handleRemoveTexture = (index) => {
    setTextureOpenIndex(-1);
    setFieldValue(
      "textures",
      values.textures.filter((logo, logoIndex) => logoIndex !== index)
    );
  };

  const handleAddLogo = () => {
    setLogoOpenIndex(values.logos.length);
    setFieldValue("logos", [
      ...values.logos,
      {
        position: "",
        height: 0,
        width: 0,
        rotation: 0,
        texture: {
          file_path: null,
          normal_map: {
            type: NORMAL_MAP_OPTIONS.AUTO,
          },
        },
      },
    ]);
  };

  const handleRemoveLogo = (index) => {
    setLogoOpenIndex(-1);
    setFieldValue(
      "logos",
      values.logos.filter((logo, logoIndex) => logoIndex !== index)
    );
  };

  const handleDeleteColorVariant = (colorVariantId) => {
    deleteColorVariantMutate(colorVariantId, {
      onSuccess: () => {
        setSelectedColorVariantId("");
        // Check if deleted color variant aren't selected in preview
        if (
          store.getState().preview.selectedPreviewColorVariantId ===
          colorVariantId
        ) {
          store.dispatch(
            setSelectedPreviewColorVariantId(
              colorVariants.filter((c) => c.id !== colorVariantId)[0]?.id ??
                null
            )
          );
        }
      },
    });
  };

  const cancelTextureCreation = () => {
    setSelectedColorVariantId("");
    setIsCreatingColorVariant(false);
    setSelectedPieces([]);
    handleReset();
  };

  return (
    <TextureContext.Provider
      value={{
        handleSetSelectedColorVariantId,
        handleAddTexture,
        handleRemoveTexture,
        handleAddLogo,
        handleRemoveLogo,
        handleDeleteColorVariant,
        setFieldValue,
        handleSubmit,
        cancelTextureCreation,
        isCreatingColorVariant,
        setIsCreatingColorVariant,
        selectedColorVariantId,
        textureOpenIndex,
        selectedColorVariant,
        values,
        piecesOptions,
        selectedPieces,
        errors,
        logoOpenIndex,
        logoPositions,
        selectedPositions,
        isDeleteLoading,
        isCreateLoading,
        colorVariants,
      }}
    >
      {children}
    </TextureContext.Provider>
  );
};

export default TextureContextProvider;
