import { FiEdit } from "react-icons/fi";
import RoundedIconsButton from "components/buttons/RoundedIconsButton";
import Badge from "components/badge/Badge";
import { garmentStatuses } from "constants/garments";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getFormattedDateOrDash } from "utils/parse";
import { pageUrls } from "routes/paths";

const GarmentCard = ({ garment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const status = garmentStatuses.find((s) => s.key === garment.workshop_status);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 overflow-hidden">
      <div className="flex flex-col h-full">
        {/* Image */}
        <div className="relative bg-gray-200">
          <img
            className="w-auto max-h-60 mx-auto"
            src={garment.imageUrl}
            alt={garment.name}
          />
          {/* Edit button */}
          <RoundedIconsButton
            className="absolute top-0 right-0 mt-4 mr-4"
            transparent
            onClick={() =>
              navigate(
                pageUrls.garment.general.replace(
                  ":garment_id",
                  garment.garment_id
                ),
                {
                  state: {
                    garment_id: garment.garment_id,
                    name: garment.name,
                    description: garment.description,
                    brand_id: garment.brand_id,
                  },
                }
              )
            }
          >
            <span className="sr-only">Edit</span>
            <FiEdit />
          </RoundedIconsButton>

          {/* Special Offer label */}
          <div className="absolute bottom-0 right-0 mb-4 mr-4">
            <Badge colorVariant={status.color} label={status.label} small />
          </div>
        </div>
        {/* Card Content */}
        <div className="grow flex flex-col p-5">
          {/* Card body */}
          <div className="grow">
            <div className="w-full mb-2">
              <h3 className="text-lg text-gray-800 font-semibold mb-1">
                {garment.name}
              </h3>
              <div className="flex items-start justify-start overflow-hidden">
                <p
                  className={`text-sm line-clamp-3 ${
                    !garment.description && "italic text-gray-400"
                  }`}
                >
                  {garment.description || t("noDescription")}
                </p>
              </div>
            </div>
          </div>
          {/* Bottom */}
          <div className="flex flex-row justify-between items-end text-gray-400">
            {/* Dates of creation */}
            <div className="flex flex-col items-start">
              <div className="text-xs ">{t("createdAt")}</div>
              <div className="text-xs">
                {getFormattedDateOrDash(garment.createdAt)}
              </div>
            </div>
            {/* Dates of approval */}
            <div className="flex flex-col items-end">
              <div className="text-xs ">{t("approvalAt")}</div>
              <div className="text-xs">
                {getFormattedDateOrDash(garment.date_of_approval)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GarmentCard;
