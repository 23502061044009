const ContentWrapper = ({ children, fullContentHeight }) => {
  return (
    <div
      className={`relative flex flex-col px-4 pt-8 sm:px-6 lg:px-8 max-w-8xl mx-auto ${
        fullContentHeight ? "overflow-hidden flex-grow" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
