import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import PositioningCard from "components/cards/PositioningCard";

function PositioningSideBar() {
  const { t } = useTranslation();
  const { positioningFilter, sorted3dPieces } = useCurrentGarmentContext();

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-60 md:space-y-3 w-1/2">
      {/* Pieces types group */}

      <div className="flex flex-nowrap md:block mr-3 md:mr-0 space-y-3 pb-24">
        {sorted3dPieces[positioningFilter].length > 0 ? (
          sorted3dPieces[positioningFilter]
            .slice()
            .sort()
            .map((p, i) => {
              return <PositioningCard key={i} piece3d={p} />;
            })
        ) : (
          <div className="flex w-full h-full items-center justify-center">
            <p className="text-sm text-gray-500 p-10 text-center">
              {t("no3dpieces")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default PositioningSideBar;
