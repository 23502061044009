import React from "react";
import { useModal } from "context/ModalProvider.js";
import SmallButton from "components/buttons/SmallButton.js";
import BadgeIcons from "components/icons/BadgeIcons.js";
import { useTranslation } from "react-i18next";

const MODAL_TYPE_BADGEICONS = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

export const FeedbackModal = () => {
  const { modalProps, modalType, hideModal } = useModal();
  const {
    titleKey,
    messageKey,
    messageDynamicValues,
    onValidate,
    onCancel,
    isDestructive,
    isCancelDestructive,
    validateKey,
    cancelKey,
  } = modalProps;
  const { t } = useTranslation();

  const handleValidate = () => {
    onValidate && onValidate();
    hideModal();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    hideModal();
  };

  return (
    <>
      <div className="p-5 flex space-x-4 w-full">
        {/* Icon */}
        <BadgeIcons withRoundedBg type={MODAL_TYPE_BADGEICONS[modalType]} />
        {/* Content */}
        <div className="w-full">
          {/* Modal header */}
          <div className="mb-2">
            <div className="text-lg font-semibold text-gray-800">
              {t(titleKey)}
            </div>
          </div>
          {/* Modal content */}
          <div className="text-sm mb-3">
            <div className="space-y-2">
              <p>{t(messageKey, messageDynamicValues)}</p>
            </div>
          </div>
          {/* Modal footer */}
          <div className="flex flex-wrap justify-end space-x-2">
            {onCancel && (
              <SmallButton
                key={`button-cancel`}
                colorVariant={isCancelDestructive ? "red" : "white"}
                onClick={handleCancel}
                label={cancelKey ? t(cancelKey) : t("cancel")}
                size="sm"
              />
            )}
            <SmallButton
              key={`button-validate`}
              colorVariant={isDestructive && "red"}
              onClick={handleValidate}
              label={validateKey ? t(validateKey) : "OK"}
              size="sm"
            />
          </div>
        </div>
      </div>
    </>
  );
};
