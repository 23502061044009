import { useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Octahedron } from "@react-three/drei";
import { useR3fContext } from "context/R3fProvider";
import { getReadableNullpos } from "utils/garment";

const R3fNullPoses = ({
  isAvatarLoaded,
  isNullposesReady,
  setIsNullposesReady,
  setMessage,
  mode,
}) => {
  const { scene } = useThree();
  const { setNullposes, highlightedNullposName } = useR3fContext();

  const [hoveredNullpos, setHoveredNullpos] = useState(null);

  useEffect(() => {
    if (hoveredNullpos) {
      setMessage(getReadableNullpos(hoveredNullpos));
    } else {
      setMessage(null);
    }
  }, [hoveredNullpos]);

  const nullposesGroup = useRef();

  const [gltfNullposes, setGltfNullposes] = useState();

  useEffect(() => {
    if (isAvatarLoaded) {
      const avatarObject = scene.getObjectByName("avatar");
      const nullposes = [];
      if (avatarObject) {
        avatarObject.traverse((node) => {
          if (node.isObject3D && node.name.split("_")[0] === "nullpos") {
            nullposes.push(node);
          }
        });
        setGltfNullposes(nullposes);
      }
    }
  }, [isAvatarLoaded]);

  useEffect(() => {
    if (gltfNullposes) {
      const nullposes3dGroup = scene.getObjectByName("3dNullposes");

      if (
        !isNullposesReady &&
        nullposes3dGroup.children.length === gltfNullposes.length
      ) {
        setNullposes(nullposes3dGroup.children);
        setIsNullposesReady(true);
      }
    }
  }, [gltfNullposes]);

  return (
    <group
      name={"3dNullposes"}
      ref={nullposesGroup}
      visible={mode === "positioning"}
    >
      {gltfNullposes &&
        gltfNullposes.map((node) => {
          return (
            <R3fCross
              {...{ setHoveredNullpos, mode }}
              key={node.name}
              position={node.position}
              name={node.name}
              scale={highlightedNullposName === node.name ? 0.02 : 0.01}
              color={
                hoveredNullpos === node.name
                  ? "crimson"
                  : highlightedNullposName === node.name
                  ? "crimson"
                  : "darkcyan"
              }
            />
          );
        })}
    </group>
  );
};

const R3fCross = ({
  position,
  color,
  name,
  scale = 0.01,
  setHoveredNullpos,
  mode,
  ...props
}) => {
  const handleRaycast = (event) => {
    const nearestDistance = event.intersections[0].distance; // Nearest distance
    const nearestObjectsUuid = event.intersections
      .filter((obj) => obj.distance === nearestDistance)
      .map((obj) => obj.object.uuid);
    if (nearestObjectsUuid.includes(event.object.uuid)) {
      setHoveredNullpos(name);
    }
  };

  return (
    <mesh
      name={name}
      position={position}
      scale={scale}
      onPointerMove={mode === "positioning" && handleRaycast}
      onPointerLeave={mode === "positioning" && (() => setHoveredNullpos(null))}
      {...props}
    >
      <Octahedron radius={1}>
        <meshBasicMaterial color={color} />
      </Octahedron>
    </mesh>
  );
};

export default R3fNullPoses;
