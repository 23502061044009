import SpinnerAnimatedSvg from "components/SpinnerAnimatedSvg";
import colorVariantsClass from "./colorVariantsButtons";
import useNavigateTo from "./useNavigateTo";

const SIZE_CLASSNAMES = { sm: "btn-sm", xs: "btn-xs" };

const SmallButton = ({
  label,
  size = "xs",
  onClick: onClickHandler,
  to,
  replace,
  isLoading,
  colorVariant = "lightBlue",
  className,
  ...rest
}) => {
  const onClick = useNavigateTo(to, onClickHandler, replace);

  return (
    <button
      className={`${SIZE_CLASSNAMES[size]} relative min-w-[50px] ${
        colorVariantsClass[colorVariant]
      } ${className || ""}`}
      {...{ onClick }}
      {...rest}
    >
      {isLoading && (
        <div className="absolute bg-gray-200 bg-opacity-50 inset-0 flex justify-center items-center">
          <SpinnerAnimatedSvg white={colorVariant !== "white"} />
        </div>
      )}
      {label}
    </button>
  );
};

export default SmallButton;
