import { cloneDeep, isEqual, union } from "lodash";
import { DO_NOT_USE, garmentStatuses } from "constants/garments";
import { generateId } from "./parse";

export const checkValidDxfPiece = (piece) => {
  const isArrayWithAtLeastOneItem = (item) =>
    item && Array.isArray(item) && item.length > 0;
  // Check if all field are filled
  if (
    !piece.type ||
    !piece.topPosition ||
    !piece.points ||
    !isArrayWithAtLeastOneItem(piece.points) ||
    !isArrayWithAtLeastOneItem(piece.pois)
  ) {
    return false;
  }
  return true;
};

export const getChangesObject = (newValues, currentValues) => {
  if (!newValues || !currentValues) return null;

  const hasChanges = {};

  // Check if there are general page changes
  hasChanges.general = ["type", "measurements"].some((key) => {
    return !isEqual(newValues[key], currentValues[key]);
  });
  // Check if there are pieces page changes
  hasChanges.pieces = !["dxf_pieces"].every((key) =>
    isEqual(newValues[key], currentValues[key])
  );
  // Check if there are positioning page changes
  hasChanges.positioning = !["3d_pieces"].every((key) =>
    isEqual(newValues[key], currentValues[key])
  );
  // Check if there are positioning page changes
  hasChanges.stitching = !["stitches"].every((key) =>
    isEqual(newValues[key], currentValues[key])
  );
  return hasChanges;
};

export const getNewDxfPiecesWithModifiedPiece = (
  editedGarment,
  dxfPieceId,
  newValues
) => {
  const newPieces = editedGarment.dxf_pieces.map((p) =>
    p.id === dxfPieceId
      ? {
          ...p,
          ...newValues,
        }
      : p
  );
  return newPieces;
};

export const getNew3dPiecesWithModifiedPositioning = (
  editedGarment,
  piece3dId,
  newValues
) => {
  const new3dPieces = editedGarment["3d_pieces"].map((p) =>
    p.id === piece3dId
      ? {
          ...p,
          positioning: {
            ...p.positioning,
            ...newValues,
          },
        }
      : p
  );
  return new3dPieces;
};

export const getNewStitchesWithModifiedStitches = (
  editedGarment,
  stitchingId,
  newValues
) => {
  const newStitches = editedGarment.stitches.map((s) =>
    s.id === stitchingId ? { ...s, ...newValues } : s
  );
  return newStitches;
};

/**
 * Returns a new array of stitches from an edited garment, with modified POI names.
 *
 * @param {object} editedGarment - The edited garment object.
 * @param {string} poiId - The POI id to replace.
 * @param {string} newPoiName - The new POI name to use as a replacement.
 * @returns {object[]} - An array of stitches with modified POI names.
 */
export const getNewStitchesWithModifiedPoiName = (
  editedGarment,
  poiId,
  newPoiName
) => {
  const newStitches = editedGarment.stitches.map((stitch) => {
    const newStitch = cloneDeep(stitch);
    for (const piece of ["piece1", "piece2"]) {
      if (stitch[piece].poiStartId === poiId) {
        newStitch[piece].poi_start = newPoiName;
      }
      if (stitch[piece].poiEndId === poiId) {
        newStitch[piece].poi_end = newPoiName;
      }
      return newStitch;
    }
  });
  return newStitches;
};

export const getP3dAndStitchesWithModifiedTypeAndBlenderObjName = (
  editedGarment,
  dxfPieceId,
  newDxfPieceType
) => {
  let piece3dIdsToFound = [];

  let newBlenderNameIndex = 0;
  const new3dPieces = editedGarment["3d_pieces"].map((p3d) => {
    if (p3d.dxfPieceId === dxfPieceId) {
      piece3dIdsToFound = union(piece3dIdsToFound, [p3d.id]);
      newBlenderNameIndex++;
      return {
        ...p3d,
        type: newDxfPieceType,
        blender_object_name: `${newDxfPieceType}_${newBlenderNameIndex}`,
      };
    } else {
      return p3d;
    }
  });

  const newStitches = editedGarment.stitches.map((stitch) => {
    const newStitch = cloneDeep(stitch);
    ["piece1", "piece2"].forEach((stitchPiece) => {
      if (piece3dIdsToFound.includes(newStitch[stitchPiece].piece3dId)) {
        newStitch[stitchPiece].piece_type = newDxfPieceType;
        newStitch[stitchPiece].blender_object_name = new3dPieces.find(
          (p3d) => p3d.id === newStitch[stitchPiece].piece3dId
        ).blender_object_name;
      }
    });
    return newStitch;
  });

  return { "3d_pieces": new3dPieces, stitches: newStitches };
};

export const getFilteredPieces = (
  dxf_pieces,
  piecesFilter,
  getHasPieceError
) => {
  if (!dxf_pieces) {
    return [];
  }
  // Sort used dxf_pieces and unused dxf_pieces
  const sortedPieces = [
    ...dxf_pieces.filter((p) => p.type !== DO_NOT_USE),
    ...dxf_pieces.filter((p) => p.type === DO_NOT_USE),
  ];

  switch (piecesFilter) {
    case "error":
      return sortedPieces.filter((p) => getHasPieceError(p.id));
    case "valid":
      return sortedPieces.filter((p) => !getHasPieceError(p.id));
    case "uncontrolled":
      return sortedPieces.filter(
        (p) => !getHasPieceError(p.id) && !p.controlled
      );
    case "controlled":
      return sortedPieces.filter(
        (p) => !getHasPieceError(p.id) && p.controlled
      );
    default:
      return sortedPieces;
  }
};

export const sortByReviewStatuses = (garments) => {
  if (!Array.isArray(garments)) return null;
  return [
    ...garments.filter((g) => g.reviewStatus === garmentStatuses[0].key),
    ...garments.filter((g) => g.reviewStatus === garmentStatuses[1].key),
  ];
};

export const getReadableNullpos = (nullpos) => {
  if (!nullpos) return nullpos;
  return nullpos.split("_").slice(1).join(" ");
};

export const getReadableNullposes = (nullposes) => {
  return nullposes.map(getReadableNullpos);
};

export const undoReadableNullpos = (readableNullpos) => {
  return "nullpos_" + readableNullpos.toLowerCase().split(" ").join("_");
};

export const findRenamedPois = (oldPois, newPois) => {
  for (const oldPoi of oldPois) {
    const newPoi = newPois.find((p) => p.id === oldPoi.id);

    if (newPoi && oldPoi.name !== newPoi.name) {
      return { oldPoi, newPoi };
    }
  }

  return null;
};

export const countPoiUsingInStitches = (editedGarment, poiId) => {
  return editedGarment.stitches.reduce((count, stitch) => {
    if (
      stitch.piece1.poiStartId === poiId ||
      stitch.piece2.poiStartId === poiId ||
      stitch.piece1.poiEndId === poiId ||
      stitch.piece2.poiEndId === poiId
    ) {
      return count + 1;
    } else return count;
  }, 0);
};

export const generate3dPieceId = (existing3dPieces, type) => {
  const upperIndexId = existing3dPieces.reduce((acc, p3d) => {
    const match = p3d.id.match(/\d+$/);
    if (match) {
      return Math.max(acc, parseInt(match[0]));
    } else {
      throw new Error(
        ">>> generate3dPieceId : error to generate new 3d pieces uniq id. Last index not found"
      );
    }
  }, 0);

  return generateId("3d", upperIndexId + 1);
};

export const generateBlenderObjectNameWithUniqIndex = (
  type,
  pieces3dWithSameType
) => {
  const upperIndexId = (pieces3dWithSameType ?? []).reduce((acc, p3d) => {
    const match = p3d.blender_object_name.match(/\d+$/);
    if (match) {
      return Math.max(acc, parseInt(match[0]));
    } else {
      // blender_object_name without index (first generated)
      return acc;
    }
  }, 0);

  return type + "_" + (upperIndexId + 1);
};

export const createNew3dPiece = (
  selectedDxfPiece,
  editedGarment,
  pieces3dWithSameType
) => {
  const { type, id } = selectedDxfPiece;
  const new3dPiece = cloneDeep(
    pieces3dWithSameType[0] ?? {
      type,
      dxfPieceId: id,
    }
  );
  new3dPiece.logos = [];
  new3dPiece.neckline_detected = false;
  new3dPiece.id = generate3dPieceId(
    editedGarment["3d_pieces"],
    selectedDxfPiece.type
  );
  new3dPiece.blender_object_name = generateBlenderObjectNameWithUniqIndex(
    type,
    pieces3dWithSameType
  );

  new3dPiece.positioning = {
    nullpos_x: [],
    nullpos_y: [],
    nullpos_z: [],
    offset_x: 0,
    offset_y: 0,
    rotation: [0, 0, 0],
    flip_normals: false,
  };

  return new3dPiece;
};
