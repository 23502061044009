import React, { useEffect } from "react";
import { useState } from "react";

const Accordion = ({ items, openIndex }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleSetActiveIndex = (index) => {
    setActiveIndex((prevIndex) => (index === prevIndex ? -1 : index));
  };

  useEffect(() => {
    setActiveIndex(openIndex);
  }, [openIndex]);

  return (
    <div className="flex flex-col gap-4">
      {Array.isArray(items) &&
        items.map((item, index) => (
          <div
            key={`accordion-heading-${index}`}
            className={`rounded-md border ${
              item.error ? "border-red-500" : "border-gray-200"
            }`}
          >
            <h2 id={`accordion-heading-${index}`} className="flex items-center">
              <button
                type="button"
                className="w-full flex items-center justify-between px-5 py-3"
                aria-expanded="true"
                aria-controls={`accordion-body-${index}`}
                onClick={() => handleSetActiveIndex(index)}
              >
                <span className="font-medium text-gray-800">{item?.title}</span>
                <svg
                  className={`w-8 h-8 fill-current ${
                    Boolean(activeIndex === index) ? "rotate-180" : ""
                  }`}
                  viewBox="0 0 32 32"
                >
                  <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                </svg>
              </button>
              {item.rightElement && (
                <div className="pr-5">{item.rightElement}</div>
              )}
            </h2>
            <div
              id={`accordion-body-${index}`}
              className={`${Boolean(activeIndex !== index) ? "hidden" : ""}`}
              aria-labelledby={`accordion-heading-${index}`}
            >
              {item?.content}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Accordion;
