import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "./useInterval";

import { useMutation } from "@tanstack/react-query";
import { makePreviewGarmentMutationOptions } from "api/react-query/mutationFunctions";
import useGarmentPartnerAndBrandIds from "./useGarmentPartnerAndBrandIds";
import { clearInFittingProcess, setInFittingProcess } from "store";
import { useModal } from "context/ModalProvider";
import { setPreviewed } from "store";
import store from "store";
import { setVtoIdInProcess } from "store";
import { clearPreviewed } from "store";

export const useVto = ({ avatar, colorVariantId }) => {
  const { showModal } = useModal();

  const { vtoIdInProcess } = useSelector((state) => state.preview);
  const dispatch = useDispatch();
  const { garmentId, partnerId } = useGarmentPartnerAndBrandIds();

  const [pollingInterval, setPollingInterval] = useState(null);
  const [isPreviewInitializing, setIsPreviewInitializing] = useState(false);

  const patchPreviewMutation = useMutation({
    ...makePreviewGarmentMutationOptions(garmentId, partnerId),
    onSuccess: (data) => {
      switch (data.status) {
        case "IN_PROGRESS":
          dispatch(
            setInFittingProcess({
              colorVariantId,
              avatarId: avatar.id,
              vtoIdInProcess: data.vto_id,
            })
          );
          dispatch(setVtoIdInProcess(data.vto_id));
          break;
        case "SUCCEEDED":
          const { inFittingProcessColorVariantId, inFittingProcessAvatarId } =
            store.getState().preview;
          dispatch(
            setPreviewed({
              avatarId: inFittingProcessAvatarId,
              colorVariantId: inFittingProcessColorVariantId,
              vtoUrl: data.preview_url,
            })
          );
          dispatch(clearInFittingProcess());
          break;
        case "FAILED":
          store.dispatch(clearInFittingProcess());
          showModal("warning", {
            titleKey: "previewErrorVtoModalTitle",
            messageKey: "previewErrorVtoModalMsg",
            isDestructive: true,
          });
          break;
        default:
          console.error(
            ">>> patchPreviewMutation : res.vto_status not handled : ",
            data
          );
          dispatch(clearInFittingProcess());
          showModal("warning", {
            titleKey: "previewErrorVtoModalTitle",
            messageKey: "previewErrorVtoModalMsg",
            isDestructive: true,
          });
      }
    },
    onError: (error) => {
      console.error(">>> patchPreviewMutation error : ", error);
      dispatch(clearInFittingProcess());
      showModal("warning", {
        titleKey: "previewErrorVtoModalTitle",
        messageKey: "previewErrorVtoModalMsg",
        isDestructive: true,
      });
    },
  });

  useEffect(() => {
    if (vtoIdInProcess) {
      setPollingInterval(2000);
    } else {
      setPollingInterval(null);
    }
  }, [vtoIdInProcess]);

  useInterval(
    () => {
      patchPreviewMutation.mutate({ vto_id: vtoIdInProcess });
    },
    pollingInterval,
    false
  );

  const initializePreview = async () => {
    setIsPreviewInitializing(true);
    dispatch(clearPreviewed());
    await patchPreviewMutation.mutateAsync({
      avatar,
      color_variant_id: colorVariantId,
    });
    setIsPreviewInitializing(false);
  };

  return { isPreviewInitializing, initializePreview };
};
