import DropdownSelector from "components/dropdown/DropdownSelector";

const DropdownInput = ({
  options = [],
  selectedOption,
  selectedOptions = [],
  getLabelOptionFromOption = (option) =>
    typeof option === "string" ? option : "",
  getLabelInputFromOption = (option) =>
    typeof option === "string" ? option : "",
  getIsSelectedFromOption = (option) => option === selectedOption,
  onMouseOverOption = () => {},
  multiSelectedOptionsCustomStyle = { selector: () => null, className: "" },
  onChange,
  title,
  isLoading,
  customWidthClass,
  multiSelect,
  fullWidth,
  keyTextNoOptions,
  rightTitleComponent,
  disabled,
  searchText,
  error,
}) => {
  return (
    <div className={`${disabled ? "pointer-events-none" : ""}`}>
      {title && (
        <div className="flex justify-between">
          <h2
            className="block text-sm text-gray-500 font-medium mb-1"
            htmlFor="type"
          >
            {title}
          </h2>
          {rightTitleComponent && rightTitleComponent}
        </div>
      )}

      <DropdownSelector
        {...{
          options,
          selectedOption,
          selectedOptions,
          getLabelOptionFromOption,
          getLabelInputFromOption,
          getIsSelectedFromOption,
          multiSelectedOptionsCustomStyle,
          onChange,
          onMouseOverOption,
          isLoading,
          customWidthClass,
          multiSelect,
          fullWidth,
          keyTextNoOptions,
          searchText,
          error,
        }}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default DropdownInput;
