export function uploadFile(url, file, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open("PUT", url);
    xhr.setRequestHeader("X-Api-Key", process.env.REACT_APP_apikey_unauth);

    xhr.onload = function () {
      if (this.status > 299) {
        reject(new Error("uploaderErrorMessage"));
      } else {
        resolve({ status: this.status });
      }
    };

    xhr.onerror = function (e) {
      console.error(e);
      reject(new Error("uploaderErrorMessage"));
    };

    if (onProgress) {
      xhr.upload.addEventListener("progress", onProgress);
      xhr.loadend = function () {
        xhr.upload.removeEventListener("progress", onProgress);
      };
    }

    xhr.send(file);
  });
}
