import React from "react";
import GarmentPagesWrapper from "layouts/contentPage/GarmentPagesWrapper";
import GarmentPageHeader from "layouts/headers/GarmentPageHeader";
import { R3fProvider } from "context/R3fProvider";
import StitchingSideBar from "layouts/stiching/StitchingSideBar";
import StitchingView from "layouts/stiching/StitchingView";

const GarmentStitching = () => {
  return (
    <GarmentPagesWrapper fullContentHeight>
      <GarmentPageHeader type="stitching" />
      <div className="bg-white shadow-lg rounded-sm mb-8 flex flex-grow overflow-hidden">
        <div className="flex overflow-hidden flex-col w-full md:flex-row md:-mr-px">
          <R3fProvider>
            <StitchingSideBar />
            <StitchingView />
          </R3fProvider>
        </div>
      </div>
    </GarmentPagesWrapper>
  );
};

export default GarmentStitching;
