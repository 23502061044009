import React from "react";
import GarmentPagesWrapper from "layouts/contentPage/GarmentPagesWrapper";
import GarmentPageHeader from "layouts/headers/GarmentPageHeader";
import { useTranslation } from "react-i18next";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import TableInputs from "components/inputs/TableInputs";
import { useLocation } from "react-router-dom";

const INFORMATIONS_ITEMS = [
  { titleKey: "garmentNameTitle", valueKey: "name", fromLocationState: true },
  { titleKey: "garmentTypesTitle", valueKey: "type" },
  {
    titleKey: "garmentDescriptionTitle",
    valueKey: "description",
    fromLocationState: true,
  },
];

const GarmentGeneral = () => {
  const { t } = useTranslation();
  const { editedGarment, changeEditedGarment } = useCurrentGarmentContext();
  const { state } = useLocation();

  const handleOnChangeMeasurements = (key, value) => {
    const formattedValue = Math.round(value * 100) / 100;
    if (isNaN(formattedValue)) return;
    changeEditedGarment({
      ...editedGarment,
      measurements: { ...editedGarment.measurements, [key]: value?.toString() },
    });
  };

  return (
    <GarmentPagesWrapper>
      <GarmentPageHeader type="general" />
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="grow">
          {/* Panel body */}
          <div className="p-6 space-y-10">
            {/* Informations */}
            <section>
              <h2 className="text-2xl text-gray-800 font-bold mb-5">
                {t("generalPageInformationTitle")}
              </h2>
              <div
                className="grid gap-4"
                style={{ gridTemplateColumns: "min-content 1fr" }}
              >
                {INFORMATIONS_ITEMS.map((el) => (
                  <React.Fragment key={el.valueKey}>
                    <h2 className="text-sm text-gray-500 font-medium mb-1 w-24">
                      {t(el.titleKey)} :
                    </h2>
                    <p className="text-sm flex-1 text-gray-500 font-light mr-5">
                      {(el.fromLocationState
                        ? state[el.valueKey]
                        : editedGarment?.[el.valueKey]) || " "}
                    </p>
                  </React.Fragment>
                ))}
              </div>
            </section>
            {/* Measurements */}
            <section>
              <div className="mb-5 space-y-2">
                <h2 className="text-2xl text-gray-800 font-bold">
                  {t("generalPageMeasurementsTitle")}
                </h2>
                <p className="text-sm text-gray-400 font-light italic">
                  {t("measurementsAlert")}
                </p>
              </div>
              <div className="flex items-center">
                <TableInputs
                  data={editedGarment?.measurements}
                  onChange={handleOnChangeMeasurements}
                  inputsProps={{ min: 0 }}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </GarmentPagesWrapper>
  );
};

export default GarmentGeneral;
