const BodyShapeIcon = ({ type }) => {
  switch (type) {
    case "rectangle":
      return (
        <svg
          className="max-w-full max-h-full"
          width="101"
          height="300"
          viewBox="0 0 101 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M81 30.2932C81 47.0236 67.1208 60.5863 50 60.5863C32.8792 60.5863 19 47.0236 19 30.2932C19 13.5627 32.8792 0 50 0C67.1208 0 81 13.5627 81 30.2932Z"
            fill="currentColor"
          />
          <path
            d="M19 198.645C19 192.018 24.3726 186.645 31 186.645C37.6274 186.645 43 192.018 43 198.645V288C43 294.627 37.6274 300 31 300C24.3726 300 19 294.627 19 288V198.645Z"
            fill="currentColor"
          />
          <path
            d="M58 198.645C58 192.018 63.3726 186.645 70 186.645C76.6274 186.645 82 192.018 82 198.645V288C82 294.627 76.6274 300 70 300C63.3726 300 58 294.627 58 288V198.645Z"
            fill="currentColor"
          />
          <path
            d="M12 87.4039C12 76.9105 20.5066 68.4039 31 68.4039H70C80.4934 68.4039 89 76.9105 89 87.4039V187.189C89 197.682 80.4934 206.189 70 206.189H31C20.5066 206.189 12 197.682 12 187.189V87.4039Z"
            fill="currentColor"
          />
        </svg>
      );

    case "triangle":
      return (
        <svg
          className="max-w-full max-h-full"
          width="123"
          height="300"
          viewBox="0 0 123 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M93.9348 30.1948C93.9348 46.8709 80.0556 60.3896 62.9348 60.3896C45.814 60.3896 31.9348 46.8709 31.9348 30.1948C31.9348 13.5187 45.814 0 62.9348 0C80.0556 0 93.9348 13.5187 93.9348 30.1948Z"
            fill="currentColor"
          />
          <path
            d="M6.71365 188.395C14.5077 198.54 28.9824 202.597 60.7152 202.597C92.448 202.597 108.036 199.808 118.057 188.395C126.964 178.251 103.582 68.7017 60.7153 69.1558C17.8484 69.61 -1.08037 178.251 6.71365 188.395Z"
            fill="currentColor"
          />
          <path
            d="M30.9348 199.013C30.9348 192.386 36.3074 187.013 42.9348 187.013C49.5622 187.013 54.9348 192.386 54.9348 199.013V288C54.9348 294.627 49.5622 300 42.9348 300C36.3074 300 30.9348 294.627 30.9348 288V199.013Z"
            fill="currentColor"
          />
          <path
            d="M69.9348 199.013C69.9348 192.386 75.3074 187.013 81.9348 187.013C88.5622 187.013 93.9348 192.386 93.9348 199.013V288C93.9348 294.627 88.5622 300 81.9348 300C75.3074 300 69.9348 294.627 69.9348 288V199.013Z"
            fill="currentColor"
          />
        </svg>
      );

    case "inverted_triangle":
      return (
        <svg
          className="max-w-full max-h-full"
          width="117"
          height="300"
          maxWidth="100%"
          maxHeight="100%"
          viewBox="0 0 117 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M88.9999 30.1948C88.9999 46.8709 75.1208 60.3896 57.9999 60.3896C40.8791 60.3896 26.9999 46.8709 26.9999 30.1948C26.9999 13.5187 40.8791 0 57.9999 0C75.1208 0 88.9999 13.5187 88.9999 30.1948Z"
            fill="currentColor"
          />
          <path
            d="M108.687 82.7922C101.687 73.0519 88.6867 68.6688 60.1867 68.6688C31.6867 68.6688 17.6868 71.8343 8.68665 82.7922C0.686647 92.5325 21.6869 198.163 60.1867 197.727C98.6865 197.291 115.687 92.5325 108.687 82.7922Z"
            fill="currentColor"
          />
          <path
            d="M26.9999 169.792C26.9999 163.165 32.3725 157.792 38.9999 157.792C45.6274 157.792 50.9999 163.165 50.9999 169.792V288C50.9999 294.627 45.6274 300 38.9999 300C32.3725 300 26.9999 294.627 26.9999 288V169.792Z"
            fill="currentColor"
          />
          <path
            d="M65.9999 169.792C65.9999 163.165 71.3725 157.792 77.9999 157.792C84.6274 157.792 89.9999 163.165 89.9999 169.792V288C89.9999 294.627 84.6274 300 77.9999 300C71.3725 300 65.9999 294.627 65.9999 288V169.792Z"
            fill="currentColor"
          />
        </svg>
      );

    case "circle":
      return (
        <svg
          className="max-w-full max-h-full"
          width="157"
          height="300"
          viewBox="0 0 157 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M114.605 66.4495C91.1055 66.4495 97.6055 74.7557 82.6055 74.7557C67.6055 74.7557 71.6054 66.4495 50.6055 66.4495C29.6056 66.4495 2.60545 87.4593 7.60545 130.456C12.6054 173.453 28.6055 206.189 82.6055 206.678C136.605 207.166 149.105 166.612 151.605 130.456C154.477 88.9251 138.105 66.4495 114.605 66.4495Z"
            fill="currentColor"
          />
          <path
            d="M110.845 30.2932C110.845 47.0236 96.9655 60.5863 79.8447 60.5863C62.7239 60.5863 48.8447 47.0236 48.8447 30.2932C48.8447 13.5627 62.7239 0 79.8447 0C96.9655 0 110.845 13.5627 110.845 30.2932Z"
            fill="currentColor"
          />
          <path
            d="M47.8447 198.645C47.8447 192.018 53.2173 186.645 59.8447 186.645C66.4721 186.645 71.8447 192.018 71.8447 198.645V288C71.8447 294.627 66.4721 300 59.8447 300C53.2173 300 47.8447 294.627 47.8447 288V198.645Z"
            fill="currentColor"
          />
          <path
            d="M86.8447 198.645C86.8447 192.018 92.2173 186.645 98.8447 186.645C105.472 186.645 110.845 192.018 110.845 198.645V288C110.845 294.627 105.472 300 98.8447 300C92.2173 300 86.8447 294.627 86.8447 288V198.645Z"
            fill="currentColor"
          />
        </svg>
      );

    case "hourglass":
      return (
        <svg
          className="max-w-full max-h-full"
          width="125"
          height="300"
          viewBox="0 0 125 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M113.5 73.0519C110 67.6947 80 67.2078 63.5 67.2078C47 67.2078 16.6097 67.6947 13.1097 73.0519C9.60973 78.409 15.2594 97.8283 22.1097 106.169C32.1097 118.344 39.1097 121.746 39.1097 137.825C39.1097 153.409 27.7484 155.497 15.1097 171.428C6.60973 182.143 1.10973 200.162 5.60973 206.493C9.20973 211.558 45.1667 213.149 63 213.312C80.8333 213.149 117.4 211.558 121 206.493C125.5 200.162 120 182.143 111.5 171.428C98.8615 155.497 87.5001 153.409 87.5001 137.825C87.5001 121.746 94.5001 118.344 104.5 106.169C111.35 97.8283 117 78.409 113.5 73.0519Z"
            fill="currentColor"
          />
          <path
            d="M94 30.1948C94 46.8709 80.1208 60.3896 63 60.3896C45.8792 60.3896 32 46.8709 32 30.1948C32 13.5187 45.8792 0 63 0C80.1208 0 94 13.5187 94 30.1948Z"
            fill="currentColor"
          />
          <path
            d="M32 199.013C32 192.386 37.3726 187.013 44 187.013C50.6274 187.013 56 192.386 56 199.013V288C56 294.627 50.6274 300 44 300C37.3726 300 32 294.627 32 288V199.013Z"
            fill="currentColor"
          />
          <path
            d="M71 199.013C71 192.386 76.3726 187.013 83 187.013C89.6274 187.013 95 192.386 95 199.013V288C95 294.627 89.6274 300 83 300C76.3726 300 71 294.627 71 288V199.013Z"
            fill="currentColor"
          />
        </svg>
      );

    default:
      return null;
  }
};

export default BodyShapeIcon;
