const { useEffect } = require("react");

const useDisableSelection = () => {
  useEffect(() => {
    const canvas = document.querySelector("canvas");

    // Désactiver la sélection de texte
    canvas.style.userSelect = "none";
    canvas.style.webkitUserSelect = "none";
    canvas.style.msUserSelect = "none";

    canvas.addEventListener("mousedown", (event) => {
      event.preventDefault();
    });

    canvas.addEventListener("mousemove", (event) => {
      event.preventDefault();
    });

    canvas.addEventListener("mouseup", (event) => {
      event.preventDefault();
    });
  }, []);
};

export default useDisableSelection;
