import SpinnerAnimatedSvg from "components/SpinnerAnimatedSvg";
import ContentWrapper from "./ContentWrapper";
import Header from "layouts/headers/Header";
import { useTranslation } from "react-i18next";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";

const GarmentPagesWrapper = ({
  children,
  fullContentHeight,
  fullContentHeightOnlyDesktop,
}) => {
  const { t } = useTranslation();
  const { isDataReady } = useCurrentGarmentContext();

  return (
    <div
      id="scrollable-area"
      className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white-darker"
    >
      <Header type="garment" />
      <main
        className={`flex flex-grow ${
          fullContentHeight ? "overflow-hidden" : ""
        } ${fullContentHeightOnlyDesktop ? "lg:overflow-hidden" : ""}`}
      >
        {isDataReady ? (
          <ContentWrapper fullContentHeight>{children}</ContentWrapper>
        ) : (
          <div className="flex flex-col justify-center items-center w-full h-full bg-black/30">
            <SpinnerAnimatedSvg size={30} grey />
            <p className="mt-3 text-gray-200">{t("loadingGarment")}</p>
          </div>
        )}
      </main>
    </div>
  );
};

export default GarmentPagesWrapper;
