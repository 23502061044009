module.exports = {
  blueAvumi900: "#19276B",
  blueAvumi800: "#0B2984",
  blueAvumi700: "#0C2F97",
  blueAvumi400: "#0F3ABD",
  blueAvumi300: "#4E6CC6",
  blueAvumi200: "#6F87D0",
  blueAvumi100: "#C1CCEB",
  blueAvumi50: "#EEF2FF",
  whiteDarker: "#F1F5F9",
};
