import { Canvas } from "@react-three/fiber";
import { Preload } from "@react-three/drei";
import { Suspense } from "react";
import R3fAxesHelpers from "threeJs/R3fAxesHelpers";
import useDisableSelection from "./useDisableSelection";
import R3fPreviewScene from "./R3fPreviewScene";
import { R3fPreviewProvider } from "context/R3fPreviewProvider";
import R3fPreviewLights from "./R3fPreviewLights";
import R3fPreviewControls from "./R3fPreviewControls";
import R3fPreviewAvatar from "./R3fPreviewAvatar";
import { NoToneMapping } from "three";

const isDevEnv = process.env.NODE_ENV === "development";

const withHelpers = { lights: isDevEnv && false, grid: isDevEnv && false };

const R3fPreviewViewer = ({}) => {
  useDisableSelection();

  return (
    <div className="w-full h-full relative" id="canvas-wrapper">
      <R3fPreviewProvider>
        <Canvas
          shadows
          dpr={[1, 2]}
          onCreated={(state) => {
            state.gl.shadowMap.autoUpdate = false;
            state.gl.tonemapping = NoToneMapping;
          }}
        >
          <color attach="background" args={["#48494B"]} />

          <Suspense fallback={null}>
            <Preload all />
            <R3fPreviewScene />
            <R3fPreviewLights {...{ withHelpers }} />
            {withHelpers.grid && <R3fAxesHelpers />}
            <R3fPreviewAvatar />
          </Suspense>

          <R3fPreviewControls />
        </Canvas>
      </R3fPreviewProvider>
    </div>
  );
};

export default R3fPreviewViewer;
