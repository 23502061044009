const { PI } = Math;

const _getDistance = (point1, point2) => {
  let dx = point2[0] - point1[0];
  let dy = point2[1] - point1[1];
  return Math.sqrt(dx * dx + dy * dy);
};

const _getClosestPointOnLine = (point, lineStart, lineEnd) => {
  let lineLengthSquared = _getDistance(lineStart, lineEnd) ** 2;
  if (lineLengthSquared === 0) return lineStart;

  let t =
    ((point[0] - lineStart[0]) * (lineEnd[0] - lineStart[0]) +
      (point[1] - lineStart[1]) * (lineEnd[1] - lineStart[1])) /
    lineLengthSquared;

  if (t < 0) return lineStart;
  if (t > 1) return lineEnd;

  return [
    lineStart[0] + t * (lineEnd[0] - lineStart[0]),
    lineStart[1] + t * (lineEnd[1] - lineStart[1]),
  ];
};

// Variables to store the cached corners by polygonId
let cachedPolygonId;
let cachedCorners = [];

/**
 * This function finds all the corners (angles less than 130 degrees) of the polygon.
 * @param {Array} vertices An array of polygon vertices with x and y coordinates: [[x, y], [x, y], ...]
 * @returns An array of the corner vertices.
 */
const _getPolygonCorners = (vertices, polygonId) => {
  // Check if the polygonId is the same as the cached one
  if (cachedPolygonId === polygonId) {
    return cachedCorners; // Use the previously calculated corners
  }

  const corners = [];
  const numVertices = vertices.length;
  for (let i = 0; i < numVertices; i++) {
    const prevIndex = (i + vertices.length - 1) % vertices.length;
    const nextIndex = (i + 1) % vertices.length;

    let prevVertex = vertices[prevIndex];
    let currentVertex = vertices[i];
    let nextVertex = vertices[nextIndex];

    // Calculate angle between currentVertex and adjacent vertices
    const v1 = [
      currentVertex[0] - prevVertex[0],
      currentVertex[1] - prevVertex[1],
    ];
    const v2 = [
      nextVertex[0] - currentVertex[0],
      nextVertex[1] - currentVertex[1],
    ];
    const dotProduct = v1[0] * v2[0] + v1[1] * v2[1];
    const magV1 = Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1]);
    const magV2 = Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1]);
    const angle = Math.acos(dotProduct / (magV1 * magV2));
    const angleDegrees = (angle % 180) * (180 / Math.PI);

    if (angleDegrees > 30) {
      corners.push(currentVertex);
    }
  }

  // Cache the equivalent vertices and corners
  cachedPolygonId = polygonId;
  cachedCorners = corners.slice();

  return corners;
};

/**
 * Clears the cached polygonId and cachedCorners variables.
 */
export const cleanGetClosestPointOnPolygonCache = () => {
  cachedPolygonId = null;
  cachedCorners = [];
};

/**
 * This function finds the closest point on the polygon to the mouse position with magnetization towards the corners if the distances are less than the threshold defined by snapThreshold.
 * @param {Array} mouse A mouse array with coordinates: [x, y]
 * @param {Array} vertices An array of polygon vertices with x and y coordinates: [[x, y], [x, y], ...]
 * @param {String} polygonId A string representing the id of the polygon.
 * @param {number} snapThreshold The threshold for magnetization.
 * @returns An array with coordinates of the closest point on the polygon to the mouse position with magnetization towards the corners if the distances are less than the threshold defined by snapThreshold.
 */
export const getClosestPointOnPolygon = (
  mouse,
  vertices,
  polygonId,
  snapThreshold = 10
) => {
  let closestPoint = null;
  let closestDistance = Infinity;

  const corners = _getPolygonCorners(vertices, polygonId);

  // Check each corners to see if it should "attract" the point
  for (let i = 0; i < corners.length; i++) {
    let distanceToCorner = _getDistance(mouse, corners[i]);
    if (distanceToCorner < snapThreshold) {
      return corners[i]; // Return immediately if within snap threshold
    }
  }

  // If no corner attracted the point, check each edge
  for (let i = 0; i < vertices.length; i++) {
    let nextIndex = (i + 1) % vertices.length; // Loop back to 0 at the end
    let closestPointOnLine = _getClosestPointOnLine(
      mouse,
      vertices[i],
      vertices[nextIndex]
    );
    let distance = _getDistance(mouse, closestPointOnLine);
    if (distance < closestDistance) {
      closestPoint = closestPointOnLine;
      closestDistance = distance;
    }
  }

  // Now check if closestPoint on polygon is close enough to any corner to be "attracted"
  for (let i = 0; i < corners.length; i++) {
    let distanceToCorner = _getDistance(closestPoint, corners[i]);
    if (distanceToCorner < snapThreshold) {
      return corners[i]; // Return corner if within snap threshold
    }
  }

  return closestPoint;
};

/**
 * Takes an array of `nullposNames` and `nullposes` objects, returns a new array where each element is a `nullposes` object whose `name` property matches an element in `nullposNames`.
 *
 * @param {string[]} nullposNames - An array of `nullpos` names.
 * @param {Object[]} nullposes - An array of `nullpos` objects.
 * @param {string} nullposes[].name - The name of the `nullpos`.
 *
 * @returns {Object[]} An array of `nullpos` objects corresponding to the provided names. If a `nullposName` does not match any `nullpos` object, it will be `undefined`.
 */
export const getNullposesObjWithNullposNames = (nullposNames, nullposes) => {
  return nullposNames.map((nullposName) =>
    nullposes.find((p) => p.name === nullposName)
  );
};

/**
 * Retrieves a 'nullpos' object based on a specified process.
 * If the process is 'min', it retrieves the 'nullpos' with the minimum value for a specified axis.
 * If the process is 'max', it retrieves the 'nullpos' with the maximum value for the specified axis.
 * If the process is not defined or invalid, or if there's only one 'nullpos', it returns the first 'nullpos' object.
 *
 * @param {Object[]} nullposes - An array of 'nullpos' objects.
 * @param {string} blenderAxe - The axis to evaluate ('x', 'y', or 'z').
 * @param {string} [process] - The process to apply ('min' or 'max').
 *
 * @returns {Object} The 'nullpos' object that satisfies the specified process conditions.
 */
export const getNullposDependingOfProcess = (nullposes, threeAxe, process) => {
  // 🚨 The Blender Y axis is the Threejs Z axis and Y to Blender's positive values go back while the positive values of the Z axis of Threejs go forward
  const reduceNullposes = (acc, obj, minOrMax) => {
    if (minOrMax === "min") {
      return obj.position[threeAxe] < acc.position[threeAxe] ? obj : acc;
    } else if (minOrMax === "max") {
      return obj.position[threeAxe] > acc.position[threeAxe] ? obj : acc;
    }
  };

  if (nullposes.length === 1 || !process) return nullposes[0];
  if (process === "min") {
    return nullposes.reduce(
      (min, obj) => reduceNullposes(min, obj, threeAxe !== "z" ? "min" : "max"),
      nullposes[0]
    );
  } else if (process === "max") {
    return nullposes.reduce(
      (max, obj) => reduceNullposes(max, obj, threeAxe !== "z" ? "max" : "min"),
      nullposes[0]
    );
  } else {
    console.error(
      ">>> getNullposDependingOfProcess: Invalid process: " + process
    );
  }
};

export const getShapeDimensionsWithPoints = (points) => {
  const minX = Math.min(...points.map((point) => point[0]));
  const maxX = Math.max(...points.map((point) => point[0]));
  const minY = Math.min(...points.map((point) => point[1]));
  const maxY = Math.max(...points.map((point) => point[1]));
  const width = maxX - minX;
  const height = maxY - minY;
  return [width, height];
};

export const getTopPositionRadRotation2D = (topPosition) => {
  /* Rotation of the piece depending of top_position */
  switch (topPosition) {
    case "BOTTOM":
      return PI;
    case "LEFT":
      return -PI / 2;
    case "RIGHT":
      return PI / 2;
    default:
      // No rotation for TOP
      return 0;
  }
};

export const get2dRadRotatedPoints = (x, y, radRotation) => {
  return [
    x * Math.cos(radRotation) - y * Math.sin(radRotation),
    y * Math.cos(radRotation) + x * Math.sin(radRotation),
  ];
};
