import { useEffect, useState } from "react";

export const useTopPositionOnScroll = (containerSize, wrapperDIvId) => {
  const [topPosition, setTopPosition] = useState(0);

  useEffect(() => {
    const scrollableDiv = document.getElementById("scrollable-area");
    const wrapperDiv = document.getElementById(wrapperDIvId);
    const header = document.getElementById("sticky-header");

    const handleScroll = () => {
      if (containerSize > wrapperDiv.offsetHeight) return;
      const wrapperOffset = wrapperDiv.getBoundingClientRect();

      if (wrapperOffset.top - header.offsetHeight <= 0) {
        setTopPosition(
          Math.min(
            Math.abs(wrapperOffset.top - header.offsetHeight),
            wrapperOffset.height - containerSize
          )
        );
      }
    };
    scrollableDiv.addEventListener("scroll", handleScroll);
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return topPosition;
};
