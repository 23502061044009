import { createContext, useCallback, useContext, useState } from "react";

export const SideBarContext = createContext({});

export const SideBarProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((cur) => !cur);
  }, [setSidebarOpen]);

  const closeSidebar = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  return (
    <SideBarContext.Provider
      value={{ toggleSidebar, closeSidebar, sidebarOpen }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export const useSideBar = () => useContext(SideBarContext);
