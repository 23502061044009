const GenderIcon = ({ type }) => {
  switch (type) {
    case "male":
      return (
        <svg
          className="max-w-full max-h-full"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.4845 3.50964C26.4756 3.50221 26.4637 3.49627 26.4578 3.48884C26.0566 3.09366 25.501 2.93767 24.987 3.02235L19.0415 3.09961C18.1011 3.11298 17.3494 3.88401 17.3613 4.82441C17.3732 5.76184 18.0848 6.46156 19.0846 6.50316L21.0754 6.4779L18.1621 9.3912C16.4298 8.11357 14.344 7.42276 12.1498 7.42276C9.43701 7.42276 6.8862 8.47903 4.96827 10.397C1.01058 14.3561 1.01058 20.7993 4.96827 24.7584C6.8862 26.6764 9.43701 27.7327 12.1498 27.7327C14.861 27.7327 17.4133 26.6764 19.3312 24.7584C22.8373 21.2494 23.2429 15.7927 20.5361 11.8395L23.595 8.78953V11.0165C23.595 11.9569 24.3586 12.7175 25.2975 12.7175C26.2408 12.7175 27 11.9554 27 11.0165V4.71745C27 4.26582 26.8084 3.82905 26.4845 3.50964ZM16.9245 22.3517C14.3752 24.9011 9.92875 24.9011 7.37646 22.3517C4.74394 19.7207 4.74394 15.4362 7.37646 12.8037C8.6526 11.529 10.3477 10.8263 12.1512 10.8263C13.9563 10.8263 15.6484 11.529 16.9275 12.8037C19.5585 15.4347 19.5585 19.7207 16.9245 22.3517Z"
            fill="currentColor"
          />
        </svg>
      );

    case "female":
      return (
        <svg
          className="max-w-full max-h-full"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5028 1C9.26437 1 5 5.21899 5 10.4067C5 15.0028 8.35564 18.8392 12.7478 19.6552V22.4981H10.2579C9.37831 22.4981 8.66689 23.2054 8.66689 24.0751C8.66689 24.9448 9.37831 25.6521 10.2579 25.6521H12.7478V27.423C12.7478 28.2941 13.462 29 14.3416 29C15.2212 29 15.9367 28.2913 15.9367 27.423V25.6521H18.5518C19.4313 25.6521 20.1441 24.942 20.1441 24.0751C20.1441 23.2013 19.4313 22.4981 18.5518 22.4981H15.9367V19.7061C20.5096 19.0181 24 15.1101 24 10.4067C24 5.21899 19.7412 1 14.5028 1ZM14.5028 16.6581C11.0193 16.6581 8.18751 13.8524 8.18751 10.404C8.18751 6.95557 11.0193 4.14979 14.5028 4.14979C17.9835 4.14979 20.8167 6.95557 20.8167 10.404C20.8167 13.8524 17.9849 16.6581 14.5028 16.6581Z"
            fill="currentColor"
          />
        </svg>
      );

    default:
      return null;
  }
};

export default GenderIcon;
