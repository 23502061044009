import { useEffect, useMemo, useRef, useState } from "react";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import { ArrowHelper, Vector2, Vector3 } from "three";
import { Line2, LineGeometry, LineMaterial } from "three-fatline";
import { useFrame, useThree } from "@react-three/fiber";
import { Cone } from "@react-three/drei";
import { changeMeshesOpacity } from "utils/r3f";
import { useR3fContext } from "context/R3fProvider";
import { isEqual } from "lodash";

const R3fStitches = () => {
  const { filteredStitches, editedStitchId } = useCurrentGarmentContext();
  const { highlightedStitchId } = useR3fContext();

  const lastExecutionTimeRef = useRef(0);

  const [stitches, setStitches] = useState([]);

  useFrame((state, delta) => {
    const currentTime = state.clock.getElapsedTime() * 1000;

    if (currentTime - lastExecutionTimeRef.current > 300) {
      const { scene } = state;

      // Get world coordinates of each pois points from stitch.piece1 and stitch.piece2
      const stitches = filteredStitches.map((stitch) => {
        const poisPiece1 = scene.getObjectByName(
          `pois-${stitch.piece1.blender_object_name}`
        );
        const poisPiece2 = scene.getObjectByName(
          `pois-${stitch.piece2.blender_object_name}`
        );
        const pois1WorldPosition = new Vector3();
        const pois2WorldPosition = new Vector3();
        const pois3WorldPosition = new Vector3();
        const pois4WorldPosition = new Vector3();

        poisPiece1
          ?.getObjectByName(stitch.piece1.poi_start)
          ?.getWorldPosition(pois1WorldPosition);
        poisPiece2
          ?.getObjectByName(stitch.piece2.poi_start)
          ?.getWorldPosition(pois2WorldPosition);
        poisPiece1
          ?.getObjectByName(stitch.piece1.poi_end)
          ?.getWorldPosition(pois3WorldPosition);
        poisPiece2
          ?.getObjectByName(stitch.piece2.poi_end)
          ?.getWorldPosition(pois4WorldPosition);

        const points = [];
        if (stitch.piece1.poi_start) points.push(pois1WorldPosition);
        if (stitch.piece2.poi_start) points.push(pois2WorldPosition);
        if (stitch.piece1.poi_end) points.push(pois3WorldPosition);
        if (stitch.piece2.poi_end) points.push(pois4WorldPosition);

        return {
          points,
          color: stitch.color,
          id: stitch.id,
        };
      });

      setStitches((cur) => (isEqual(stitches, cur) ? cur : stitches));
      // Update the last execution time
      lastExecutionTimeRef.current = currentTime;
    }
  });

  return (
    <>
      {stitches.map((stitch, i) => {
        if (stitch.points.length === 0) return null;
        return (
          <LineComponent
            key={`stitches-${stitch.id}`}
            points={stitch.points}
            color={stitch.color}
            visible={
              !editedStitchId ||
              editedStitchId === stitch.id ||
              stitch.id === "building"
            }
            transparent={
              editedStitchId === stitch.id ||
              (highlightedStitchId && highlightedStitchId !== stitch.id)
            }
          />
        );
      })}
    </>
  );
};

export default R3fStitches;

const CONE_HEIGHT = 0.02;
const TRANSPARENT_OPACITY = 0.3;

const LineComponent = ({ points, color, visible, transparent }) => {
  const stitchGroupRef = useRef();
  const coneRef = useRef();
  const { gl } = useThree();

  const conePosition = useMemo(() => {
    if (points.length < 2) return;

    const direction = new Vector3().subVectors(
      new Vector3(...Object.values(points[points.length - 1])),
      new Vector3(...Object.values(points[points.length - 2]))
    );

    const offset = direction
      .clone()
      .normalize()
      .multiplyScalar((-CONE_HEIGHT * 0.5) / 2);
    const position = new Vector3(
      ...Object.values(points[points.length - 1])
    ).add(offset);

    return position;
  }, [points]);

  const Line = useMemo(() => {
    const geometry = new LineGeometry();
    geometry.setPositions([
      ...points.reduce((acc, point) => acc.concat(...Object.values(point)), []),
    ]); // [ x1, y1, z1,  x2, y2, z2, ... ] format

    const material = new LineMaterial({
      color,
      linewidth: 2,
      resolution: new Vector2(
        gl.domElement.clientWidth,
        gl.domElement.clientHeight
      ), // resolution of the viewport
      // dashed, dashScale, dashSize, gapSize
      transparent: transparent,
      opacity: transparent ? TRANSPARENT_OPACITY : 1,
    });

    return new Line2(geometry, material);
  }, [
    points,
    color,
    gl.domElement.clientWidth,
    gl.domElement.clientHeight,
    transparent,
  ]);

  useEffect(() => {
    if (points.length > 1) {
      const start = new Vector3(...Object.values(points[points.length - 2]));
      const end = new Vector3(...Object.values(points[points.length - 1]));
      const direction = new Vector3().subVectors(end, start).normalize();

      // Create an ArrowHelper to compute the correct orientation
      const arrowHelper = new ArrowHelper(direction, start);

      // Copy rotation to the cone
      if (coneRef.current) {
        coneRef.current.rotation.copy(arrowHelper.rotation);
      }
    }
  }, [points]);

  useEffect(() => {
    if (coneRef.current) {
      changeMeshesOpacity(coneRef.current, transparent, TRANSPARENT_OPACITY);
    }
  }, [transparent]);

  return (
    <group ref={stitchGroupRef} {...{ visible }}>
      <primitive object={Line} transparent={true} />
      {points.length > 1 && (
        <Cone ref={coneRef} args={[0.012, CONE_HEIGHT]} position={conePosition}>
          <meshBasicMaterial color={color} />
        </Cone>
      )}
    </group>
  );
};
