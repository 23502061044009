import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import reducers from "./slices";

const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: "avumi-workshop-root",
  version: 0,
  storage: storage,
  blacklist: [], // reducer which aren't to be persistent
  transforms: [
    encryptTransform({
      secretKey: "D75SSiS5sLrj8F5dt2k4",
      onError: function (error) {
        // Handle the error.
        console.error("redux-persist error", error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;

// Export actions from redux folder
export * from "./slices";
