import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { garmentStatuses } from "constants/garments";
import { useQuery } from "@tanstack/react-query";
import {
  makeGarmentsQueryOptions,
  makeSearchQueryOptions,
} from "api/react-query/queryFunctions";
import { useSelector } from "react-redux";

const GarmentsContext = createContext({});

const COUNT = 12;

export const GarmentsProvider = ({ children }) => {
  const { partners } = useSelector((state) => state.referential);

  const [page, setPage] = useState(0);

  const [selectedPartner, setSelectedPartner] = useState(partners[0]);

  const [filters, setFilters] = useState({
    reviewStatuses: garmentStatuses.map((g) => g.key),
    brand: null,
  });

  const searchResults = useSearchGarment(selectedPartner?.id);

  useEffect(() => {
    if (partners.length > 0 && !selectedPartner) {
      setSelectedPartner(partners[0]);
    }
  }, [partners, selectedPartner]);

  const { isFetching, isError, data, refetch } = useQuery(
    makeGarmentsQueryOptions({
      page,
      count: COUNT,
      partnerId: selectedPartner?.id,
      brandId: filters.brand?.id,
      reviewStatuses: filters.reviewStatuses,
    })
  );

  const changeFilters = useCallback((filterType, value) => {
    setFilters((cur) => ({ ...cur, [filterType]: value }));
    setPage(0);
  }, []);

  const changePage = useCallback((page) => {
    setPage(page);
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, []);

  return (
    <GarmentsContext.Provider
      value={{
        garments: data?.items || [],
        isFetching,
        isError,
        total: data?.page_data?.total || 0,
        page,
        changePage,
        count: COUNT,
        refetch,
        filters,
        changeFilters,
        selectedPartner,
        setSelectedPartner,
        ...searchResults,
      }}
    >
      {children}
    </GarmentsContext.Provider>
  );
};

export const useGarments = () => useContext(GarmentsContext);

const useSearchGarment = (partnerId) => {
  const [query, setQuery] = useState("");

  const { data, isFetching } = useQuery(
    makeSearchQueryOptions({
      query,
      partnerId,
    })
  );

  return {
    query,
    setQuery,
    searchLoading: isFetching || !partnerId,
    searchResults: data || [],
  };
};
