import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import StitchCard from "components/cards/StitchCard";
import { FiPlus } from "react-icons/fi";
import LinkTextButton from "components/buttons/LinkTextButton";

function StitchingSideBar() {
  const { t } = useTranslation();
  const {
    stitchingFilters,
    filteredStitches,
    handleCreateStitch,
    getStitchError,
    sorted3dPieces,
  } = useCurrentGarmentContext();

  const isNoOneStitchForOnePiece = stitchingFilters.some((bObjName) =>
    Boolean(
      getStitchError(
        undefined,
        sorted3dPieces.positioned.find(
          (p) => p.blender_object_name === bObjName
        )?.id,
        "atLeastOnStitch"
      )
    )
  );

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-[400px] md:space-y-3 w-1/3 items-center">
      {/* Pieces types group */}

      <div className="flex flex-nowrap md:block mr-3 md:mr-0 space-y-3 pb-24">
        {isNoOneStitchForOnePiece && (
          <p
            className={`text-sm ${
              isNoOneStitchForOnePiece ? "text-red-500" : "text-gray-500"
            } px-10 ${
              filteredStitches.length > 0 ? "pb-2" : "py-5"
            } text-center`}
          >
            {t("no3dStichingForOnePiece")}
          </p>
        )}
        {filteredStitches.length > 0 ? (
          filteredStitches.map((s, i) => {
            return <StitchCard key={i} stitch={s} />;
          })
        ) : (
          <div className="flex w-full h-full items-center justify-center">
            <p
              className={`text-sm text-gray-500 px-10 ${
                isNoOneStitchForOnePiece ? "pt-2" : "pt-5"
              } pb-5  text-center`}
            >
              {stitchingFilters.length === 0
                ? t("onePieceFilterRequired")
                : t("no3dStiching")}
            </p>
          </div>
        )}
        {stitchingFilters.length > 0 && (
          <LinkTextButton
            label={t("addStitch")}
            Icon={FiPlus}
            onClick={handleCreateStitch}
          />
        )}
      </div>
    </div>
  );
}

export default StitchingSideBar;
