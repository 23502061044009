import React, { useEffect, useState } from "react";
import Header from "layouts/headers/Header";
import ContentWrapper from "layouts/contentPage/ContentWrapper";
import HomePageHeader from "layouts/headers/HomePageHeader";
import { useTranslation } from "react-i18next";
import GarmentsList from "layouts/home/GarmentsList";
import { queryClient } from "api/react-query";
import { makePartnersQueryOptions } from "api/react-query/queryFunctions";
import SpinnerAnimatedSvg from "components/SpinnerAnimatedSvg";
import { useDispatch } from "react-redux";
import { setPartners } from "store";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    handleFetchPartners();
  }, []);

  const handleFetchPartners = async () => {
    try {
      const res = await queryClient.fetchQuery(makePartnersQueryOptions());
      dispatch(setPartners(res?.data?.items || []));
      setInitialized(true);
    } catch {
      setInitialized(false);
      dispatch(setPartners([]));
    }
  };

  return (
    <>
      <Header />
      <main>
        <ContentWrapper>
          {initialized ? (
            <>
              <HomePageHeader title={t("homeTitle")} />
              <GarmentsList />
            </>
          ) : (
            <div className="fixed inset-0 w-full h-full flex items-center justify-center flex-col gap-3">
              <SpinnerAnimatedSvg size={40} />
              <p className="text-center text-gray-300 text-lg">
                {t("loading")}
              </p>
            </div>
          )}
        </ContentWrapper>
      </main>
    </>
  );
};

export default Home;
