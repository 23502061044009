import React from "react";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import "./css/style.scss";
import "./lang";
import { AuthProvider } from "./context/AuthProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import dayjs from "dayjs";
import MyRouter from "./routes/MyRouter";
import { GarmentsProvider } from "./context/GarmentsProvider";
import { queryClient } from "./api/react-query";
import ModalProvider from "./context/ModalProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "api";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const isDevelopment = process.env.NODE_ENV === "development";

if (!isDevelopment) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_dsn,
    integrations: [
      new Sentry.BrowserTracing(),
      // new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_SENTRY_environment,
  });
}

const contextClass = {
  success: "bg-green-500",
  error: "bg-red-500",
  info: "bg-blue-600",
  warning: "bg-orange-500",
  default: "bg-black",
  dark: "bg-white-600 font-gray-300",
};

function App() {
  // useEffect(() => {
  //   document.querySelector("html").style.scrollBehavior = "auto";
  //   window.scroll({ top: 0 });
  //   document.querySelector("html").style.scrollBehavior = "";
  // }, [location.pathname]); // triggered on route change

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ModalProvider>
              <GarmentsProvider>
                <MyRouter />
                {/* Toast container */}
                <ToastContainer
                  toastClassName={({ type }) =>
                    contextClass[type || "default"] +
                    " relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-3 text-center"
                  }
                  position="top-center"
                  hideProgressBar={true}
                  theme="dark"
                  closeButton={() => null}
                />
              </GarmentsProvider>
            </ModalProvider>
          </AuthProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            toggleButtonProps={{ style: { width: 20, height: 20 } }}
          />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
