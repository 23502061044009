import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfigFile from "css/tailwind.config.js";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const isArrayWithAtLeastOneItem = (array) =>
  array && Array.isArray(array) && array.length > 0;

export const getFileExt = (type) => {
  if (type === "image/jpeg") return ".jpg";
  if (type === "image/png") return ".png";
  return "";
};

export const unflattenObject = (flatObject) => {
  const result = {};

  for (const key in flatObject) {
    const parts = key.split(".");
    let current = result;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (part.endsWith("]")) {
        const arrayKey = part.slice(0, part.indexOf("["));
        const arrayIndex = parseInt(part.match(/\d+/)[0], 10);

        if (!current[arrayKey]) {
          current[arrayKey] = [];
        }
        current = current[arrayKey];

        while (current.length <= arrayIndex) {
          current.push({});
        }
        current = current[arrayIndex];
      } else {
        if (!current[part]) {
          if (i === parts.length - 1) {
            current[part] = flatObject[key];
          } else {
            current[part] = {};
          }
        }
        current = current[part];
      }
    }
  }

  return result;
};
