import { useTranslation } from "react-i18next";
import IconsButton from "components/buttons/IconsButton";
import { BiTargetLock } from "react-icons/bi";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";

const PoiCoordinates = ({ point, poiId }) => {
  const { t } = useTranslation();
  const { switchToEditPoiCoordMode, editedCoordPoiId } =
    useCurrentGarmentContext();

  return (
    <div>
      <label className="block text-sm font-medium mb-1 ">
        {t("poiCoordinates")}
      </label>
      <div className="flex gap-3">
        {point.map((value, i) => (
          <CoordinatePoint
            key={`coordinate-${i}`}
            axe={i === 0 ? "x" : "y"}
            value={value?.toFixed(2) || "-"}
          />
        ))}

        <IconsButton
          className={`${editedCoordPoiId ? "opacity-0" : ""}`}
          colorVariant={"vLightBlue"}
          onClick={() => {
            switchToEditPoiCoordMode(poiId);
          }}
        >
          <BiTargetLock className="w-5 h-5" />
        </IconsButton>
      </div>
    </div>
  );
};

export default PoiCoordinates;

const CoordinatePoint = ({ value, axe }) => {
  return (
    <div
      className={`text-sm text-gray-800 leading-5 py-2 px-3 border-gray-200 bg-white border relative inline-flex pl-8 rounded`}
    >
      <div className="absolute inset-y-0 left-0 w-6 flex items-center justify-center px-2 text-sm font-medium bg-gray-100">
        {axe}
      </div>
      <span className="w-20 text-center">{value}</span>
    </div>
  );
};
