import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsImage } from "react-icons/bs";
import { toast } from "react-toastify";
import { uploadFile } from "utils/uploadFile";
import { getFileExt } from "utils/utils";
import { v4 as uuidv4 } from "uuid";

const FileUploader = ({ garmentType, value, onChange, error }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);

  const handleOnUpload = async (file) => {
    if (!garmentType) return;

    try {
      const fileName = `${uuidv4()}${getFileExt(file.type)}`;

      const response = await uploadFile(
        `${process.env.REACT_APP_FILE_UPLOADER_url}/${garmentType}/${fileName}`,
        file,
        (e) => {
          const percentCompleted = parseInt((e.loaded / e.total) * 100, 10);
          setUploadingProgress(percentCompleted);
        }
      );

      setUploadingProgress(0);

      if (response.status) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onChange({
            fileName,
            previewUrl: reader.result,
          });
        };
        reader.onerror = () => {
          throw new Error("error");
        };
      } else {
        throw new Error("error");
      }
    } catch (error) {
      toast(t("fileUploadError"), {
        type: "error",
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        style: {
          fontSize: "14px",
        },
      });
      console.error(">>> FileUploader > handleOnUpload : ", error);
      setUploadingProgress(0);
      inputRef.current.value = null;
    }
  };

  const handleOnChange = (e) => {
    setIsDragOver(false);
    const file = e?.target.files?.[0];

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      if (file.size < 9000000) {
        handleOnUpload(file);
      } else {
        toast(t("fileSizeError"), {
          type: "error",
          theme: "colored",
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          style: {
            fontSize: "14px",
          },
        });
        inputRef.current.value = null;
      }
    } else {
      toast(t("fileFormatError"), {
        type: "error",
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        style: {
          fontSize: "14px",
        },
      });
      inputRef.current.value = null;
    }
  };

  return (
    <div>
      <div
        className={`relative w-full h-0 pt-[100%] rounded-md overflow-hidden${
          isDragOver ? " bg-blue-avumi-100" : " bg-gray-200"
        }${Boolean(error) ? " border border-red-300" : ""}`}
      >
        {value?.previewUrl ? (
          <img
            className="absolute inset-0 pointer-events-none w-full h-full object-cover z-10"
            src={value.previewUrl}
            alt="preview"
          />
        ) : (
          <>
            {Boolean(uploadingProgress === 0) && (
              <div className="absolute inset-0 flex items-center justify-center text-sm pointer-events-none z-1">
                <BsImage className="text-3xl text-gray-600" />
              </div>
            )}
          </>
        )}
        {Boolean(uploadingProgress > 0) && (
          <div className="absolute inset-0 flex items-center justify-center text-sm pointer-events-none bg-[rgba(255,255,255,0.7)] z-40">
            {uploadingProgress}%
          </div>
        )}
        <input
          ref={inputRef}
          className={`absolute inset-0 opacity-0 cursor-pointer z-40`}
          type="file"
          onChange={handleOnChange}
          onDragEnter={() => {
            setIsDragOver(true);
          }}
          onDragLeave={() => {
            setIsDragOver(false);
          }}
          accept="image/jpg, image/png"
        />
      </div>
      <p className="text-xs text-center mt-1 px-2">({t("dropFileText")})</p>
      {error && (
        <p className="mt-2 text-sm text-center text-red-500">{error}</p>
      )}
    </div>
  );
};

export default FileUploader;
