import { useQuery } from "@tanstack/react-query";
import { IS_CREATING_ID } from "api/react-query/mutationFunctions";
import { makeStandardAvatarsQueryOptions } from "api/react-query/queryFunctions";
import { avatarsFilterKeys, avatarsFilterOptions } from "constants/garments";
import { useModal } from "context/ModalProvider";
import { useVto } from "hooks/useVto";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedPreview } from "store";
import { clearInFittingProcess } from "store";
import { clearPreviewed } from "store";
import { setSelectedPreviewColorVariantId } from "store";
import { setSelectedPreviewedAvatarId } from "store";

const useGarmentPreview = (lastSavedGarment, hasChanges) => {
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const { selectedPreviewedAvatarId, selectedPreviewColorVariantId } =
    useSelector((state) => state.preview);

  const [previewGender, setPreviewGender] = useState("all_gender");
  const [previewSize, setPreviewSize] = useState("all_size");
  const [previewBodyShape, setPreviewBodyShape] = useState("all_body_shape");

  const {
    isLoading,
    isError,
    data: avatars,
  } = useQuery(makeStandardAvatarsQueryOptions());

  const { isPreviewInitializing, initializePreview } = useVto({
    avatar: avatars?.find((a) => a.id === selectedPreviewedAvatarId),
    colorVariantId: selectedPreviewColorVariantId,
  });

  useEffect(() => {
    return () => {
      dispatch(clearInFittingProcess());
      dispatch(clearPreviewed());
      dispatch(clearSelectedPreview());
    };
  }, []);

  useEffect(() => {
    // Initialize color variant
    if (lastSavedGarment && !selectedPreviewColorVariantId) {
      dispatch(
        setSelectedPreviewColorVariantId(
          lastSavedGarment.color_variants.filter(
            (c) => c.id !== IS_CREATING_ID
          )[0]?.id ?? null
        )
      );
    }
  }, [lastSavedGarment, selectedPreviewColorVariantId]);

  useEffect(() => {
    // Initialize avatar
    if (avatars && !selectedPreviewedAvatarId) {
      dispatch(setSelectedPreviewedAvatarId(avatars[0].id));
    }
  }, [selectedPreviewedAvatarId, avatars]);

  /* ******* METHODS ******* */
  const changePreviewGender = useCallback((option) => {
    setPreviewGender(option);
  }, []);

  const changePreviewSize = useCallback((option) => {
    setPreviewSize(option);
  }, []);

  const changePreviewBodyShape = useCallback((option) => {
    setPreviewBodyShape(option);
  }, []);

  const changePreviewSelectedAvatarId = useCallback(
    (id) => {
      dispatch(setSelectedPreviewedAvatarId(id));
    },
    [dispatch]
  );

  const changePreviewSelectedColorVariant = useCallback(
    (colorVariantId) => {
      dispatch(setSelectedPreviewColorVariantId(colorVariantId));
    },
    [dispatch]
  );

  const handlePreview = useCallback(() => {
    // Check if color_variants and avatar are selected
    if (!(selectedPreviewColorVariantId && selectedPreviewedAvatarId)) {
      showModal("warning", {
        titleKey: "previewNotPossibleModalTitle",
        messageKey: "previewNotPossibleModalMsg",
      });
      return;
    } else {
      // Check if there isn't unsaved changes
      if (Object.keys(hasChanges).some((key) => hasChanges[key])) {
        showModal("warning", {
          titleKey: "previewWithUnsavedModalTitle",
          messageKey: "previewWithUnsavedModalMsg",
          onValidate: initializePreview,
          validateKey: "preview",
          onCancel: () => {},
          isDestructive: true,
        });
      } else {
        initializePreview();
      }
    }
  }, [
    selectedPreviewColorVariantId,
    selectedPreviewedAvatarId,
    showModal,
    initializePreview,
    hasChanges,
  ]);

  return {
    isAvatarsLoading: isLoading,
    isAvatarsError: isError,
    avatars,
    filteredAvatars:
      avatars?.filter((item) => {
        return avatarsFilterKeys.every((key) => {
          if (
            key === "gender" &&
            previewGender !== avatarsFilterOptions.gender[0]
          )
            return previewGender === item[key];
          if (key === "size" && previewSize !== avatarsFilterOptions.size[0])
            return item[key].includes(previewSize.replace("size_", ""));
          if (
            key === "body_shape" &&
            previewBodyShape !== avatarsFilterOptions.body_shape[0]
          )
            return previewBodyShape === item[key];

          return true;
        });
      }) ?? [],
    previewGender,
    changePreviewGender,
    previewSize,
    changePreviewSize,
    previewBodyShape,
    changePreviewBodyShape,
    selectedPreviewedAvatarId,
    changePreviewSelectedAvatarId,
    changePreviewSelectedColorVariant,
    selectedPreviewColorVariantId,
    handlePreview,
    isPreviewInitializing,
  };
};

export default useGarmentPreview;
