import { useThree } from "@react-three/fiber";
import { useCallback, useEffect } from "react";
import { Box3, Vector3 } from "three";

const useCenterView = (object3dToCenter, enabledCentering) => {
  const { camera, gl, controls } = useThree();

  const handleCenterView = useCallback(() => {
    if (!enabledCentering) {
      return;
    }

    const box = new Box3().setFromObject(object3dToCenter);
    const center = box.getCenter(new Vector3());
    const size = box.getSize(new Vector3());
    const aspectRatio = gl.domElement.clientWidth / gl.domElement.clientHeight;

    camera.aspect = aspectRatio;

    camera.updateProjectionMatrix(); // Update the camera with the new aspect ratio

    // Set camera
    const fovInRadians = (camera.fov * Math.PI) / 180;
    const halfWidth = size.x / 2;
    const halfHeight = size.y / 2;

    // Calculate the distance for the width and height
    const distanceForWidth =
      halfWidth / Math.tan((fovInRadians * aspectRatio) / 2);
    const distanceForHeight = halfHeight / Math.tan(fovInRadians / 2);

    // Take the maximum of the distances to make sure both dimensions fit the canvas
    const distance = Math.max(distanceForWidth, distanceForHeight);
    if (size && distance) {
      // Set camera
      camera.position.set(0.8, center.y + 0.3, distance * 1.1); // Adjust this line to control the initial camera position
      camera.lookAt(center.x, center.y, center.z); // Make the camera look at the center of the gltf

      // Set controls
      controls.target.set(center.x, center.y, center.z);
      controls.update();
      camera.updateProjectionMatrix();
    }
  }, [
    camera,
    controls,
    gl.domElement.clientHeight,
    gl.domElement.clientWidth,
    enabledCentering,
  ]);

  useEffect(() => {
    if (object3dToCenter && controls?.target) {
      handleCenterView(object3dToCenter);
    }

    const resizeObserver = new ResizeObserver(handleCenterView);
    resizeObserver.observe(gl.domElement);

    return () => resizeObserver.disconnect();
  }, [
    object3dToCenter,
    controls,
    handleCenterView,
    gl.domElement,
    enabledCentering,
  ]);

  return { handleCenterView };
};

export default useCenterView;
