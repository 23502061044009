import { origins } from "constants/garments";

const _setDxfErrors = (errors, fieldKey, errorSuffixKey, dxfPieceId, poiId) => {
  errors.push({
    fieldKey,
    errorKeyText: `dxfPiece_${errorSuffixKey}`,
    dxfPieceId,
    poiId,
  });
};

export const getDxfPiecesErrors = (dxfPieces) => {
  const errors = [];

  dxfPieces.forEach((dxfPiece) => {
    const { id } = dxfPiece;
    // Check if type aren't unused (null)
    if (dxfPiece.type !== null) {
      // If type isn't null (unused),...
      // ... check if type ...
      if (!dxfPiece.type) {
        // ... is not filled
        _setDxfErrors(errors, "type", "typeNotFilled", id);
      } else if (dxfPieces.filter((p) => dxfPiece.type === p.type).length > 1) {
        // ... is not duplicated
        _setDxfErrors(errors, "type", "duplicatedTypes", id);
      }

      // ... check if topPosition are filled
      if (!dxfPiece.top_position) {
        _setDxfErrors(errors, "top_position", "topPositionNotFilled", id);
      }
      // ... check if quantity are filled and higher than 0
      if (dxfPiece.quantity < 1) {
        _setDxfErrors(errors, "quantity", "incorrectQuantity", id);
      }

      // ... check if there are at least 2 pois
      if (dxfPiece.pois.length < 2) {
        _setDxfErrors(errors, "pois", "poisMin", id);
      }

      // ... check POIs
      dxfPiece.pois.forEach((poi) => {
        // check if name aren't duplicated
        if (dxfPiece.pois.filter(({ name }) => poi.name === name).length > 1) {
          _setDxfErrors(errors, "name", "poiDuplicatedNames", id, poi.id);
        }
      });
    }
  });
  return errors;
};

const _set3dErrors = (errors, fieldKey, errorSuffixKey, piece3dId) => {
  errors.push({
    fieldKey,
    errorKeyText: `piece3d_${errorSuffixKey}`,
    piece3dId,
  });
};

export const get3dPiecesErrors = (pieces3d) => {
  const errors = [];

  pieces3d.forEach((piece3d) => {
    const { id } = piece3d;

    // Check if at least 1 nullpos and check if process is good enum if there are more than 2 nullpos
    ["nullpos_x", "nullpos_y", "nullpos_z"].forEach((key, i) => {
      const processKey = `process_${["x", "y", "z"][i]}`;
      if (piece3d.positioning[key].length < 1) {
        _set3dErrors(errors, key, "nullposRequired", id);
      } else if (
        piece3d.positioning[key].length > 1 &&
        !["min", "max"].includes(piece3d.positioning[processKey])
      ) {
        _set3dErrors(errors, processKey, "processRequired", id);
      }
    });

    // Check if offset is number or nullable
    ["offset_x", "offset_y", "offset_z"].forEach((key) => {
      if (
        piece3d.positioning[key] !== null &&
        piece3d.positioning[key] !== undefined &&
        isNaN(piece3d.positioning[key])
      ) {
        _set3dErrors(errors, key, "offsetBadValue", id);
      }
    });

    // Check if rotation is number or nullable
    piece3d.positioning.rotation.forEach((rotation, i) => {
      if (isNaN(rotation)) {
        _set3dErrors(
          errors,
          `rotation_${["x", "y", "z"][i]}`,
          "rotationBadValue",
          id
        );
      }
    });

    // Check if origin is good enum
    ["replace_origin_x", "replace_origin_y", "replace_origin_z"].forEach(
      (key) => {
        if (
          piece3d.positioning[key] !== null &&
          piece3d.positioning[key] !== undefined &&
          !origins.includes(piece3d.positioning[key])
        ) {
          _set3dErrors(errors, key, "originBadValue", id);
        }
      }
    );
  });

  return errors;
};

const _setStitchErrors = (
  errors,
  fieldKey,
  errorSuffixKey,
  stitchId,
  piece3dId
) => {
  errors.push({
    fieldKey,
    errorKeyText: `stitch_${errorSuffixKey}`,
    stitchId,
    piece3dId,
  });
};

export const getStitchesErrors = (stitches, pieces3d) => {
  const errors = [];

  // ❗️ only check on all 3d_piece mapping (no stitches mapping)
  pieces3d.forEach((piece3d) => {
    // Check if there is at least one stitch by 3dPiece()
    if (
      !stitches.some(
        (stitch) =>
          stitch.piece1.piece3dId === piece3d.id ||
          stitch.piece2.piece3dId === piece3d.id
      )
    ) {
      _setStitchErrors(
        errors,
        "atLeastOnStitch",
        "atLeastOnStitch",
        undefined,
        piece3d.id
      );
    }
  });

  return errors;
};

const _setTextureErrors = (
  errors,
  fieldKey,
  errorSuffixKey,
  colorVariantId
) => {
  errors.push({
    fieldKey,
    errorKeyText: `texture_${errorSuffixKey}`,
    colorVariantId,
  });
};

export const getTexturesErrors = (colorVariants, pieces3d) => {
  const errors = [];

  colorVariants.forEach((colorVariant) => {
    // Check if each color variant are at least on texture by 3d_pieces (in textureItem.pieces key : eg: ".*" is for each pieces)
    const allBlenderName3dPieces = pieces3d.map(
      (piece3d) => piece3d.blender_object_name
    );
    // aucune texture comprend .* ET toute les allBlenderName3dPieces ne sont pas référencées dans au moins un array textures.pieces
    const isStarInAtLeastOneTexturePieces = colorVariant.textures.some((t) =>
      t.pieces.includes(".*")
    );
    const isAllPiecesInTextures = allBlenderName3dPieces.every((bObjName) =>
      colorVariant.textures.some((t) => t.pieces.includes(bObjName))
    );

    if (!isStarInAtLeastOneTexturePieces && !isAllPiecesInTextures) {
      _setTextureErrors(
        errors,
        "allPiecesCovered",
        "textureDontCoverAllPieces",
        colorVariant.id
      );
    }
  });
  return errors;
};

export const getGarmentErrors = (editedGarment) => {
  const errors = {};
  if (typeof editedGarment !== "object" || editedGarment === null) {
    return errors;
  }

  // General page errors
  /*  Nothing to check */

  // Pieces page errors (dxf_pieces)
  errors.pieces = getDxfPiecesErrors(editedGarment.dxf_pieces);

  // Positioning page errors (3d_pieces)
  errors.positioning = get3dPiecesErrors(editedGarment["3d_pieces"]);

  // Stitching page errors (3d_pieces)
  errors.stitching = getStitchesErrors(
    editedGarment["stitches"],
    editedGarment["3d_pieces"]
  );

  // Texture page errors (3d_pieces)
  errors.textures = getTexturesErrors(
    editedGarment["color_variants"],
    editedGarment["3d_pieces"]
  );

  return errors;
};
