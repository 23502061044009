export const changeMeshesOpacity = (object, condition, opacity = 0.2) => {
  object.traverse((node) => {
    if (node.isMesh) {
      node.material.transparent = true;
      if (condition) {
        node.material.opacity = opacity;
      } else {
        node.material.opacity = 1;
      }
    }
  });
};

export const setVisibility = (object, visible) => {
  object.visible = visible;
  object.children.forEach((child) => setVisibility(child, visible));
};

/**
 * PREVIEW UTILS
 */
const DEFAULT_CEIL_Y = 2.2;

export const getYPositionOfCeil = (sceneObj) => {
  const ceil = sceneObj.getObjectByName("ceil");
  if (ceil?.position?.y) {
    return ceil.position.y - 0.2; // 0.2 is offset security to not enter inside ceil with camera
  } else {
    return DEFAULT_CEIL_Y;
  }
};

export const getBodyGender = (avatarObject) => {
  const isMaleAvatar = !!avatarObject.getObjectByName("avatar_male_body");
  const isFemaleAvatar = !!avatarObject.getObjectByName("avatar_female_body");
  if (isMaleAvatar) return "male";
  if (isFemaleAvatar) return "female";
};
