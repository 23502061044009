import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Line, useHelper } from "@react-three/drei";
import {
  BackSide,
  Box3,
  BoxHelper,
  DoubleSide,
  FrontSide,
  Shape,
  Vector3,
} from "three";
import {
  get2dRadRotatedPoints,
  getNullposDependingOfProcess,
  getNullposesObjWithNullposNames,
  getShapeDimensionsWithPoints,
  getTopPositionRadRotation2D,
} from "utils/geometry";
import { useR3fContext } from "context/R3fProvider";
import { changeMeshesOpacity } from "utils/r3f";
import useCenterView from "./useCenterView";

const HELPERS = { boxPiece: true };
const { PI } = Math;

const INITIAL_BLENDER_PIECE_ROTATION = [PI / 2, 0, PI / 2]; // [x,y,z] Three axes
const INDEX_AXIS_CONV = [0, 2, 1]; // 🚨 Blender z is ThreeJs y and rotation array is [x,z,y] for three js
const ROTATION_DIRECTION = [1, 1, -1]; // counter-clockwise when the axis points towards us

const R3fPieces = ({ hidden, mode }) => {
  const piecesGroupRef = useRef();
  const { nullposes } = useR3fContext();
  const { sorted3dPieces, edited3dPieces } = useCurrentGarmentContext();

  const positionedValidPieces = sorted3dPieces?.positioned || [];

  useCenterView(
    piecesGroupRef.current,
    mode === "stitching" && piecesGroupRef.current
  );

  return (
    <group
      ref={piecesGroupRef}
      name="pieces" // DON'T CHANGE THIS NAME or change it in raycasting of pois
      position={[0, 0, 0]}
    >
      {positionedValidPieces &&
        nullposes &&
        positionedValidPieces.map((piece_3d) => {
          if (
            edited3dPieces?.length > 0 &&
            !edited3dPieces.includes(piece_3d.blender_object_name)
          ) {
            return null;
          } else {
            return (
              <Piece
                {...{ piece_3d, mode }}
                hidden={hidden}
                key={piece_3d.id}
              />
            );
          }
        })}
    </group>
  );
};

export default R3fPieces;

const Piece = ({
  hidden,
  mode,
  piece_3d: { blender_object_name: name, positioning, id, type: pieceType },
}) => {
  const {
    dxf_pieces: { all: dxf_pieces },
  } = useCurrentGarmentContext();

  const {
    nullposes,
    highlightedPiece3dId,
    highlightedNullposName,
    setNullposesPositionings,
    setOffsetCentering,
  } = useR3fContext();

  useEffect(() => {
    return () => {
      setNullposesPositionings((cur) => {
        delete cur[id];
        return cur;
      });
    };
  }, []);

  const dxf_piece = useMemo(() => {
    return dxf_pieces.find((p) => p.type === pieceType);
  }, [dxf_pieces, pieceType]);

  const topPositionRadRotation2D = useMemo(() => {
    return getTopPositionRadRotation2D(dxf_piece?.top_position);
  }, [dxf_piece?.top_position]);

  const { scaledTopPositionedPoints, scaledTopPositionedDimensions } =
    useMemo(() => {
      // Calculate initial width and height of piece
      const [initialWidth, initialHeight] = getShapeDimensionsWithPoints(
        dxf_piece.points
      );
      const centerOffsetX =
        Math.min(...dxf_piece.points.map((point) => point[0])) +
        initialWidth / 2;
      const centerOffsetY =
        Math.min(...dxf_piece.points.map((point) => point[1])) +
        initialHeight / 2;

      setOffsetCentering((cur) => ({
        ...cur,
        [name]: { x: centerOffsetX, y: centerOffsetY },
      }));

      // Center points around 0,0 coordinates and rotate it depending of top_position
      const centeredPointsWithTopPositionRotation = dxf_piece.points.map(
        ([x, y]) => {
          const dx = x - centerOffsetX;
          const dy = y - centerOffsetY;

          return get2dRadRotatedPoints(dx, dy, topPositionRadRotation2D);
        }
      );

      const finalScaledPoints = centeredPointsWithTopPositionRotation.map(
        ([x, y]) => [x / 1000, y / 1000]
      );

      const [finalWidth, finalHeight] =
        getShapeDimensionsWithPoints(finalScaledPoints);

      return {
        scaledTopPositionedPoints: finalScaledPoints,
        scaledTopPositionedDimensions: {
          width: finalWidth,
          height: finalHeight,
        },
      };
    }, [dxf_piece, topPositionRadRotation2D]);

  const positions = useMemo(() => {
    // Get array of nullposes depending of positioning.nullpos_<blenderAxe>
    const nullposesX = getNullposesObjWithNullposNames(
      positioning.nullpos_x,
      nullposes
    );
    const nullposesY = getNullposesObjWithNullposNames(
      positioning.nullpos_z, // 🚨 Blender y is ThreeJs z
      nullposes
    );
    const nullposesZ = getNullposesObjWithNullposNames(
      positioning.nullpos_y, // 🚨 Blender y is ThreeJs z
      nullposes
    );

    // Select nullpose object depending of positioning.process_<blenderAxe> values
    const nullposX = getNullposDependingOfProcess(
      nullposesX,
      "x",
      positioning.process_x
    );
    const nullposY = getNullposDependingOfProcess(
      nullposesY,
      "y",
      positioning.process_z // 🚨 Blender z is ThreeJs y
    );
    const nullposZ = getNullposDependingOfProcess(
      nullposesZ,
      "z",
      positioning.process_y // 🚨 Blender z is ThreeJs y
    );

    setNullposesPositionings((cur) => {
      return {
        ...cur,
        [id]: { x: nullposX, y: nullposY, z: nullposZ },
      };
    });

    return [
      (positioning.reverse_x ? -1 : 1) * nullposX.position.x +
        (positioning.offset_x || 0) || 0,
      nullposY.position.y + (positioning.offset_z || 0) || 0, // 🚨 Blender z is ThreeJs y
      nullposZ.position.z - (positioning.offset_y || 0) || 0, // 🚨 Blender z is ThreeJs y
    ];
  }, [id, setNullposesPositionings, positioning, nullposes]);

  const pieceShape = useMemo(() => {
    const pieceShape = new Shape();
    pieceShape.moveTo(
      scaledTopPositionedPoints[0][0],
      scaledTopPositionedPoints[0][1]
    );

    for (let i = 1; i < scaledTopPositionedPoints.length; i++) {
      pieceShape.lineTo(
        scaledTopPositionedPoints[i][0],
        scaledTopPositionedPoints[i][1]
      );
    }
    return pieceShape;
  }, [scaledTopPositionedPoints]);

  if (
    highlightedNullposName &&
    highlightedPiece3dId &&
    id !== highlightedPiece3dId
  ) {
    return null;
  }
  return (
    <PositionedGroup
      {...{ positioning, mode, name }}
      pieceDimensions={scaledTopPositionedDimensions}
      transparent={
        highlightedNullposName ||
        (highlightedPiece3dId && id !== highlightedPiece3dId)
      }
      position={positions}
    >
      {/* Frontside */}
      {!hidden && (
        <mesh
          rotation={INITIAL_BLENDER_PIECE_ROTATION}
          onPointerMove={mode === "stitching" ? (e) => {} : undefined} // Required to raycast the pois (if no event registered this mesh not appears inside intersections array of other onPointerMove)
        >
          <shapeGeometry args={[pieceShape]} />
          <meshBasicMaterial
            attach="material"
            color={"blue"}
            side={positioning.flip_normals ? BackSide : FrontSide}
            transparent
          />
        </mesh>
      )}

      {/* Outline (Contour) */}
      <Line
        points={scaledTopPositionedPoints} // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
        color="black" // Default
        lineWidth={1} // In pixels (default)
        dashed={false} // Default
        renderOrder={1}
        transparent
        rotation={INITIAL_BLENDER_PIECE_ROTATION}
      />

      {/* Backside */}

      {!hidden && (
        <mesh rotation={INITIAL_BLENDER_PIECE_ROTATION}>
          <shapeGeometry attach="geometry" args={[pieceShape]} />
          <meshBasicMaterial
            attach="material"
            color={"darkred"}
            side={positioning.flip_normals ? FrontSide : BackSide}
            transparent
          />
        </mesh>
      )}
    </PositionedGroup>
  );
};

const PositionedGroup = ({
  transparent,
  children,
  position,
  rotation = [0, 0, 0],
  positioning,
  pieceDimensions,
  mode,
  name,
  ...props
}) => {
  const positionGroup = useRef();
  const pieceGroup = useRef();
  useHelper(
    HELPERS.boxPiece && mode === "positioning" && positionGroup,
    BoxHelper,
    "blue"
  );
  const { stitchingFilters } = useCurrentGarmentContext();

  const [pivot, setPivot] = useState({ x: 0, y: 0, z: 0 });

  useEffect(() => {
    // Manage transparent depending of transparent props
    changeMeshesOpacity(positionGroup.current, transparent);
  }, [transparent]);

  useEffect(() => {
    // Get replace_origin for each axe
    const blenderOriginX = positioning.replace_origin_x || "center";
    const blenderOriginZ = positioning.replace_origin_z || "center";
    const blenderOriginY = positioning.replace_origin_y || "center";

    // Get bounding box of piece to calculate width, height and depth for pivot to place the piece depending of origin values
    const boxPieceDimensions = { width: 0, height: 0, depth: 0 };
    if (positionGroup.current) {
      const boundingBox = new Box3().setFromObject(positionGroup.current);

      boxPieceDimensions.width = boundingBox.max.x - boundingBox.min.x;
      boxPieceDimensions.height = boundingBox.max.y - boundingBox.min.y;
      boxPieceDimensions.depth = boundingBox.max.z - boundingBox.min.z;

      // Calculate pivots depending of origin values
      setPivot({
        x:
          blenderOriginX === "min"
            ? boxPieceDimensions.width / 2
            : blenderOriginX === "max"
            ? -boxPieceDimensions.width / 2
            : 0,
        y:
          blenderOriginZ === "min"
            ? boxPieceDimensions.height / 2
            : blenderOriginZ === "max"
            ? -boxPieceDimensions.height / 2
            : 0,
        z:
          blenderOriginY === "min"
            ? boxPieceDimensions.depth / 2
            : blenderOriginY === "max"
            ? -boxPieceDimensions.depth / 2
            : 0,
      });
    } else {
      console.warning(
        "groupRef.current is not defined during the update pivot effect"
      );
    }
  }, [positioning, pieceDimensions, rotation]);

  useEffect(() => {
    // Get center of the piece
    const box = new Box3().setFromObject(pieceGroup.current);
    const center = new Vector3();
    box.getCenter(center);

    // Create axis rotation depending of rotation and center of piece
    const axisX = new Vector3(1, 0, 0);
    const axisY = new Vector3(0, 1, 0);
    const axisZ = new Vector3(0, 0, 1);
    const angleX =
      (ROTATION_DIRECTION[0] * positioning.rotation[INDEX_AXIS_CONV[0]] * PI) /
      180;
    const angleY =
      (ROTATION_DIRECTION[1] * positioning.rotation[INDEX_AXIS_CONV[1]] * PI) /
      180;
    const angleZ =
      (ROTATION_DIRECTION[2] * positioning.rotation[INDEX_AXIS_CONV[2]] * PI) /
      180;

    // Order of rotation application is the Blender one (XYZ so XZY of blender)
    pieceGroup.current.rotation.set(0, 0, 0);
    pieceGroup.current.rotateOnWorldAxis(axisX, angleX);
    pieceGroup.current.rotateOnWorldAxis(axisZ, angleZ);
    pieceGroup.current.rotateOnWorldAxis(axisY, angleY);
  }, [positioning.rotation, position]);

  return (
    <>
      <group
        ref={positionGroup}
        position={position}
        name={name}
        visible={!(mode === "stitching" && !stitchingFilters.includes(name))}
      >
        <group ref={pieceGroup} position={[pivot.x, pivot.y, pivot.z]}>
          {children}
        </group>
      </group>
      {/* Helper center */}
      {HELPERS.boxPiece && mode === "positioning" && (
        <R3fPointOrigin position={position} color="blue" />
      )}
    </>
  );
};

const R3fPointOrigin = ({ position, color }) => {
  const boxRef = useRef();

  useEffect(() => {
    boxRef.current.geometry.center();
  }, [position]);

  return (
    <group position={position}>
      <Box
        ref={boxRef}
        args={[0.02, 0.02, 0.02]}
        material-color={color}
        material-side={DoubleSide}
        renderOrder={1}
      />
    </group>
  );
};
