import { createContext, useContext, useState } from "react";

const R3fContext = createContext({});

export const R3fProvider = ({ children }) => {
  const [nullposes, setNullposes] = useState([]);
  const [highlightedPiece3dId, setHighlightedPiece3dId] = useState(null);
  const [highlightedNullposName, setHighlightedNullposName] = useState(null);
  const [nullposesPositionings, setNullposesPositionings] = useState({});
  /* nullposesPositionings  : 
  {
    pieceId : {
      x: nullpose3dObject,
      y: nullpose3dObject,
      z: nullpose3dObject
    }
  }
  */

  const [highlightedStitchId, setHighlightedStitchId] = useState(null);
  const [offsetCentering, setOffsetCentering] = useState({});

  return (
    <R3fContext.Provider
      value={{
        nullposes,
        setNullposes,
        highlightedPiece3dId,
        setHighlightedPiece3dId,
        highlightedNullposName,
        setHighlightedNullposName,
        nullposesPositionings,
        setNullposesPositionings,
        highlightedStitchId,
        setHighlightedStitchId,
        offsetCentering,
        setOffsetCentering,
      }}
    >
      {children}
    </R3fContext.Provider>
  );
};

export const useR3fContext = () => useContext(R3fContext);
