import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AvumiBannerImage from "images/avumi-3ds-banner-white-190.png";
import { TbArrowBarLeft } from "react-icons/tb";
import { HiArrowLeft } from "react-icons/hi";
import { useSideBar } from "context/SideBarContext";
import { useTranslation } from "react-i18next";
import GarmentIcon from "images/garments.svg";
import PreviewIcon from "images/3d-preview.svg";
import PiecesIcon from "images/pieces.svg";
import PositioningIcon from "images/positioning.svg";
import StitchingIcon from "images/stitching.svg";
import TextureIcon from "images/texture.svg";
import { pageUrls } from "routes/paths";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";

function GlobalGarmentSidebar() {
  const { sidebarOpen, toggleSidebar, closeSidebar } = useSideBar();
  const {
    isDataReady,
    getHasPieceError,
    getHasPositioningError,
    getHasStitchingError,
    errors,
  } = useCurrentGarmentContext();
  const params = useParams();
  const navigate = useNavigate();

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const backdrop = useRef(null);

  const storedSidebarExpanded = localStorage.getItem(
    "avumi-3ds-sidebar-expanded"
  );
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (backdrop.current.contains(target)) {
        closeSidebar();
      }
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      closeSidebar();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("avumi-3ds-sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    if (sidebarOpen) {
      setSidebarExpanded(sidebarOpen);
    }
  }, [sidebarOpen]);

  return (
    <div>
      {/* GlobalGarmentSidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
        ref={backdrop}
      ></div>

      {/* GlobalGarmentSidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-blue-avumi-900 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* GlobalGarmentSidebar header */}
        <div className="flex justify-between mb-10">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => toggleSidebar(false)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <HiArrowLeft className="w-6 h-6 fill-current" />
          </button>
          {/* Logo */}
          <button
            className="block cursor-pointer"
            onClick={() => navigate(pageUrls.home)}
          >
            <img
              className="object-cover object-left h-14 opacity-1 transition-opacity duration-200 sidebar-expanded:opacity-1"
              src={AvumiBannerImage}
              alt="Authentication"
            />
          </button>
        </div>

        <div>
          <div className="space-y-8 mt-5 first:mt-0">
            <div>
              {/* Garment edition */}
              <SideBarGroupTitle i18nKey="sideBarGarmentEditionTitle" />
              <ul className="mt-3">
                <SideBarItem
                  i18nKey="sideBarGarment"
                  icon={GarmentIcon}
                  to={pageUrls.garment.general.replace(
                    ":garment_id",
                    params?.garment_id
                  )}
                  disabled={!isDataReady}
                />
                <SideBarItem
                  i18nKey="sideBarPieces"
                  icon={PiecesIcon}
                  to={pageUrls.garment.pieces.replace(
                    ":garment_id",
                    params?.garment_id
                  )}
                  disabled={!isDataReady}
                  withError={getHasPieceError()}
                />
                <SideBarItem
                  i18nKey="sideBarPositioning"
                  icon={PositioningIcon}
                  to={pageUrls.garment.positioning.replace(
                    ":garment_id",
                    params?.garment_id
                  )}
                  disabled={!isDataReady}
                  withError={getHasPositioningError()}
                />
                <SideBarItem
                  i18nKey="sideBarStitching"
                  icon={StitchingIcon}
                  to={pageUrls.garment.stitching.replace(
                    ":garment_id",
                    params?.garment_id
                  )}
                  disabled={!isDataReady}
                  withError={getHasStitchingError()}
                />
                <SideBarItem
                  i18nKey="sideBarTexture"
                  icon={TextureIcon}
                  to={pageUrls.garment.texture.replace(
                    ":garment_id",
                    params?.garment_id
                  )}
                  disabled={!isDataReady}
                  withError={Boolean(errors.textures?.length)}
                />
                <SideBarItem
                  i18nKey="sideBarSecondaryPieces"
                  icon={PiecesIcon}
                  to={pageUrls.garment.secondary_pieces.replace(
                    ":garment_id",
                    params?.garment_id
                  )}
                  disabled={!isDataReady}
                  inactive={true}
                />
              </ul>
            </div>
          </div>
          <div className="space-y-8 mt-5 first:mt-0">
            <div>
              {/* Garment edition */}
              <SideBarGroupTitle i18nKey="sideBarRenderingTitle" />
              <ul className="mt-3">
                <SideBarItem
                  i18nKey="sideBar3dPreview"
                  icon={PreviewIcon}
                  to={pageUrls.garment.preview.replace(
                    ":garment_id",
                    params?.garment_id
                  )}
                  disabled={!isDataReady}
                />
              </ul>
            </div>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <TbArrowBarLeft className="w-6 h-6 stroke-white rotate-180 sidebar-expanded:rotate-0" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalGarmentSidebar;

const SideBarGroupTitle = ({ i18nKey }) => {
  const { t } = useTranslation();

  return (
    <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
      <span
        className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
        aria-hidden="true"
      >
        •••
      </span>
      <span className="lg:hidden lg:sidebar-expanded:block 2xl:block truncate">
        {t(i18nKey)}
      </span>
    </h3>
  );
};

const SideBarItem = ({ i18nKey, icon, to, disabled, inactive, withError }) => {
  const { t } = useTranslation();
  const { state, pathname } = useLocation();

  const navigate = useNavigate();

  const isActive = pathname.includes(to);

  const handleClick = (e) => {
    if (disabled) return;
    if (inactive || isActive) e.preventDefault();
    navigate(to, { state });
  };

  return (
    <li
      className={`rounded-sm mb-0.5 last:mb-0 w-full ${
        pathname.includes(to) && "bg-gray-900"
      }`}
    >
      <button
        onClick={handleClick}
        className={`block px-3 py-2 text-gray-200 hover:text-white truncate transition duration-150 w-full ${
          isActive && "hover:text-gray-200"
        } ${
          disabled
            ? "opacity-30 cursor-default"
            : inactive
            ? "opacity-50"
            : "opacity-100"
        }`}
      >
        <div className="flex items-center relative">
          <img src={icon} width="24" height="24" alt={i18nKey} />
          {withError && (
            <div className="absolute top-0 sidebar-expanded:top-1/2 sidebar-expanded:translate-y-[-50%] right-0 rounded-full bg-red-500 w-1.5 h-1.5" />
          )}

          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
            {t(i18nKey)}
          </span>
        </div>
      </button>
    </li>
  );
};
