import { useNavigate } from "react-router-dom";

const useNavigateTo = (to, onClick, replace) => {
  const navigate = useNavigate();

  if (to && !onClick) {
    onClick = () => navigate(to, { replace });
  }

  return onClick;
};

export default useNavigateTo;
