import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropdown from "./Dropdown";
import { HiOutlineChevronDown } from "react-icons/hi";
import { BsCheckLg } from "react-icons/bs";
import SpinnerAnimatedSvg from "components/SpinnerAnimatedSvg";
import { GrClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { searchInArrayWithScoring } from "utils/parse";

const getFilteredOptions = (options, query) => {
  if (!query) {
    return options;
  }

  return searchInArrayWithScoring(options, query);
};

const DropdownSelector = ({
  align,
  options,
  multiSelect,
  onMouseOverOption = () => {},
  selectedOption,
  selectedOptions,
  getLabelOptionFromOption,
  getLabelInputFromOption,
  getIsSelectedFromOption,
  multiSelectedOptionsCustomStyle,
  isLoading,
  customWidthClass,
  fullWidth,
  onChange = () => {},
  keyTextNoOptions,
  searchText,
  error,
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [query, setQuery] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [dropdownOpen]);

  const renderInputValue = useCallback(() => {
    if (isLoading) {
      return <SpinnerAnimatedSvg grey />;
    }

    if (multiSelect) {
      return (
        <div className="flex flex-row flex-wrap gap-2">
          {selectedOptions.map((option, i) => {
            return (
              <div
                key={`${option}-${i}`}
                className={`inline-flex pl-2 h-5 rounded-sm text-xs cursor-auto ${
                  multiSelectedOptionsCustomStyle.selector(option)
                    ? multiSelectedOptionsCustomStyle.className
                    : "bg-gray-200"
                }`}
                onMouseEnter={() => onMouseOverOption(option)}
                onMouseLeave={() => onMouseOverOption(null)}
                onClick={(e) => e.stopPropagation()}
              >
                {getLabelOptionFromOption(option)}
                <div
                  className="px-2 flex items-center justify-center text-gray-500 cursor-pointer"
                  onClick={(e) => {
                    onChange(option);
                    onMouseOverOption(null);
                    e.stopPropagation();
                  }}
                >
                  <GrClose className="w-2 h-2" />
                </div>
              </div>
            );
          })}
          {dropdownOpen && (
            <input
              ref={inputRef}
              type="text"
              placeholder={searchText ?? t("placeholderNullpos")}
              className="p-0 border-none text-xs font-light outline-none focus:ring-0"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => setQuery("")}
            />
          )}
        </div>
      );
    }
    return (
      <span className="text-left">
        {getLabelInputFromOption(selectedOption)}
      </span>
    );
  }, [
    dropdownOpen,
    getLabelInputFromOption,
    getLabelOptionFromOption,
    isLoading,
    multiSelect,
    multiSelectedOptionsCustomStyle,
    onChange,
    onMouseOverOption,
    query,
    searchText,
    selectedOption,
    selectedOptions,
    t,
  ]);

  return (
    <Dropdown
      {...{ dropdownOpen, setDropdownOpen, align, fullWidth }}
      customClassName={`${
        customWidthClass || "min-w-72"
      } max-h-[200px] overflow-y-auto`}
      buttonComponent={
        <button
          className={`btn w-full justify-between ${
            customWidthClass || "min-w-72"
          } min-h-[38px] bg-white ${
            Boolean(error)
              ? "border-red-300 hover:border-red-400"
              : "border-gray-200 hover:border-gray-300"
          } text-gray-500 hover:text-gray-600`}
          style={multiSelect && { cursor: "text" }}
          aria-haspopup="true"
          disabled={isLoading}
          onClick={() => setDropdownOpen(true)}
          aria-expanded={dropdownOpen}
        >
          <div className="flex items-center">{renderInputValue()}</div>
          {!multiSelect && (
            <HiOutlineChevronDown className="w-4 h-4 shrink-0 ml-1 text-gray-500" />
          )}
        </button>
      }
    >
      {getFilteredOptions(options, query).length === 0 ? (
        <span className="text-sm flex items-center text-gray-300 justify-between w-full py-2 px-3">
          {keyTextNoOptions ? t(keyTextNoOptions) : t("noFoundOptions")}
        </span>
      ) : (
        getFilteredOptions(options, query).map((option, i) => {
          return (
            <button
              key={`option-${option}-${i}`}
              tabIndex="0"
              className={`flex text-sm items-center justify-between w-full hover:bg-gray-50 py-2 px-3 cursor-pointer ${
                getIsSelectedFromOption(
                  option,
                  multiSelect ? selectedOptions : selectedOption
                ) && "text-blue-avumi-700"
              }`}
              onClick={(e) => {
                onChange(option);
                setDropdownOpen(false);
                onMouseOverOption(null);
              }}
              onMouseOver={() => onMouseOverOption(option)}
              onMouseLeave={() => onMouseOverOption(null)}
            >
              <span>{getLabelOptionFromOption(option)}</span>

              <BsCheckLg
                className={`shrink-0 mr-2 fill-current text-blue-avumi-700 ${
                  !getIsSelectedFromOption(
                    option,
                    multiSelect ? selectedOptions : selectedOption
                  ) && "invisible"
                }`}
              />
            </button>
          );
        })
      )}
    </Dropdown>
  );
};

export default DropdownSelector;
