import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import BigButton from "components/buttons/BigButton";
import { useTranslation } from "react-i18next";

const ValidateGarmentButton = ({ reduced }) => {
  const { t } = useTranslation();
  const { validateGarment, isValidating, editedGarment, isGarmentFetching } =
    useCurrentGarmentContext();

  const handleValidateGarment = () => {
    if (editedGarment?.workshop_status === "REVIEWED") {
      return;
    }
    validateGarment();
  };

  return (
    <BigButton
      onClick={handleValidateGarment}
      label={
        editedGarment?.workshop_status === "REVIEWED"
          ? t("validatedGarment")
          : t("validateGarment")
      }
      colorVariant={
        editedGarment?.workshop_status === "REVIEWED" ? "green" : "lightBlue"
      }
      isLoading={isValidating || isGarmentFetching}
      disabled={editedGarment?.workshop_status === "REVIEWED"}
    />
  );
};

export default ValidateGarmentButton;
