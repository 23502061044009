import { Navigate, Outlet, useLocation } from "react-router-dom";
import { pageUrls } from "routes/paths";
import { useAuth } from "context/AuthProvider";
import { useEffect } from "react";

const RequireAuth = () => {
  const location = useLocation();
  const { authenticated, checkUserIsSignedIn } = useAuth();

  useEffect(() => {
    checkUserIsSignedIn();
  }, []);

  return authenticated ? (
    <Outlet />
  ) : (
    <Navigate to={pageUrls.login} replace state={{ path: location.pathname }} />
  );
};

export default RequireAuth;
