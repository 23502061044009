import React, { useEffect } from "react";
import AuthImage from "images/auth-image.jpg";
import AvumiBannerImage from "images/avumi-3ds-banner.png";
import BigButton from "components/buttons/BigButton";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/AuthProvider";
import { useNavigate } from "react-router";
import InputText from "components/inputs/InputText";
import { useFormik } from "formik";
import { yupValidation } from "utils/yupValidation";
import { pageUrls } from "routes/paths";

const SignIn = () => {
  const { t } = useTranslation();
  const {
    authenticated,
    authIsChecking,
    signIn,
    errorMessage,
    isChangePasswordChallenge,
  } = useAuth();

  const navigate = useNavigate();

  const { handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
      newPassword: "",
    },
    onSubmit: ({ email, password, newPassword }) =>
      signIn({ username: email, password, newPassword }),
    validate: yupValidation(
      isChangePasswordChallenge ? "signinWithNewPassword" : "signin"
    ),
  });

  useEffect(() => {
    if (authenticated) {
      navigate(pageUrls.home, { replace: true });
    }
  }, [authenticated]);

  return (
    <main className="bg-white ">
      <div className="relative md:flex max-w-5xl m-auto">
        {/* Content */}
        <div className="md:w-1/3 md:m max-w-sm m-auto md:m-0">
          <div className="min-h-screen h-full flex flex-col after:flex-1 before:flex-1">
            <div className="px-4 ">
              <img
                className="object-contain object-center w-full pb-5"
                src={AvumiBannerImage}
                alt="Authentication"
              />
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <InputText
                    title={t("emailAccount")}
                    type="email"
                    id="email"
                    disabled={authIsChecking}
                    isError={errors.email && touched.email}
                    errorMessage={
                      errors.email && touched.email && t(errors.email.keyText)
                    }
                    onChange={handleChange("email")}
                  />
                  <InputText
                    title={t(
                      isChangePasswordChallenge ? "oldPassword" : "password"
                    )}
                    type="password"
                    id="password"
                    disabled={authIsChecking}
                    isError={errors.password && touched.password}
                    errorMessage={
                      errors.password &&
                      touched.password &&
                      t(errors.password.keyText)
                    }
                    autoComplete="on"
                    onChange={handleChange("password")}
                  />
                  {isChangePasswordChallenge && (
                    <InputText
                      title={t("newPasswordRequired")}
                      type="password"
                      id="newPassword"
                      disabled={authIsChecking}
                      isError={errors.newPassword && touched.newPassword}
                      errorMessage={
                        errors.newPassword &&
                        touched.newPassword &&
                        t(errors.newPassword.keyText)
                      }
                      autoComplete="on"
                      onChange={handleChange("newPassword")}
                    />
                  )}
                  {errorMessage && (
                    <p className="text-sm text-red-500">{errorMessage}</p>
                  )}
                </div>
                <div className=" flex items-center justify-center mt-6">
                  <BigButton
                    colorVariant="blue"
                    label={t("signIn")}
                    type="submit"
                    onClick={handleSubmit}
                    isLoading={authIsChecking}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-2/3 p-20"
          aria-hidden="true"
        >
          <img
            className="object-contain object-center w-full h-full"
            src={AuthImage}
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
};

export default SignIn;
