import { useTranslation } from "react-i18next";
import BigButton from "components/buttons/BigButton";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";

const ValidatePieceFooter = () => {
  const { t } = useTranslation();
  const { selectedDxfPiece, handleMarkControlledPiece } =
    useCurrentGarmentContext();

  return (
    <footer>
      <div className="flex flex-col px-6 py-5 border-t border-gray-200">
        <div className="flex self-end space-x-3">
          <BigButton
            type="submit"
            colorVariant={selectedDxfPiece.controlled ? "white" : "lightBlue"}
            label={t(
              selectedDxfPiece.controlled
                ? "markUncontrolledPiece"
                : "markControlledPiece"
            )}
            onClick={() =>
              handleMarkControlledPiece(!selectedDxfPiece.controlled)
            }
          />
        </div>
      </div>
    </footer>
  );
};

export default ValidatePieceFooter;
