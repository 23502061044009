import SmallButton from "components/buttons/SmallButton";
import DropdownInput from "components/inputs/DropdownInput";
import InputNumber from "components/inputs/InputNumber";
import InputText from "components/inputs/InputText";
import React from "react";
import { useTranslation } from "react-i18next";
import NormalMap from "./NormalMap";
import ImagePreview from "components/imagePreview/ImagePreview";
import FileUploader from "components/fileUploader/FileUploader";

const LogoContent = ({
  logo,
  logoPositions,
  selectedPositions,
  isReadonly,
  index,
  setValue,
  onRemove,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <div className="border-t border-gray-200 px-5 py-4">
      <div className="flex flex-col md:flex-row gap-4 mb-4 pb-4 border-b border-gray-200">
        <div className="flex-1">
          <h3 className="text-lg text-gray-800 font-bold mb-2">
            {t("textureTitle")}
          </h3>
          <div className="grid gap-2 md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3">
            <InputText
              title={t("textureNameTitle")}
              type="text"
              customWidthClass="auto"
              value={logo?.name ?? ""}
              onChange={(e) => {
                setValue("name", e.target.value);
              }}
              error={errors?.name?.keyText && t(errors?.name?.keyText)}
              disabled={isReadonly}
            />
            <InputNumber
              label={t("textureWidthTitle")}
              type="decimal"
              customWidthClass="auto"
              value={logo?.width ?? 0}
              min={0}
              onChange={(quantity) => {
                setValue("width", quantity);
              }}
              error={errors?.width?.keyText && t(errors?.width?.keyText)}
              disabled={isReadonly}
            />
            <InputNumber
              label={t("textureHeightTitle")}
              type="decimal"
              customWidthClass="auto"
              value={logo?.height ?? 0}
              min={0}
              onChange={(quantity) => {
                setValue("height", quantity);
              }}
              error={errors?.height?.keyText && t(errors?.height?.keyText)}
              disabled={isReadonly}
            />
            <InputNumber
              label={t("textureRotationTitle")}
              type="integer"
              customWidthClass="auto"
              value={logo?.rotation ?? 0}
              onChange={(quantity) => {
                setValue("rotation", quantity);
              }}
              disabled={isReadonly}
            />
            <div className="xl:col-span-2">
              <DropdownInput
                title={t("textureLogoPosition")}
                options={
                  isReadonly
                    ? logo?.positions
                    : logoPositions?.filter(
                        (postion) => !selectedPositions.includes(postion)
                      ) ?? []
                }
                selectedOption={
                  isReadonly ? logo?.positions?.[0] : logo?.position ?? ""
                }
                onChange={(option) => {
                  setValue("position", option);
                }}
                getLabelOptionFromOption={(option) => option}
                getLabelInputFromOption={(option) => option}
                customWidthClass="min-w-auto"
                fullWidth
                error={
                  errors?.position?.keyText && t(errors?.position?.keyText)
                }
                disabled={isReadonly}
              />
            </div>
          </div>
        </div>
        <div className="overflow-x-scroll no-scrollbar w-full md:w-[160px]">
          {isReadonly ? (
            <ImagePreview src={logo?.texture?.file_url} />
          ) : (
            <FileUploader
              garmentType="garmentTextures"
              value={logo?.texture?.file_path}
              onChange={(value) =>
                setValue("texture", { ...logo.texture, file_path: value })
              }
              error={
                errors?.texture?.file_path?.keyText &&
                t(errors?.texture?.file_path?.keyText)
              }
            />
          )}
        </div>
      </div>
      <NormalMap
        normalMap={logo?.texture?.normal_map}
        isReadonly={isReadonly}
        setValue={(key, value) => {
          if (key === "type") {
            setValue("texture", {
              ...logo.texture,
              normal_map: {
                [key]: value,
              },
            });
          } else {
            setValue("texture", {
              ...logo.texture,
              normal_map: {
                ...logo.texture.normal_map,
                [key]: value,
              },
            });
          }
        }}
        errors={errors?.texture?.normal_map}
      />
      {Boolean(onRemove) && index >= 0 && (
        <div className="flex justify-end mt-4 pt-4 border-t border-gray-200">
          <SmallButton
            type="button"
            label={t("delete")}
            colorVariant="red"
            onClick={() => onRemove(index)}
          />
        </div>
      )}
    </div>
  );
};

export default LogoContent;
