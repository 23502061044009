import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import BadgeIcons from "components/icons/BadgeIcons";
import { DO_NOT_USE } from "constants/garments";
import { LuCircleSlash2 } from "react-icons/lu";

function PiecesSideBar() {
  const { t } = useTranslation();
  const {
    selectedDxfPiece,
    setSelectedDxfPieceId,
    dxf_pieces,
    piecesFilter,
    getHasPieceError,
  } = useCurrentGarmentContext();

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-60 md:space-y-3">
      {/* Pieces types group */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3">
          {t("piecesTypeList")}
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          {dxf_pieces[piecesFilter].map((piece, i) => {
            return (
              <li
                key={piece.id}
                className={`flex mr-0.5 md:mr-0 md:mb-0.5 items-center px-2.5 py-2 space-x-3 rounded whitespace-nowrap cursor-pointer ${
                  selectedDxfPiece.id === piece.id && "bg-blue-avumi-50"
                }`}
                onClick={() => setSelectedDxfPieceId(piece.id)}
              >
                {piece.type === DO_NOT_USE ? (
                  <LuCircleSlash2
                    className={`${
                      !piece.controlled ? "text-gray-300" : "text-green-500"
                    }`}
                  />
                ) : (
                  <BadgeIcons
                    type={getHasPieceError(piece.id) ? "error" : "success"}
                    grey={!getHasPieceError(piece.id) && !piece.controlled}
                  />
                )}
                <span
                  className={`text-sm font-medium  truncate  ${
                    selectedDxfPiece.id === piece.id
                      ? "text-blue-avumi-300"
                      : piece.type === DO_NOT_USE
                      ? "text-gray-400 hover:text-gray-500"
                      : "text-gray-700 hover:text-gray-800"
                  } ${piece.type === DO_NOT_USE ? "line-through" : ""}`}
                >
                  {piece.type || t("doNotUse")}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default PiecesSideBar;
