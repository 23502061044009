import React, { useState } from "react";
import GarmentPagesWrapper from "layouts/contentPage/GarmentPagesWrapper";
import GarmentPageHeader from "layouts/headers/GarmentPageHeader";
import { useTranslation } from "react-i18next";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import ValidatePieceFooter from "layouts/footers/ValidatePieceFooter";
import PiecesSideBar from "layouts/pieces/PiecesSideBar";
import DropdownInput from "components/inputs/DropdownInput";
import InputNumber from "components/inputs/InputNumber";
import { DO_NOT_USE, pieceList, topPositions } from "constants/garments";
import PoiAccordions from "layouts/pieces/PoiAccordions";
import { useModal } from "context/ModalProvider";
import SvgViewer from "components/draw/SvgViewer";

const GarmentPieces = () => {
  const { t } = useTranslation();
  const {
    editedGarment,
    selectedDxfPiece,
    isGarmentInitialLoading,
    changeEditedPiece,
    changeEditedPieceType,
    createNew3dPieceAndIncreaseQty,
    delete3dPieceAndDecreaseQty,
    getPieceError,
  } = useCurrentGarmentContext();
  const { showModal } = useModal();

  const [highlightedPoiId, setHighlightedPoiId] = useState(null);

  const handleChangeDxfPieceType = (option) => {
    // Check if the piece name/type is already used
    if (
      option &&
      editedGarment?.dxf_pieces.some(
        (p) =>
          p.type !== DO_NOT_USE &&
          p.type === option &&
          p.type !== selectedDxfPiece.type
      )
    ) {
      showModal("warning", {
        titleKey: "pieceTypeAlreadyUsedTitle",
        messageKey: "pieceTypeAlreadyUsedMsg",
      });
    } else {
      changeEditedPieceType(option);
    }
  };

  const handleChangeQuantity = (qty) => {
    try {
      // Increase so create 3dPieces
      if (selectedDxfPiece.quantity < qty) {
        createNew3dPieceAndIncreaseQty();
      }
      if (selectedDxfPiece.quantity > qty) {
        delete3dPieceAndDecreaseQty();
      }
    } catch (error) {
      console.error(">>> handleChangeQuantity", error);
    }
  };

  return (
    <GarmentPagesWrapper>
      <GarmentPageHeader type="pieces" />
      <div className="bg-white shadow-lg rounded-sm mb-8 flex">
        <div className="flex flex-col flex-grow w-full md:flex-row md:-mr-px">
          <PiecesSideBar />

          <div className="flex flex-col grow overflow-hidden">
            {/* Panel body */}
            <div className="flex flex-col grow p-6 space-y-10">
              {/* DxF Annotations */}
              {selectedDxfPiece?.dxf_annotations && (
                <section>
                  <h3 className="text-xl text-gray-800 font-bold mb-5">
                    {t("piecesPageDXFTitle")}
                  </h3>
                  <div className="flex flex-col items-start text-sm ">
                    {Object.entries(selectedDxfPiece?.dxf_annotations).map(
                      ([key, value]) => (
                        <span key={`annotation-${key}`}>
                          <b>{key}</b>
                          {` : ${value}`}
                        </span>
                      )
                    )}
                  </div>
                </section>
              )}
              {/* Piece properties */}
              <section>
                <h2 className="text-2xl text-gray-800 font-bold mb-5">
                  {t("piecesPagePropertiesTitle")}
                </h2>
                <div className="inline-flex flex-wrap gap-5">
                  <DropdownInput
                    isLoading={isGarmentInitialLoading}
                    title={t("garmentTypesTitle")}
                    options={[...pieceList.sort(), DO_NOT_USE]}
                    getLabelOptionFromOption={(option) => {
                      return option === DO_NOT_USE ? t("doNotUse") : option;
                    }}
                    getLabelInputFromOption={(option) => {
                      return option === DO_NOT_USE ? t("doNotUse") : option;
                    }}
                    isDisabled={(option) => option}
                    selectedOption={selectedDxfPiece?.type}
                    onChange={handleChangeDxfPieceType}
                    error={t(getPieceError("type")?.errorKeyText)}
                  />
                  <DropdownInput
                    isLoading={isGarmentInitialLoading}
                    title={t("garmentTopTitle")}
                    options={topPositions}
                    isDisabled={(option) => option}
                    selectedOption={selectedDxfPiece?.top_position}
                    onChange={(option) =>
                      changeEditedPiece({
                        top_position: option,
                      })
                    }
                    error={t(getPieceError("top_position")?.errorKeyText)}
                  />
                  <InputNumber
                    label={t("pieceQuantityTitle")}
                    value={selectedDxfPiece?.quantity}
                    onChange={handleChangeQuantity}
                    type="integer"
                    min={1}
                    error={t(getPieceError("quantity")?.errorKeyText)}
                  />
                </div>
              </section>
              {/* POI and Junctions */}
              <section>
                <h2 className="text-2xl text-gray-800 font-bold mb-5">
                  {t("piecesPagePOIJunctionTitle")}
                </h2>
                <div
                  id="poi-accordions-wrapper"
                  className="flex items-start relative"
                >
                  <div className="flex-1">
                    <PoiAccordions
                      {...{ highlightedPoiId, setHighlightedPoiId }}
                    />
                  </div>
                  <div className="flex h-full">
                    {selectedDxfPiece?.points && (
                      <SvgViewer
                        {...{ highlightedPoiId, setHighlightedPoiId }}
                        points={selectedDxfPiece?.points}
                        pois={selectedDxfPiece?.pois}
                      />
                    )}
                  </div>
                </div>
              </section>
            </div>

            {/* Panel footer */}
            <ValidatePieceFooter mode="pieces" />
          </div>
        </div>
      </div>
    </GarmentPagesWrapper>
  );
};

export default GarmentPieces;
