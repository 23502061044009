import { MutationCache, QueryClient } from "@tanstack/react-query";
import { QueryCache } from "@tanstack/react-query";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { t } from "i18next";

const queryCache = new QueryCache({
  onError: (error, query) => {
    Sentry.captureException(error);
    toast(t("requestError"), {
      toastId: "request-error-toast",
      type: "warning",
      theme: "colored",
    });
  },
});

const mutationCache = new MutationCache({
  onError: (error, _variables, _context, mutation) => {
    Sentry.captureException(error);
    toast(t("requestError"), {
      toastId: "request-error-toast",
      type: "warning",
      theme: "colored",
    });
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
  queryCache,
  mutationCache,
});
