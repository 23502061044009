import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { clearPrivate } from "store/globalActions";

const initialState = {};

const unsavedGarmentsSlice = createSlice({
  name: "unsavedGarments",
  initialState,
  reducers: {
    addUnsavedGarment(state, action) {
      return { ...state, [action.payload.garmentId]: action.payload.garment };
    },
    removeUnsavedGarment(state, action) {
      const newState = cloneDeep(state);
      delete newState[action.payload];
      return newState;
    },
  },
  extraReducers: (build) => {
    build.addCase(clearPrivate, (state, action) => initialState);
  },
});

const { actions, reducer } = unsavedGarmentsSlice;
export const { addUnsavedGarment, removeUnsavedGarment } = actions;
export default reducer;
