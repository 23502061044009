import React from "react";
import GarmentPagesWrapper from "layouts/contentPage/GarmentPagesWrapper";
import GarmentPageHeader from "layouts/headers/GarmentPageHeader";
import PositioningSideBar from "layouts/positionning/PositioningSideBar";
import PositioningView from "layouts/positionning/PositioningView";
import { R3fProvider } from "context/R3fProvider";

const GarmentPositioning = () => {
  return (
    <GarmentPagesWrapper fullContentHeight>
      <GarmentPageHeader type="positioning" />
      <div className="bg-white shadow-lg rounded-sm mb-8 flex flex-grow overflow-hidden">
        <div className="flex overflow-hidden flex-col w-full md:flex-row md:-mr-px">
          <R3fProvider>
            <PositioningSideBar />
            <PositioningView />
          </R3fProvider>
        </div>
      </div>
    </GarmentPagesWrapper>
  );
};

export default GarmentPositioning;
