import { useGLTF } from "@react-three/drei";
import { useEffect, useRef } from "react";
import { useR3fContext } from "context/R3fProvider";
import { changeMeshesOpacity } from "utils/r3f";
import { useThree } from "@react-three/fiber";
import useCenterView from "./useCenterView";

const R3fAvatar = ({
  url,
  setIsAvatarLoaded,
  isAvatarLoaded,
  mode,
  ...props
}) => {
  const { highlightedNullposName } = useR3fContext();
  const { camera, gl, controls } = useThree();

  const gltf = useGLTF(url, true);
  const groupRef = useRef();

  const { handleCenterView } = useCenterView(
    gltf.scene,
    mode === "positioning" && isAvatarLoaded
  );

  useEffect(() => {
    if (gltf?.scene && controls?.target) {
      setIsAvatarLoaded(true);
    }
  }, [gltf, controls]);

  useEffect(() => {
    if (mode === "positioning") {
      changeMeshesOpacity(
        groupRef.current,
        Boolean(highlightedNullposName),
        0.5
      );
    }
  }, [highlightedNullposName]);

  useEffect(() => {
    // Center view with Ctrl+ Space or control + Space
    const handler = (event) => {
      if (event?.keyCode === 67 && (event.shiftKey || event.metaKey)) {
        event.preventDefault();
        // If use press Ctrl+Space ou Cmd+Space
        handleCenterView();
      }
    };

    const canvas = document.getElementById("canvas-wrapper");
    canvas.onmouseenter = () => window.addEventListener("keydown", handler);
    canvas.onmouseleave = () => window.removeEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [camera, controls, gl.domElement, gltf]);

  return (
    <>
      <group ref={groupRef} name="avatar" visible={mode === "positioning"}>
        {isAvatarLoaded && (
          <primitive
            object={gltf.scene}
            {...props}
            onPointerMove={(e) => null} // Required to raycast the nullposes (if no event registered this mesh not appears inside intersections array of other onPointerMove)
          />
        )}
      </group>
    </>
  );
};

export default R3fAvatar;
