import Accordion from "components/accordion/Accordion";
import LinkTextButton from "components/buttons/LinkTextButton";
import SmallButton from "components/buttons/SmallButton";
import PoiCoordinates from "components/coordinates/PoisCoordinates";
import SvgViewer from "components/draw/SvgViewer";
import CheckBox from "components/inputs/CheckBox";
import DropdownInput from "components/inputs/DropdownInput";
import InputNumber from "components/inputs/InputNumber";
import InputText from "components/inputs/InputText";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

const Cords = ({ items }) => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(-1);
  const [newItem, setNewItem] = useState(undefined);
  const allItems = newItem ? [...items, newItem] : items;
  const { handleSubmit, handleReset, setFieldValue, values, errors } =
    useFormik({
      initialValues: {
        selectedPiece: "",
        cordName: "",
        capsName: "",
        positionName: "",
        length: 0,
        width: 0,
        flatCord: false,
        assignTextureCord: "",
        assignTextureCaps: "",
        coords: [],
      },
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: async (data) => {
        console.log(data);
      },
      // validate: yupValidation("TODO"),
    });

  const handleCancel = () => {
    setOpenIndex(-1);
    setNewItem(undefined);
    handleReset();
  };

  const handleAdd = () => {
    setOpenIndex(allItems?.length ?? -1);
    setNewItem({ isNew: true });
  };

  const handleDelete = () => {
    console.log("delete");
  };

  return (
    <div>
      <h2 className="text-2xl text-gray-800 font-bold mb-5">
        {t("spCordsTitle")}
      </h2>
      <Accordion
        openIndex={openIndex}
        items={allItems.map((item) => ({
          title: `${t("spCordTitleName")} - ${
            item?.isNew ? `[${t("spPending")}]` : "[Cord Name]"
          } `,
          content: (
            <Cord
              values={
                item?.isNew
                  ? values
                  : //TODO: map item values
                    {
                      selectedPiece: "",
                      cordName: "",
                      capsName: "",
                      positionName: "",
                      length: 0,
                      width: 0,
                      flatCord: false,
                      assignTextureCord: "",
                      assignTextureCaps: "",
                      coords: [],
                    }
              }
              errors={errors}
              setFieldValue={setFieldValue}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              onDelete={handleDelete}
              isNew={item?.isNew}
            />
          ),
        }))}
      />
      <div className="flex justify-center mt-2">
        {!newItem && (
          <LinkTextButton
            type="button"
            label={t("spAddCord")}
            Icon={FiPlus}
            onClick={handleAdd}
          />
        )}
      </div>
    </div>
  );
};

export default Cords;

const Cord = ({
  values,
  errors,
  setFieldValue,
  isNew,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-5 border-t border-gray-200">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-grow">
          <div className="grid gap-2 lg:grid-cols-3">
            <div className="md:col-span-3">
              <DropdownInput
                title={t("texturePiecesTitle")}
                options={[]}
                getLabelOptionFromOption={(option) => option}
                selectedOption={values.selectedPiece}
                onChange={(option) => {
                  setFieldValue("selectedPiece", option);
                }}
                fullWidth
                error={
                  errors?.selectedPiece?.keyText &&
                  t(errors?.selectedPiece?.keyText)
                }
                disabled={!isNew}
                searchText={t("texturePlacesSearch")}
              />
            </div>
            <InputText
              title={t("spCordName")}
              type="text"
              value={values.cordName}
              onChange={(e) => {
                setFieldValue("cordName", e.target.value);
              }}
              error={Boolean(errors.cordName) && t(errors?.cordName?.keyText)}
              disabled={!isNew}
            />
            <InputText
              title={t("spCapsName")}
              type="text"
              value={values.capsName}
              onChange={(e) => {
                setFieldValue("capsName", e.target.value);
              }}
              error={Boolean(errors.capsName) && t(errors?.capsName?.keyText)}
              disabled={!isNew}
            />
            <InputText
              title={t("spPositionName")}
              type="text"
              value={values.positionName}
              onChange={(e) => {
                setFieldValue("positionName", e.target.value);
              }}
              error={
                Boolean(errors.positionName) && t(errors?.positionName?.keyText)
              }
              disabled={!isNew}
            />
            <InputNumber
              label={t("spLength")}
              type="decimal"
              customWidthClass="auto"
              value={values.length}
              min={0}
              onChange={(quantity) => {
                setFieldValue("length", quantity);
              }}
              error={Boolean(errors.length) && t(errors?.length?.keyText)}
              disabled={!isNew}
            />
            <InputNumber
              label={t("spWidth")}
              type="decimal"
              customWidthClass="auto"
              value={values.width}
              min={0}
              onChange={(quantity) => {
                setFieldValue("width", quantity);
              }}
              error={Boolean(errors.width) && t(errors?.width?.keyText)}
              disabled={!isNew}
            />
            <CheckBox
              label={t("spFlatCord")}
              disabled={!isNew}
              checked={values.flatCord}
              onChange={(e) => {
                setFieldValue("flatCord", e.target.checked);
              }}
            />
            <DropdownInput
              title={t("spAssignTextureCord")}
              options={[]}
              selectedOption={values.assignTextureCord}
              onChange={(option) => {
                setFieldValue("assignTextureCord", option);
              }}
              getLabelOptionFromOption={(option) => option}
              getLabelInputFromOption={(option) => option}
              customWidthClass="min-w-auto"
              fullWidth
              error={
                Boolean(errors.assignTextureCord) &&
                t(errors?.assignTextureCord?.keyText)
              }
              disabled={!isNew}
            />
            <div className="md:col-span-2">
              <DropdownInput
                title={t("spAssignTextureCaps")}
                selectedOptions={values.assignTextureCaps}
                onChange={(option) => {
                  setFieldValue("assignTextureCaps", option);
                }}
                getLabelOptionFromOption={(option) => option}
                searchText={t("spAssignTextureCapsSearchText")}
                fullWidth
                error={
                  Boolean(errors.assignTextureCaps) &&
                  t(errors?.assignTextureCaps?.keyText)
                }
                disabled={!isNew}
              />
            </div>
            <div className="md:col-span-3">
              {/* TODO: add value, onChange and disabled to Poi inputs */}
              <PoiCoordinates
                point={[710.274475, 1353.059814]}
                poiId={"dxfPiece-BACK0-poi9"}
              />
            </div>
          </div>
        </div>
        <div>
          <SvgViewer
            highlightedPoiId={undefined}
            setHighlightedPoiId={() => {}}
            points={[]}
            pois={[]}
          />
        </div>
      </div>
      <div
        className={`flex ${
          isNew ? "justify-between" : "justify-end"
        } mt-4 pt-4 border-t border-gray-200`}
      >
        {isNew ? (
          <>
            <SmallButton
              type="button"
              label={t("cancel")}
              colorVariant="greyLight"
              onClick={onCancel}
            />
            <SmallButton type="button" label={t("create")} onClick={onSubmit} />
          </>
        ) : (
          <SmallButton
            type="button"
            label={t("delete")}
            colorVariant="red"
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};
