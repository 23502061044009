import Accordion from "components/accordion/Accordion";
import LinkTextButton from "components/buttons/LinkTextButton";
import SmallButton from "components/buttons/SmallButton";
import PoiCoordinates from "components/coordinates/PoisCoordinates";
import SvgViewer from "components/draw/SvgViewer";
import DropdownInput from "components/inputs/DropdownInput";
import InputText from "components/inputs/InputText";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

const Zippers = ({ items }) => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(-1);
  const [newItem, setNewItem] = useState(undefined);
  const allItems = newItem ? [...items, newItem] : items;
  const { handleSubmit, handleReset, setFieldValue, values, errors } =
    useFormik({
      initialValues: {
        selectedPiece: "",
        zipperName: "",
        zipperComponentsName: "",
        startPositionName: "",
        endPositionName: "",
        cords_1: [], //TODO: replace key
        cords_2: [], //TODO: replace key
        assignTextureFabric: "",
        assignTextureComponents: "",
      },
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: async (data) => {
        console.log(data);
      },
      // validate: yupValidation("TODO"),
    });

  const handleCancel = () => {
    setOpenIndex(-1);
    setNewItem(undefined);
    handleReset();
  };

  const handleAdd = () => {
    setOpenIndex(allItems?.length ?? -1);
    setNewItem({ isNew: true });
  };

  const handleDelete = () => {
    console.log("delete");
  };

  return (
    <div className="mt-4">
      <h2 className="text-2xl text-gray-800 font-bold mb-5">
        {t("spZippersTitle")}
      </h2>
      <Accordion
        openIndex={openIndex}
        items={allItems.map((item) => ({
          title: `${t("spZipperTitleName")} - ${
            item?.isNew ? `[${t("spPending")}]` : "[Zipper Name]"
          } `,
          content: (
            <Zipper
              values={
                item?.isNew
                  ? values
                  : //TODO: map item values
                    {
                      selectedPiece: "",
                      zipperName: "",
                      zipperComponentsName: "",
                      startPositionName: "",
                      endPositionName: "",
                      cords_1: [], //TODO: replace key
                      cords_2: [], //TODO: replace key
                      assignTextureFabric: "",
                      assignTextureComponents: "",
                    }
              }
              errors={errors}
              setFieldValue={setFieldValue}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              onDelete={handleDelete}
              isNew={item?.isNew}
            />
          ),
        }))}
      />
      <div className="flex justify-center mt-2">
        {!newItem && (
          <LinkTextButton
            type="button"
            label={t("spAddZipper")}
            Icon={FiPlus}
            onClick={handleAdd}
          />
        )}
      </div>
    </div>
  );
};

export default Zippers;

const Zipper = ({
  values,
  errors,
  setFieldValue,
  isNew,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-5 border-t border-gray-200">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-grow">
          <div className="grid gap-2 lg:grid-cols-2">
            <div className="md:col-span-2">
              <DropdownInput
                title={t("texturePiecesTitle")}
                options={[]}
                getLabelOptionFromOption={(option) => option}
                selectedOption={values.selectedPiece}
                onChange={(option) => {
                  setFieldValue("selectedPiece", option);
                }}
                fullWidth
                error={
                  errors?.selectedPiece?.keyText &&
                  t(errors?.selectedPiece?.keyText)
                }
                disabled={!isNew}
                searchText={t("texturePlacesSearch")}
              />
            </div>
            <InputText
              title={t("spZipperName")}
              type="text"
              value={values.zipperName}
              onChange={(e) => {
                setFieldValue("zipperName", e.target.value);
              }}
              isError={Boolean(errors.zipperName)}
              errorMessage={
                Boolean(errors.zipperName) && t(errors?.zipperName?.keyText)
              }
              disabled={!isNew}
            />
            <InputText
              title={t("spZipperComponentsName")}
              type="text"
              value={values.zipperComponentsName}
              onChange={(e) => {
                setFieldValue("zipperComponentsName", e.target.value);
              }}
              isError={Boolean(errors.zipperComponentsName)}
              errorMessage={
                Boolean(errors.zipperComponentsName) &&
                t(errors?.zipperComponentsName?.keyText)
              }
              disabled={!isNew}
            />
            <InputText
              title={t("spStartPositionName")}
              type="text"
              value={values.startPositionName}
              onChange={(e) => {
                setFieldValue("startPositionName", e.target.value);
              }}
              isError={Boolean(errors.startPositionName)}
              errorMessage={
                Boolean(errors.startPositionName) &&
                t(errors?.startPositionName?.keyText)
              }
              disabled={!isNew}
            />
            <InputText
              title={t("spEndPositionName")}
              type="text"
              value={values.endPositionName}
              onChange={(e) => {
                setFieldValue("endPositionName", e.target.value);
              }}
              isError={Boolean(errors.endPositionName)}
              errorMessage={
                Boolean(errors.endPositionName) &&
                t(errors?.endPositionName?.keyText)
              }
              disabled={!isNew}
            />
            {/* TODO: add value, onChange and disabled to Poi inputs */}
            <PoiCoordinates
              point={[710.274475, 1353.059814]}
              poiId={"dxfPiece-BACK0-poi9"}
            />
            {/* TODO: add value, onChange and disabled to Poi inputs */}
            <PoiCoordinates
              point={[710.274475, 1353.059814]}
              poiId={"dxfPiece-BACK0-poi9"}
            />
            <DropdownInput
              title={t("spAssignTextureFabric")}
              options={[]}
              selectedOption={values.assignTextureFabric}
              onChange={(option) => {
                setFieldValue("assignTextureFabric", option);
              }}
              getLabelOptionFromOption={(option) => option}
              getLabelInputFromOption={(option) => option}
              customWidthClass="min-w-auto"
              fullWidth
              isError={Boolean(errors.assignTextureFabric)}
              errorMessage={
                Boolean(errors.assignTextureFabric) &&
                t(errors?.assignTextureFabric?.keyText)
              }
              disabled={!isNew}
            />
            <DropdownInput
              title={t("spAssignTextureComponents")}
              options={[]}
              selectedOption={values.assignTextureComponents}
              onChange={(option) => {
                setFieldValue("assignTextureComponents", option);
              }}
              getLabelOptionFromOption={(option) => option}
              getLabelInputFromOption={(option) => option}
              customWidthClass="min-w-auto"
              fullWidth
              isError={Boolean(errors.assignTextureComponents)}
              errorMessage={
                Boolean(errors.assignTextureComponents) &&
                t(errors?.assignTextureComponents?.keyText)
              }
              disabled={!isNew}
            />
          </div>
        </div>
        <div>
          <SvgViewer
            highlightedPoiId={undefined}
            setHighlightedPoiId={() => {}}
            points={[]}
            pois={[]}
          />
        </div>
      </div>
      <div
        className={`flex ${
          isNew ? "justify-between" : "justify-end"
        } mt-4 pt-4 border-t border-gray-200`}
      >
        {isNew ? (
          <>
            <SmallButton
              type="button"
              label={t("cancel")}
              colorVariant="greyLight"
              onClick={onCancel}
            />
            <SmallButton type="button" label={t("create")} onClick={onSubmit} />
          </>
        ) : (
          <SmallButton
            type="button"
            label={t("delete")}
            colorVariant="red"
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};
