const colorVariantsClass = {
  vLightBlue:
    "bg-blue-avumi-200 hover:bg-blue-avumi-300 disabled:bg-blue-avumi-100 text-white",
  lightBlue:
    "bg-blue-avumi-300 hover:bg-blue-avumi-400 text-white disabled:bg-blue-avumi-300",
  blue: "bg-blue-avumi-700 hover:bg-blue-avumi-900 text-white disabled:bg-blue-avumi-700",
  white:
    "bg-white text-gray-500 border-gray-200 hover:border-gray-300 hover:text-gray-600 disabled:bg-white disabled:text-gray-500 disabled:border-gray-200",
  greyDark: "bg-gray-900 text-gray-200 disabled:bg-gray-900",
  greyLight: "bg-gray-100 hover:bg-gray-200 disabled:bg-gray-100 text-gray-600",
  greyDisabled: "bg-gray-50 text-gray-300",
  red: "bg-red-500 hover:bg-red-600 text-white disabled:bg-red-500",
  green: "bg-green-200 hover:shadow- text-green-500 disabled:bg-green-300",
};

export default colorVariantsClass;
