import { Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { capitalize } from "lodash";
import { useRef } from "react";
import { Euler } from "three";

const { PI } = Math;
const fontSize = 0.05;
const axeLengthTop = 2;
const axeLength = 1;

const R3fAxesHelpers = () => {
  const textTop = useRef();
  const textBack = useRef();
  const textLeft = useRef();

  const texts = useRef({
    top: {
      position: [-fontSize, axeLengthTop - fontSize * 1.2, 0],
      rotation: new Euler(0, 0, PI / 2),
      scale: [1, 1, 1],
      ref: textTop,
      color: "deepskyblue",
      label: "Top",
    },
    backward: {
      position: [0, fontSize, -(axeLength - fontSize * 1.1)],
      rotation: new Euler(0, PI / 2, 0),
      ref: textBack,
      color: "limegreen",
      label: "Back",
    },
    left: {
      position: [axeLength - fontSize * 1.6, fontSize, 0],
      rotation: new Euler(0, 0, 0),
      ref: textLeft,
      color: "orange",
      label: "Left",
    },
  }).current;

  useFrame(({ camera }) => {
    if (camera.position.x < 0) {
      texts.backward.ref.current.parent.scale.set(1, 1, -1);
    } else {
      texts.backward.ref.current.parent.scale.set(1, 1, 1);
    }
    if (camera.position.z < 0) {
      texts.top.ref.current.parent.scale.set(-1, 1, 1);
      texts.top.ref.current.parent.position.x = -texts.top.position[0];
      texts.left.ref.current.parent.scale.set(-1, 1, 1);
    } else {
      texts.top.ref.current.parent.scale.set(1, 1, 1);
      texts.top.ref.current.parent.position.x = texts.top.position[0];
      texts.left.ref.current.parent.scale.set(1, 1, 1);
    }
  });

  return (
    <group name="axesHelpers">
      <group
        rotation={[-PI / 2, 0, 0]}
        scale={[1, 1, axeLengthTop / axeLength]}
        name={"axes"}
      >
        <axesHelper
          args={[axeLength, axeLengthTop, axeLength]}
          castShadow={false}
          receiveShadow={false}
        />
      </group>
      {Object.entries(texts).map(
        ([key, { scale, position, ...text }], index) => (
          <group
            {...{ scale, position }}
            key={key}
            name={"text" + capitalize(key)}
          >
            <Text {...text} fontSize={fontSize}>
              {text.label}
            </Text>
          </group>
        )
      )}
    </group>
  );
};

export default R3fAxesHelpers;
