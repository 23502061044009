import { useRef, useEffect } from "react";

/**
 * @param {function} callback The callback to run after each delay time.
 * @param {number|null} delay The delay of setInterval. If null, it clear automaticly the setInterval
 * @param {boolean} firstCall If is true, call the first back at 0 time. Then, the first callback is called after delay
 */
const useInterval = (callback = () => {}, delay = null, firstCall) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    const tick = async () => {
      if (typeof savedCallback?.current !== "undefined") {
        return savedCallback?.current();
      }
    };
    if (delay !== null) {
      if (firstCall) {
        tick();
      }
      const id = setInterval(() => {
        tick();
      }, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay, firstCall]);
};

export default useInterval;
