import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import PoiCoordinates from "components/coordinates/PoisCoordinates";
import InputText from "components/inputs/InputText";
import LinkTextButton from "components/buttons/LinkTextButton";
import { useModal } from "context/ModalProvider";
import { countPoiUsingInStitches } from "utils/garment";

const PoiCard = ({
  id,
  name,
  open,
  setOpen,
  highlightedPoiId,
  setHighlightedPoiId,
}) => {
  const { t } = useTranslation();
  const {
    changeEditedPiece,
    selectedDxfPiece,
    updatePoiName,
    deletePoiWithLinkedStitches,
    editedGarment,
    getHasPieceError,
    getPieceError,
  } = useCurrentGarmentContext();
  const { showModal } = useModal();

  const poi = useMemo(() => {
    return selectedDxfPiece.pois.find((poi) => poi.id === id);
  }, [id, selectedDxfPiece.pois]);

  const hasError = getHasPieceError(selectedDxfPiece.id, id);
  const errorName = getPieceError("name", id);

  const handleRemovePoi = () => {
    const usingCount = countPoiUsingInStitches(editedGarment, id);
    if (usingCount > 0) {
      showModal("warning", {
        titleKey: "poiRemoveTitle",
        messageKey: "stitchedPoiRemoveMsg",
        messageDynamicValues: { count: usingCount },
        onValidate: () => {
          setOpen(null);
          setHighlightedPoiId(null);
          deletePoiWithLinkedStitches(id);
        },
        validateKey: "removeAnyway",
        onCancel: () => {},
        isDestructive: true,
      });
    } else {
      showModal("warning", {
        titleKey: "poiRemoveTitle",
        messageKey: "poiRemoveMsg",
        onValidate: () => {
          setOpen(null);
          setHighlightedPoiId(null);
          changeEditedPiece({
            ...selectedDxfPiece,
            pois: selectedDxfPiece.pois.filter((poi) => poi.id !== id),
          });
        },
        onCancel: () => {},
        isDestructive: true,
      });
    }
  };

  const handleRenamePoi = (e) => {
    const newValue = e.target.value;
    updatePoiName(id, newValue);
  };

  return (
    <div
      onMouseMove={() => {
        highlightedPoiId !== id && setHighlightedPoiId(id);
      }}
      onMouseLeave={() => {
        setHighlightedPoiId(null);
      }}
      className={`max-w-full text-sm hover:shadow rounded-md border border-gray-200 hover:border-blue-avumi-100 ${
        hasError && "border-red-500 hover:border-red-500"
      }`}
      aria-expanded={open}
    >
      <div
        className="flex flex-row flex-1 px-5 py-3 items-center cursor-pointer "
        onClick={() => setOpen((cur) => (cur === id ? null : id))}
      >
        <div className="truncate flex-1">
          <div className="font-medium text-gray-800">{name}</div>
        </div>
        <div className="whitespace-nowrap">
          <div className="flex items-center">
            <div
              className={`text-gray-400 transform ${open && "rotate-180"}`}
              aria-controls={`description-${name}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* Piece name input */}
      <div id={`description-${id}`} className={`${!open && "hidden"}`}>
        <div colSpan="10" className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="flex flex-col bg-white p-1 -mt-3 gap-3">
            <InputText
              id={id}
              title={t("piecesPoiType")}
              value={name}
              onChange={handleRenamePoi}
              inputClassName="w-0 min-w-72"
              error={t(errorName?.errorKeyText)}
            />
            <PoiCoordinates point={poi.point} poiId={id} />
            <LinkTextButton
              onClick={handleRemovePoi}
              label={t("delete")}
              noPadding
              className={"ml-auto mr-2 text-red-500 hover:text-red-700"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoiCard;
