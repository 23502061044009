import { useR3fContext } from "context/R3fProvider";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import { PiPencilSimpleBold, PiTrashBold } from "react-icons/pi";

const StitchCard = ({ stitch }) => {
  const { highlightedStitchId, setHighlightedStitchId } = useR3fContext();
  const { handleDeleteStitch, handleEditStitch, editedStitchId, editedStitch } =
    useCurrentGarmentContext();

  return (
    <div
      onMouseEnter={() =>
        highlightedStitchId !== stitch.id && setHighlightedStitchId(stitch.id)
      }
      onMouseLeave={() => highlightedStitchId && setHighlightedStitchId(null)}
      className={`max-w-full p-3 text-sm hover:shadow rounded-md border border-gray-200 hover:border-blue-avumi-100 ${
        highlightedStitchId === stitch.id &&
        "border-blue-avumi-300 hover:border-blue-avumi-300"
      } ${editedStitchId && editedStitchId !== stitch.id && "opacity-20"}`}
    >
      {/* Pieces name */}
      <div className="flex flex-row flex-1 mb-3">
        <div className="truncate flex-1">
          <div className="font-medium text-gray-800">
            {editedStitchId === stitch.id
              ? editedStitch?.piece1.blender_object_name || "..."
              : stitch.piece1.blender_object_name}
          </div>
        </div>
        <div className="truncate flex-1">
          <div className="font-medium text-gray-800 text-right">
            {editedStitchId === stitch.id
              ? editedStitch?.piece2.blender_object_name || "..."
              : stitch.piece2.blender_object_name}
          </div>
        </div>
      </div>
      {/* POIs */}
      <div className="relative grid grid-cols-2 gap-y-3 gap-x-[50px] w-full text-xs font-semibold text-gray-400 uppercase">
        <div className="break-all">
          {editedStitchId === stitch.id
            ? editedStitch?.piece1.poi_start || "..."
            : stitch.piece1.poi_start}
        </div>
        <div className="break-all text-right">
          {editedStitchId === stitch.id
            ? editedStitch?.piece2.poi_start || "..."
            : stitch.piece2.poi_start}
        </div>
        <div className="break-all">
          {editedStitchId === stitch.id
            ? editedStitch?.piece1.poi_end || "..."
            : stitch.piece1.poi_end}
        </div>
        <div className="break-all text-right">
          {editedStitchId === stitch.id
            ? editedStitch?.piece2.poi_end || "..."
            : stitch.piece2.poi_end}
        </div>
        <svg
          className={`absolute top-0 left-1/2 transform -translate-x-1/2 h-full max-h-[50px] w-10`}
          style={{ color: stitch.color }}
          viewBox="0 0 40 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2,5 H35 L0,40 H35 "
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            strokeDasharray="5,7"
          />
          <path
            d="M35,40 l-7,7 l7,-7 l-7,-7"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </div>
      {/* Buttons */}
      <div className="flex gap-2 mt-3 justify-end">
        <button
          className="p-1.5 shrink-0 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm"
          onClick={() => handleEditStitch(stitch.id)}
        >
          <PiPencilSimpleBold className="w-5 h-5 text-gray-500" />
        </button>
        <button
          className="p-1.5 shrink-0 rousnded bg-white border border-gray-200 hover:border-gray-300 shadow-sm"
          onClick={() => handleDeleteStitch(stitch.id)}
        >
          <PiTrashBold className="w-5 h-5 text-red-500" />
        </button>
      </div>
    </div>
  );
};

export default StitchCard;
