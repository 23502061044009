import React, {
  useRef,
  useEffect,
  cloneElement,
  memo,
  isValidElement,
} from "react";
import Transition from "utils/Transition.js";

const Dropdown = ({
  dropdownOpen,
  setDropdownOpen,
  onClickOutside,
  align,
  buttonComponent,
  customClassName,
  children,
  fullWidth,
}) => {
  const trigger = useRef(null);
  const dropdown = useRef(null);

  if (!onClickOutside) onClickOutside = () => setDropdownOpen(false); // Default behaviors

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      ) {
        return;
      } else {
        onClickOutside();
      }
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [dropdownOpen, onClickOutside]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      onClickOutside();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [dropdownOpen, onClickOutside]);

  return (
    <div className={`relative inline-flex max-w-full ${fullWidth && "w-full"}`}>
      <div className="w-full" ref={trigger}>
        {isValidElement(buttonComponent) ? (
          cloneElement(buttonComponent, {
            "aria-haspopup": true,
            "aria-expanded": dropdownOpen,
          })
        ) : (
          <></>
        )}
      </div>

      <Transition
        show={dropdownOpen}
        tag="div"
        className={`origin-top-right z-10 absolute top-full min-w-56 bg-white border border-gray-200 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        } ${customClassName ? customClassName : ""}`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => {
            onClickOutside();
          }}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
};

export default memo(Dropdown);
