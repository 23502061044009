import { useModal } from "context/ModalProvider";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  getNewDxfPiecesWithModifiedPiece,
  getFilteredPieces,
  getNewStitchesWithModifiedPoiName,
  createNew3dPiece,
  getP3dAndStitchesWithModifiedTypeAndBlenderObjName,
} from "utils/garment";

const useGarmentPieces = (
  editedGarment,
  setValues,
  changeEditedGarment,
  errors
) => {
  const { showModal } = useModal();
  const { t } = useTranslation();

  const [selectedDxfPieceId, setSelectedDxfPieceId] = useState();
  const [piecesFilter, setPiecesFilter] = useState("all");

  const [editedCoordPoiId, setEditedCoordPoiId] = useState(null);

  const selectedDxfPiece =
    editedGarment?.dxf_pieces.find((p) => p.id === selectedDxfPieceId) || {};

  useEffect(() => {
    if (editedGarment) {
      setSelectedDxfPieceId((cur) => {
        const foundDxfPieceIndex = editedGarment.dxf_pieces.findIndex(
          (p) => p.id === cur
        );
        const firstUsedPieceIndex = editedGarment.dxf_pieces.findIndex(
          (p) => p.type !== null
        );
        return editedGarment.dxf_pieces[
          cur && foundDxfPieceIndex > -1
            ? foundDxfPieceIndex
            : firstUsedPieceIndex
        ].id;
      });
    }
  }, [editedGarment, selectedDxfPieceId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setEditedCoordPoiId(null);
        toast.dismiss("edit-mode-toast");
        // Remove all poi not fully created (coordinates not set)
        const selectedDxfPiece = editedGarment?.dxf_pieces.find(
          (p) => p.id === selectedDxfPieceId
        );
        changeEditedPiece({
          pois: selectedDxfPiece.pois.filter((poi) => {
            return poi.point[0] !== null && poi.point[1] !== null;
          }),
        });
      }
    };
    if (editedCoordPoiId) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [editedCoordPoiId]);

  /* ******* METHODS ******* */

  const updatePoiName = useCallback(
    (poiId, newValue) => {
      // Change name of selected poi
      const newPieces = getNewDxfPiecesWithModifiedPiece(
        editedGarment,
        selectedDxfPieceId,
        {
          pois: selectedDxfPiece.pois.map((poi) => {
            if (poi.id === poiId) {
              return {
                ...poi,
                name: newValue.toUpperCase().replace(/\s/g, ""),
              };
            } else return poi;
          }),
        }
      );

      // Change all poi_start and poi_end name which are the same poi id (poiStartId and poiEndId)
      const newStitches = getNewStitchesWithModifiedPoiName(
        editedGarment,
        poiId,
        newValue
      );

      setValues({
        ...editedGarment,
        dxf_pieces: newPieces,
        stitches: newStitches,
      });
    },
    [editedGarment, setValues, selectedDxfPiece, selectedDxfPieceId]
  );

  const deletePoiWithLinkedStitches = useCallback(
    (poiId) => {
      // Delete poi in dxf pieces
      const newPieces = getNewDxfPiecesWithModifiedPiece(
        editedGarment,
        selectedDxfPieceId,
        {
          pois: selectedDxfPiece.pois.filter((poi) => poi.id !== poiId),
        }
      );

      // Delete all stitchings with deleted poi id
      const newStitches = editedGarment.stitches.filter((stitch) => {
        return (
          stitch.piece1.poiStartId !== poiId &&
          stitch.piece2.poiStartId !== poiId &&
          stitch.piece1.poiEndId !== poiId &&
          stitch.piece2.poiEndId !== poiId
        );
      });

      setValues({
        ...editedGarment,
        dxf_pieces: newPieces,
        stitches: newStitches,
      });
    },
    [editedGarment, setValues, selectedDxfPiece, selectedDxfPieceId]
  );

  const changeEditedPiece = useCallback(
    (newValues) => {
      const newPieces = getNewDxfPiecesWithModifiedPiece(
        editedGarment,
        selectedDxfPieceId,
        newValues
      );
      setValues({ ...editedGarment, dxf_pieces: newPieces });
    },
    [selectedDxfPieceId, editedGarment, setValues]
  );

  const changeEditedPieceType = useCallback(
    (newType) => {
      // Update type of selected dxf piece
      const newDxfPieces = getNewDxfPiecesWithModifiedPiece(
        editedGarment,
        selectedDxfPiece.id,
        { type: newType }
      );
      // Update type of all 3d_pieces AND stitches with modified type and blender_object_name
      const new3dPiecesAndStitches =
        getP3dAndStitchesWithModifiedTypeAndBlenderObjName(
          editedGarment,
          selectedDxfPiece.id,
          newType
        );

      if (newType === null && selectedDxfPiece.type !== null) {
        // Delete 3d_pieces and stitches if user switch from other type to null (unused)
        const existingPiece3dLinked = editedGarment["3d_pieces"].filter(
          (p3d) => p3d.dxfPieceId === selectedDxfPiece.id
        );
        const existingStitchesLinked = editedGarment.stitches.filter((stitch) =>
          existingPiece3dLinked.some(
            (p3d) =>
              p3d.id === stitch.piece1.piece3dId ||
              p3d.id === stitch.piece2.piece3dId
          )
        );
        if (existingPiece3dLinked.length || existingStitchesLinked.length) {
          showModal("warning", {
            titleKey: "dxfPieceSwitchToUnusedTitle",
            messageKey: "dxfPieceSwitchToUnusedMsg",
            messageDynamicValues: {
              pieces3dLength: existingPiece3dLinked.length,
              stitchesLength: existingStitchesLinked.length,
            },
            onValidate: () => {
              new3dPiecesAndStitches["3d_pieces"] = new3dPiecesAndStitches[
                "3d_pieces"
              ].filter(
                (p3d) =>
                  !existingPiece3dLinked.some((exP3d) => exP3d.id === p3d.id)
              );
              new3dPiecesAndStitches.stitches =
                new3dPiecesAndStitches.stitches.filter(
                  (stitch) =>
                    !existingStitchesLinked.some(
                      (exStitch) => exStitch.id === stitch.id
                    )
                );
              setValues({
                ...editedGarment,
                dxf_pieces: newDxfPieces,
                ...new3dPiecesAndStitches,
              });
            },
            validateKey: "confirm",
            onCancel: () => {},
            isDestructive: true,
          });
          return; // Handle action depending of user choice
        }
      } else if (newType !== null && selectedDxfPiece.type === null) {
        // Handle create 3d_pieces if user switch from null (unused) to other type
        const newPiece3d = createNew3dPiece(
          { ...selectedDxfPiece, type: newType },
          editedGarment,
          []
        );
        new3dPiecesAndStitches["3d_pieces"].push(newPiece3d);
      }

      setValues({
        ...editedGarment,
        dxf_pieces: newDxfPieces,
        ...new3dPiecesAndStitches,
      });
    },
    [selectedDxfPiece, editedGarment, setValues]
  );

  const createNew3dPieceAndIncreaseQty = useCallback(() => {
    // Get type
    const type = selectedDxfPiece.type;
    if (type === null) {
      toast.error(t("dxfPieceQtyChangeImpossibleForUnused"), {
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    // Get current quantity of dxf_piece with same type
    const currentQty = selectedDxfPiece.quantity;

    // Get the piece type to find all the 3dPieces with the same type
    const pieces3dWithSameType = editedGarment["3d_pieces"].filter(
      (p) => p.type === selectedDxfPiece.type
    );

    // Generate new 3d piece with same type
    const new3dPiece = createNew3dPiece(
      selectedDxfPiece,
      editedGarment,
      pieces3dWithSameType
    );

    setValues({
      ...editedGarment,
      dxf_pieces: editedGarment.dxf_pieces.map((p) => {
        if (p.id === selectedDxfPieceId) {
          return { ...p, quantity: currentQty ? currentQty + 1 : 1 };
        } else return p;
      }),
      "3d_pieces": [...editedGarment["3d_pieces"], new3dPiece],
    });

    toast.success(t("dxfPieceIncrementation", { type }), {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  }, [editedGarment, selectedDxfPiece, selectedDxfPieceId, setValues]);

  const delete3dPieceAndDecreaseQty = useCallback(() => {
    // Get type
    const type = selectedDxfPiece.type;
    if (type === null) {
      toast.error(t("dxfPieceQtyChangeImpossibleForUnused"), {
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    // Get current quantity of dxf_piece with same type
    const currentQty = selectedDxfPiece.quantity;

    // Get the piece type to find all the 3dPieces with the same type
    const pieces3dWithSameType = editedGarment["3d_pieces"].filter(
      (p) => p.type === selectedDxfPiece.type
    );

    // Check if one of 3d piece with this type isn't used in stitching
    const notStitched3dPiecesWithSameType = pieces3dWithSameType.filter((p3d) =>
      editedGarment.stitches.every((stitch) => {
        return (
          stitch.piece1.piece3dId !== p3d.id &&
          stitch.piece2.piece3dId !== p3d.id
        );
      })
    );
    if (notStitched3dPiecesWithSameType.length === 0) {
      // All 3d pieces are stitched
      showModal("warning", {
        titleKey: "remove3dPiecesStitchedTitle",
        messageKey: "remove3dPiecesStitchedMsg",
      });
      return;
    } else {
      setValues({
        ...editedGarment,
        dxf_pieces: editedGarment.dxf_pieces.map((p) => {
          if (p.id === selectedDxfPieceId) {
            return { ...p, quantity: currentQty - 1 };
          } else return p;
        }),
        "3d_pieces": [
          ...editedGarment["3d_pieces"].filter(
            (p3d) =>
              p3d.id !==
              notStitched3dPiecesWithSameType[
                notStitched3dPiecesWithSameType.length - 1
              ].id
          ),
        ],
      });

      toast.success(t("dxfPieceDecrementing", { type }), {
        theme: "colored",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [editedGarment, selectedDxfPiece.type, selectedDxfPieceId, setValues]);

  const switchToEditPoiCoordMode = useCallback((poiId, options) => {
    setEditedCoordPoiId(poiId);
    toast.dismiss("edit-mode-toast");
    if (poiId) {
      toast(
        t(
          options?.mode === "creation"
            ? "cancelPoiCreationWithEsc"
            : "closeToastWithEsc"
        ),
        {
          toastId: "edit-mode-toast",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        }
      );
    }
  }, []);

  const handleMarkControlledPiece = useCallback(
    (controlled) => {
      if (controlled) {
        // Check if there is no error
        if (errors.pieces.some((e) => e.dxfPieceId === selectedDxfPieceId)) {
          showModal("warning", {
            titleKey: "pieceTypeValidationWithErrorTitle",
            messageKey: "pieceTypeValidationWithErrorMsg",
          });
          return;
        }
        // Check if piece is selected
        if (!selectedDxfPieceId) {
          console.error(">>> validatePiece: no piece selected");
          return;
        }
      }

      const newPieces = getNewDxfPiecesWithModifiedPiece(
        editedGarment,
        selectedDxfPieceId,
        {
          controlled,
        }
      );
      changeEditedGarment({ dxf_pieces: newPieces });
    },

    [editedGarment, selectedDxfPieceId, changeEditedGarment, showModal, errors]
  );

  const getPieceError = useCallback(
    (fieldKey, poiId) => {
      return errors?.pieces?.find(
        (e) =>
          e.dxfPieceId === selectedDxfPieceId &&
          e.poiId === poiId &&
          e.fieldKey === fieldKey
      );
    },
    [errors, selectedDxfPieceId]
  );

  const getHasPieceError = useCallback(
    (pieceId, poiId) => {
      if (isNaN(pieceId) && !poiId) {
        return errors?.pieces?.length > 0;
      } else {
        if (poiId) {
          return errors?.pieces?.some(
            (e) => e.dxfPieceId === pieceId && e.poiId === poiId
          );
        } else {
          return errors?.pieces?.some((e) => e.dxfPieceId === pieceId);
        }
      }
    },
    [errors]
  );

  return {
    selectedDxfPiece,
    setSelectedDxfPieceId,
    piecesFilter,
    setPiecesFilter,
    changeEditedPiece,
    changeEditedPieceType,
    updatePoiName,
    deletePoiWithLinkedStitches,
    handleMarkControlledPiece,
    createNew3dPieceAndIncreaseQty,
    delete3dPieceAndDecreaseQty,
    dxf_pieces: {
      all: getFilteredPieces(
        editedGarment?.dxf_pieces,
        "all",
        getHasPieceError
      ),
      uncontrolled: getFilteredPieces(
        editedGarment?.dxf_pieces,
        "uncontrolled",
        getHasPieceError
      ),
      controlled: getFilteredPieces(
        editedGarment?.dxf_pieces,
        "controlled",
        getHasPieceError
      ),
      error: getFilteredPieces(
        editedGarment?.dxf_pieces,
        "error",
        getHasPieceError
      ),
      valid: getFilteredPieces(
        editedGarment?.dxf_pieces,
        "valid",
        getHasPieceError
      ),
    },
    switchToEditPoiCoordMode,
    editedCoordPoiId,
    setEditedCoordPoiId,
    getPieceError,
    getHasPieceError,
  };
};

export default useGarmentPieces;
