import SpinnerAnimatedSvg from "components/SpinnerAnimatedSvg";
import colorVariantsClass from "./colorVariantsButtons";
import useNavigateTo from "./useNavigateTo";

const BigButton = ({
  label,
  colorVariant = "lightBlue",
  onClick: onClickHandler,
  to,
  replace,
  isLoading,
  isFullWidth,
  ...rest
}) => {
  const onClick = useNavigateTo(to, onClickHandler, replace);

  return (
    <button
      className={`btn relative ${colorVariantsClass[colorVariant]} ${
        isLoading ? "border-current" : ""
      } ${
        isFullWidth ? "w-full" : ""
      } disabled:pointer-events-none disabled:opacity-50 truncate`}
      {...{ onClick }}
      {...rest}
    >
      {isLoading && (
        <div className="absolute bg-gray-200 bg-opacity-50 inset-0 flex justify-center items-center">
          <SpinnerAnimatedSvg white={colorVariant !== "white"} />
        </div>
      )}
      {label}
    </button>
  );
};

export default BigButton;
