import React from "react";
import GarmentPagesWrapper from "layouts/contentPage/GarmentPagesWrapper";
import GarmentPageHeader from "layouts/headers/GarmentPageHeader";
import Accordion from "components/accordion/Accordion";
import TextureContent from "layouts/texture/TextureContent";
import { useTranslation } from "react-i18next";
import LogoContent from "layouts/texture/LogoContent";
import LinkTextButton from "components/buttons/LinkTextButton";
import { FiPlus } from "react-icons/fi";
import BigButton from "components/buttons/BigButton";
import FileUploader from "components/fileUploader/FileUploader";
import ImagePreview from "components/imagePreview/ImagePreview";
import { unflattenObject } from "utils/utils";
import TextureContextProvider, {
  useTextureContext,
} from "context/TexturesProvider";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";

const GarmentTexture = () => {
  return (
    <TextureContextProvider>
      <GarmentPagesWrapper fullContentHeightOnlyDesktop>
        <GarmentPageHeader type="texture" />
        <div className="bg-white shadow-lg rounded-sm mb-8 flex flex-grow overflow-hidden">
          <div className="flex overflow-hidden flex-col w-full lg:flex-row md:-mr-px">
            <LeftTextureSelector />
            <TexturesCenter />
            <TextureRightPanel />
          </div>
        </div>
      </GarmentPagesWrapper>
    </TextureContextProvider>
  );
};

export default GarmentTexture;

const LeftTextureSelector = () => {
  const { t } = useTranslation();
  const { errors } = useCurrentGarmentContext();

  const {
    selectedColorVariantId,
    colorVariants,
    handleSetSelectedColorVariantId,
    isCreatingColorVariant,
    isGarmentLoading,
    isGarmentFetching,
    isError,
    setIsCreatingColorVariant,
    isCreateLoading,
  } = useTextureContext();

  return (
    <div className="no-scrollbar overflow-y-auto px-3 py-6 border-b lg:border-b-0 lg:border-r border-gray-200 lg:max-w-[225px]">
      <section>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-5 ml-3">
          {t("colorVariantSectionTitle")}
        </div>
        <ul className="flex flex-col gap-2">
          {colorVariants.map((colorVariant) => (
            <li key={colorVariant.id}>
              <ListButton
                title={colorVariant.id}
                error={errors.textures?.find(
                  (e) => e.colorVariantId === colorVariant.id
                )}
                active={selectedColorVariantId === colorVariant.id}
                onClick={() => {
                  handleSetSelectedColorVariantId(colorVariant.id);
                }}
              />
            </li>
          ))}
          {isCreatingColorVariant && !isCreateLoading && (
            <li>
              <ListButton
                title={t("newColorVariant")}
                active={selectedColorVariantId === "new"}
                onClick={() => {
                  handleSetSelectedColorVariantId("new");
                }}
              />
            </li>
          )}
          {(isGarmentLoading || isGarmentFetching) && (
            <li>
              <p className="text-sm text-gray-500 px-3 py-2">{t("loading")}</p>
            </li>
          )}
          {isError && (
            <li>
              <p className="text-sm text-gray-500 px-3 py-2">
                {t("defaultErrorRequestMsg")}
              </p>
            </li>
          )}
        </ul>
        {!isCreatingColorVariant && (
          <div className="flex justify-center">
            <LinkTextButton
              label={t("addColorVariant")}
              Icon={FiPlus}
              onClick={() => {
                handleSetSelectedColorVariantId("new");
                setIsCreatingColorVariant(true);
              }}
            />
          </div>
        )}
      </section>
    </div>
  );
};

const TexturesCenter = () => {
  const { t } = useTranslation();
  const { errors: currentGarmentErrors } = useCurrentGarmentContext();

  const {
    selectedColorVariantId,
    textureOpenIndex,
    selectedColorVariant,
    values,
    piecesOptions,
    selectedPieces,
    setFieldValue,
    handleRemoveTexture,
    errors,
    handleAddTexture,
    logoOpenIndex,
    logoPositions,
    selectedPositions,
    handleRemoveLogo,
    handleAddLogo,
    isCreatingColorVariant,
  } = useTextureContext();

  const error = currentGarmentErrors?.textures?.find(
    (e) => e.colorVariantId === selectedColorVariantId
  );

  return (
    <div className="no-scrollbar overflow-y-auto flex-1 p-4">
      {Boolean(selectedColorVariantId) && (
        <div key={selectedColorVariantId}>
          <section className="mb-6">
            <h2 className="text-2xl text-gray-800 font-bold mb-3">
              {t("textureSectionTitle")}
            </h2>
            {error && (
              <p className="mb-4 -mt-2 text-sm text-red-500">
                {t(error.errorKeyText)}
              </p>
            )}
            <Accordion
              openIndex={textureOpenIndex}
              items={
                selectedColorVariant
                  ? selectedColorVariant.textures.map((texture, index) => ({
                      title: `${t("texture")} #${index}`,
                      content: (
                        <TextureContent texture={texture} isReadonly={true} />
                      ),
                    }))
                  : values.textures.map((texture, index) => ({
                      title: `${t("texture")} #${index}`,
                      content: (
                        <TextureContent
                          index={index}
                          texture={texture}
                          piecesOptions={piecesOptions}
                          selectedPieces={selectedPieces}
                          setValue={(key, newValue) =>
                            setFieldValue(
                              "textures",
                              values?.textures.map((texture, textureIndex) => {
                                if (textureIndex === index)
                                  return {
                                    ...texture,
                                    [key]: newValue,
                                  };
                                return texture;
                              })
                            )
                          }
                          onRemove={handleRemoveTexture}
                          errors={unflattenObject(errors)?.textures?.[index]}
                        />
                      ),
                      error: Object.keys(errors).some((key) =>
                        key.includes(`textures[${index}]`)
                      ),
                    }))
              }
            />
            {selectedColorVariantId === "new" &&
              selectedPieces.length - 1 < piecesOptions.length && (
                <>
                  {!Boolean(values.textures.length) && (
                    <div className="flex w-full h-full items-center justify-center">
                      <p className="text-sm text-gray-500 p-4 text-center">
                        {t("noTextureAdded")}
                      </p>
                    </div>
                  )}
                  <div className="flex justify-center mt-6 pt-2 border-t border-gray-200">
                    <LinkTextButton
                      type="button"
                      label={t("addTexture")}
                      Icon={FiPlus}
                      onClick={handleAddTexture}
                    />
                  </div>
                </>
              )}
          </section>
          <section>
            <h2 className="text-2xl text-gray-800 font-bold mb-5">
              {t("logoSectionTitle")}
            </h2>
            {Boolean(
              isCreatingColorVariant &&
                selectedColorVariant &&
                selectedColorVariant.logos.length === 0
            ) && (
              <div className="flex w-full h-full items-center justify-center">
                <p className="text-sm text-gray-500 p-4 text-center">
                  {t("noLogoSelected")}
                </p>
              </div>
            )}
            <Accordion
              openIndex={logoOpenIndex}
              items={
                selectedColorVariant
                  ? selectedColorVariant.logos.map((logo, index) => ({
                      title: `${t("logo")} #${index}`,
                      content: <LogoContent logo={logo} isReadonly={true} />,
                    }))
                  : values.logos.map((logo, index) => ({
                      title: `${t("logo")} #${index}`,
                      content: (
                        <LogoContent
                          index={index}
                          logo={logo}
                          logoPositions={logoPositions}
                          selectedPositions={selectedPositions}
                          setValue={(key, newValue) =>
                            setFieldValue(
                              "logos",
                              values?.logos.map((logo, logoIndex) => {
                                if (logoIndex === index)
                                  return {
                                    ...logo,
                                    [key]: newValue,
                                  };
                                return logo;
                              })
                            )
                          }
                          onRemove={handleRemoveLogo}
                          errors={unflattenObject(errors)?.logos?.[index]}
                        />
                      ),
                      error: Object.keys(errors).some((key) =>
                        key.includes(`logos[${index}]`)
                      ),
                    }))
              }
            />
            {logoPositions.length > values.logos.length && (
              <>
                {!Boolean(values.logos.length) && (
                  <div className="flex w-full h-full items-center justify-center">
                    <p className="text-sm text-gray-500 p-4 text-center">
                      {t("noLogoAdded")}
                    </p>
                  </div>
                )}
                {isCreatingColorVariant && (
                  <div className="flex justify-center mt-6 pt-2 border-t border-gray-200">
                    <LinkTextButton
                      type="button"
                      label={t("addLogo")}
                      Icon={FiPlus}
                      onClick={handleAddLogo}
                    />
                  </div>
                )}
              </>
            )}
          </section>
        </div>
      )}
    </div>
  );
};

const TextureRightPanel = () => {
  const { t } = useTranslation();

  const {
    selectedColorVariantId,
    selectedColorVariant,
    values,
    setFieldValue,
    isDeleteLoading,
    handleDeleteColorVariant,
    isCreateLoading,
    handleSubmit,
    cancelTextureCreation,
  } = useTextureContext();

  return (
    <div className="overflow-x-scroll no-scrollbar p-4 lg:w-[220px] border-t lg:border-t-0 lg:border-l border-gray-200">
      {Boolean(selectedColorVariant) && (
        <section className="flex flex-col justify-between h-full">
          <div className="mb-4">
            <h2 className="text-lg text-gray-800 font-bold mb-4">
              {t("texturePreviewSectionTitle")}
            </h2>
            <ImagePreview src={selectedColorVariant?.preview_url} />
          </div>
          <BigButton
            type="button"
            label={t("delete")}
            colorVariant="red"
            isFullWidth
            isLoading={isDeleteLoading}
            disabled={isDeleteLoading}
            onClick={() => handleDeleteColorVariant(selectedColorVariantId)}
          />
        </section>
      )}
      {selectedColorVariantId === "new" && (
        <section className="flex flex-col justify-between h-full">
          <div>
            <h2 className="text-lg text-gray-800 font-bold mb-6">
              {t("texturePreviewSectionTitle")}
            </h2>
            <FileUploader
              garmentType="garmentTextures"
              value={values.preview_path}
              onChange={(value) => setFieldValue("preview_path", value)}
            />
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <BigButton
              type="button"
              label={t("create")}
              colorVariant="blue"
              isFullWidth
              isLoading={isCreateLoading}
              disabled={isCreateLoading}
              onClick={handleSubmit}
            />
            <BigButton
              type="button"
              label={t("cancel")}
              colorVariant="greyLight"
              isFullWidth
              onClick={cancelTextureCreation}
            />
          </div>
        </section>
      )}
    </div>
  );
};

const ListButton = ({ title, active, onClick, error }) => (
  <button
    title={title}
    className={`w-full lg:w-[200px] text-left text-sm ${
      error && "text-red-500"
    } px-3 py-2 rounded-md whitespace-nowrap text-ellipsis overflow-hidden${
      active ? " bg-blue-avumi-50" : ""
    }`}
    onClick={onClick}
  >
    {title}
  </button>
);
