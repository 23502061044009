import { useCallback, useEffect, useState } from "react";
import { getGarmentErrors } from "utils/validation";

const useErrors = (values) => {
  const [errors, setErrors] = useState(getGarmentErrors(values));

  useEffect(() => {
    setErrors(getGarmentErrors(values));
  }, [values]);

  const getIsValidValues = useCallback(() => {
    return Object.values(errors).every((errorArray) => errorArray.length === 0);
  }, [errors]);

  return { errors, getIsValidValues };
};

export default useErrors;
