import Accordion from "components/accordion/Accordion";
import LinkTextButton from "components/buttons/LinkTextButton";
import SmallButton from "components/buttons/SmallButton";
import PoiCoordinates from "components/coordinates/PoisCoordinates";
import SvgViewer from "components/draw/SvgViewer";
import CheckBox from "components/inputs/CheckBox";
import DropdownInput from "components/inputs/DropdownInput";
import InputNumber from "components/inputs/InputNumber";
import InputText from "components/inputs/InputText";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

const Pockets = ({ items }) => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(-1);
  const [newItem, setNewItem] = useState(undefined);
  const allItems = newItem ? [...items, newItem] : items;
  const { handleSubmit, handleReset, setFieldValue, values, errors } =
    useFormik({
      initialValues: {
        selectedPiece: "",
        type: "",
        size: 0,
        positionName: "",
        expandingPocket: false,
        pocketName: "",
        assignTexturePocket: "",
        flap: false,
        flapName: "",
        assignTextureFlap: "",
        overstitch: false,
        seamsName: "",
        assignTextureSeams: "",
        buttons: false,
        buttonName: "",
        assignTextureButton: "",
        width: 0,
        startPositionName: "",
        endPositionName: "",
      },
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: async (data) => {
        console.log(data);
      },
      // validate: yupValidation("TODO"),
    });

  const handleCancel = () => {
    setOpenIndex(-1);
    setNewItem(undefined);
    handleReset();
  };

  const handleAdd = () => {
    setOpenIndex(allItems?.length ?? -1);
    setNewItem({ isNew: true });
  };

  const handleDelete = () => {
    console.log("delete");
  };

  return (
    <div className="mt-4">
      <h2 className="text-2xl text-gray-800 font-bold mb-5">
        {t("spPocketsTitle")}
      </h2>
      <Accordion
        openIndex={openIndex}
        items={allItems.map((item) => ({
          title: `${t("spPocketTitleName")} - ${
            item?.type ? `[${item.type}] - ` : ""
          }${item?.isNew ? `[${t("spPending")}]` : "[Pocket Name]"} `,
          content: (
            <Pocket
              values={
                item?.isNew
                  ? values
                  : //TODO: map item values
                    {
                      selectedPiece: "",
                      type: item?.type,
                      size: 0,
                      positionName: "",
                      expandingPocket: false,
                      pocketName: "",
                      assignTexturePocket: "",
                      flap: false,
                      flapName: "",
                      assignTextureFlap: "",
                      overstitch: false,
                      seamsName: "",
                      assignTextureSeams: "",
                      buttons: false,
                      buttonName: "",
                      assignTextureButton: "",
                      width: 0,
                      startPositionName: "",
                      endPositionName: "",
                    }
              }
              errors={errors}
              setFieldValue={setFieldValue}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              onDelete={handleDelete}
              isNew={item?.isNew}
            />
          ),
        }))}
      />
      <div className="flex justify-center mt-2">
        {!newItem && (
          <LinkTextButton
            type="button"
            label={t("spAddPocket")}
            Icon={FiPlus}
            onClick={handleAdd}
          />
        )}
      </div>
    </div>
  );
};

export default Pockets;

const Pocket = ({
  values,
  errors,
  setFieldValue,
  isNew,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-5 border-t border-gray-200">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-grow">
          <div className="grid gap-2 lg:grid-cols-3">
            <div className="md:col-span-3">
              <DropdownInput
                title={t("texturePiecesTitle")}
                options={[]}
                getLabelOptionFromOption={(option) => option}
                selectedOption={values.selectedPiece}
                onChange={(option) => {
                  setFieldValue("selectedPiece", option);
                }}
                fullWidth
                error={
                  errors?.selectedPiece?.keyText &&
                  t(errors?.selectedPiece?.keyText)
                }
                disabled={!isNew}
                searchText={t("texturePlacesSearch")}
              />
            </div>
            <DropdownInput
              title={t("spPocketType")}
              options={["PATCH", "INSET"]}
              selectedOption={values.type}
              onChange={(option) => {
                setFieldValue("type", option);
              }}
              getLabelOptionFromOption={(option) => option}
              getLabelInputFromOption={(option) => option}
              customWidthClass="min-w-auto"
              fullWidth
              error={Boolean(errors.type) && t(errors?.type?.keyText)}
              disabled={!isNew}
            />
            {values.type === "PATCH" && (
              <>
                <InputNumber
                  label={t("spSize")}
                  type="decimal"
                  customWidthClass="auto"
                  value={values.size}
                  min={0}
                  onChange={(quantity) => {
                    setFieldValue("size", quantity);
                  }}
                  error={Boolean(errors.size) && t(errors?.size?.keyText)}
                  disabled={!isNew}
                />
                <InputText
                  title={t("spPositionName")}
                  type="text"
                  value={values.positionName}
                  onChange={(e) => {
                    setFieldValue("positionName", e.target.value);
                  }}
                  error={
                    Boolean(errors.positionName) &&
                    t(errors?.positionName?.keyText)
                  }
                  disabled={!isNew}
                />
                <div className="md:col-span-3">
                  {/* TODO: add value, onChange and disabled to Poi inputs */}
                  <PoiCoordinates
                    point={[710.274475, 1353.059814]}
                    poiId={"dxfPiece-BACK0-poi9"}
                  />
                </div>
                {/* POCKET */}
                <div className="md:col-span-3 mb-2">
                  <CheckBox
                    label={t("spExpandingPocket")}
                    disabled={!isNew}
                    checked={values.expandingPocket}
                    onChange={(e) => {
                      setFieldValue("expandingPocket", e.target.checked);
                    }}
                  />
                </div>
                {Boolean(values.expandingPocket) && (
                  <>
                    <InputText
                      title={t("spPocketName")}
                      type="text"
                      value={values.pocketName}
                      onChange={(e) => {
                        setFieldValue("pocketName", e.target.value);
                      }}
                      error={
                        Boolean(errors.pocketName) &&
                        t(errors?.pocketName?.keyText)
                      }
                      disabled={!isNew}
                    />
                    <div className="md:col-span-2">
                      <DropdownInput
                        title={t("spAssignTexturePocket")}
                        options={[]}
                        selectedOption={values.assignTexturePocket}
                        onChange={(option) => {
                          setFieldValue("assignTexturePocket", option);
                        }}
                        getLabelOptionFromOption={(option) => option}
                        getLabelInputFromOption={(option) => option}
                        customWidthClass="min-w-auto"
                        fullWidth
                        error={
                          Boolean(errors.assignTexturePocket) &&
                          t(errors?.assignTexturePocket?.keyText)
                        }
                        disabled={!isNew}
                      />
                    </div>
                  </>
                )}
                {/* #POCKET */}
                {/* FLAP */}
                <div className="md:col-span-3 mb-2">
                  <CheckBox
                    label={t("spFlap")}
                    disabled={!isNew}
                    checked={values.flap}
                    onChange={(e) => {
                      setFieldValue("flap", e.target.checked);
                    }}
                  />
                </div>
                {Boolean(values.flap) && (
                  <>
                    <InputText
                      title={t("spFlapName")}
                      type="text"
                      value={values.flapName}
                      onChange={(e) => {
                        setFieldValue("flapName", e.target.value);
                      }}
                      error={
                        Boolean(errors.flapName) && t(errors?.flapName?.keyText)
                      }
                      disabled={!isNew}
                    />
                    <div className="md:col-span-2">
                      <DropdownInput
                        title={t("spAssignTextureFlap")}
                        options={[]}
                        selectedOption={values.assignTextureFlap}
                        onChange={(option) => {
                          setFieldValue("assignTextureFlap", option);
                        }}
                        getLabelOptionFromOption={(option) => option}
                        getLabelInputFromOption={(option) => option}
                        customWidthClass="min-w-auto"
                        fullWidth
                        error={
                          Boolean(errors.assignTextureFlap) &&
                          t(errors?.assignTextureFlap?.keyText)
                        }
                        disabled={!isNew}
                      />
                    </div>
                  </>
                )}
                {/* #FLAP */}
                {/* OVERSTITCH */}
                <div className="md:col-span-3 mb-2">
                  <CheckBox
                    label={t("spOverstitch")}
                    disabled={!isNew}
                    checked={values.overstitch}
                    onChange={(e) => {
                      setFieldValue("overstitch", e.target.checked);
                    }}
                  />
                </div>
                {Boolean(values.overstitch) && (
                  <>
                    <InputText
                      title={t("spSeamsName")}
                      type="text"
                      value={values.seamsName}
                      onChange={(e) => {
                        setFieldValue("seamsName", e.target.value);
                      }}
                      error={
                        Boolean(errors.seamsName) &&
                        t(errors?.seamsName?.keyText)
                      }
                      disabled={!isNew}
                    />
                    <div className="md:col-span-2">
                      <DropdownInput
                        title={t("spAssignTextureSeams")}
                        options={[]}
                        selectedOption={values.assignTextureSeams}
                        onChange={(option) => {
                          setFieldValue("assignTextureSeams", option);
                        }}
                        getLabelOptionFromOption={(option) => option}
                        getLabelInputFromOption={(option) => option}
                        customWidthClass="min-w-auto"
                        fullWidth
                        error={
                          Boolean(errors.assignTextureSeams) &&
                          t(errors?.assignTextureSeams?.keyText)
                        }
                        disabled={!isNew}
                      />
                    </div>
                  </>
                )}
                {/* #OVERSTITCH */}
                {/* BUTTONS */}
                <div className="md:col-span-3 mb-2">
                  <CheckBox
                    label={t("spButtons")}
                    disabled={!isNew}
                    checked={values.buttons}
                    onChange={(e) => {
                      setFieldValue("buttons", e.target.checked);
                    }}
                  />
                </div>
                {Boolean(values.buttons) && (
                  <>
                    <InputText
                      title={t("spButtonName")}
                      type="text"
                      value={values.buttonName}
                      onChange={(e) => {
                        setFieldValue("buttonName", e.target.value);
                      }}
                      error={
                        Boolean(errors.buttonName) &&
                        t(errors?.buttonName?.keyText)
                      }
                      disabled={!isNew}
                    />
                    <div className="md:col-span-2">
                      <DropdownInput
                        title={t("spAssignTextureButton")}
                        options={[]}
                        selectedOption={""}
                        onChange={(option) => {
                          setFieldValue("assignTextureButton", option);
                        }}
                        getLabelOptionFromOption={(option) => option}
                        getLabelInputFromOption={(option) => option}
                        customWidthClass="min-w-auto"
                        fullWidth
                        error={
                          Boolean(errors.assignTextureButton) &&
                          t(errors?.assignTextureButton?.keyText)
                        }
                        disabled={!isNew}
                      />
                    </div>
                  </>
                )}
                {/* #BUTTONS */}
              </>
            )}
            {values.type === "INSET" && (
              <>
                <InputText
                  title={t("spPocketName")}
                  type="text"
                  value={values.pocketName}
                  onChange={(e) => {
                    setFieldValue("pocketName", e.target.value);
                  }}
                  error={
                    Boolean(errors.pocketName) && t(errors?.pocketName?.keyText)
                  }
                  disabled={!isNew}
                />
                <InputNumber
                  label={t("spWidth")}
                  type="decimal"
                  customWidthClass="auto"
                  value={values.width}
                  min={0}
                  onChange={(quantity) => {
                    setFieldValue("width", quantity);
                  }}
                  error={Boolean(errors.width) && t(errors?.width?.keyText)}
                  disabled={!isNew}
                />
                <DropdownInput
                  title={t("spAssignTexturePocket")}
                  options={[]}
                  selectedOption={values.assignTexturePocket}
                  onChange={(option) => {
                    setFieldValue("assignTexturePocket", option);
                  }}
                  getLabelOptionFromOption={(option) => option}
                  getLabelInputFromOption={(option) => option}
                  customWidthClass="min-w-auto"
                  fullWidth
                  error={
                    Boolean(errors.assignTexturePocket) &&
                    t(errors?.assignTexturePocket?.keyText)
                  }
                  disabled={!isNew}
                />
                <InputText
                  title={t("spStartPositionName")}
                  type="text"
                  value={values.startPositionName}
                  onChange={(e) => {
                    setFieldValue("startPositionName", e.target.value);
                  }}
                  error={
                    Boolean(errors.startPositionName) &&
                    t(errors?.startPositionName?.keyText)
                  }
                  disabled={!isNew}
                />
                <InputText
                  title={t("spEndPositionName")}
                  type="text"
                  value={values.endPositionName}
                  onChange={(e) => {
                    setFieldValue("endPositionName", e.target.value);
                  }}
                  error={
                    Boolean(errors.endPositionName) &&
                    t(errors?.endPositionName?.keyText)
                  }
                  disabled={!isNew}
                />
                <div className="md:col-span-3">
                  {/* TODO: add value, onChange and disabled to Poi inputs */}
                  <PoiCoordinates
                    point={[710.274475, 1353.059814]}
                    poiId={"dxfPiece-BACK0-poi9"}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <SvgViewer
            highlightedPoiId={undefined}
            setHighlightedPoiId={() => {}}
            points={[]}
            pois={[]}
          />
        </div>
      </div>
      <div
        className={`flex ${
          isNew ? "justify-between" : "justify-end"
        } mt-4 pt-4 border-t border-gray-200`}
      >
        {isNew ? (
          <>
            <SmallButton
              type="button"
              label={t("cancel")}
              colorVariant="greyLight"
              onClick={onCancel}
            />
            <SmallButton type="button" label={t("create")} onClick={onSubmit} />
          </>
        ) : (
          <SmallButton
            type="button"
            label={t("delete")}
            colorVariant="red"
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};
