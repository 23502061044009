import { NORMAL_MAP_OPTIONS } from "layouts/texture/NormalMap";
import * as Yup from "yup";

export const schemaFieldStringRequired = Yup.string().required({
  keyText: "requiredField",
});

export const schemaMail = Yup.string()
  .required({ keyText: "requiredField" })
  .email({ keyText: "badMail" });

export const schemaNewPassword = Yup.string()
  .required({ keyText: "requiredField" })
  .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/, {
    keyText: "badPasswordPolicy",
  });

export const schemaGeneral = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
});

const normalMapSchema = Yup.object()
  .shape({
    type: Yup.string().required({ keyText: "requiredField" }),
    selectedNormalMap: Yup.object().when("type", {
      is: (type) =>
        type === NORMAL_MAP_OPTIONS.LIBRARY ||
        type === NORMAL_MAP_OPTIONS.UPLOADS,
      then: (schema) => schema.required({ keyText: "requiredField" }),
    }),
    name: Yup.string().when("type", {
      is: (type) => type === NORMAL_MAP_OPTIONS.CUSTOM,
      then: (schema) => schema.required({ keyText: "requiredField" }),
    }),
    size: Yup.number().when("type", {
      is: (type) => type === NORMAL_MAP_OPTIONS.CUSTOM,
      then: (schema) =>
        schema
          .min(0.01, { keyText: "requiredField" })
          .required({ keyText: "requiredField" }),
    }),
    file_path: Yup.object().when("type", {
      is: (type) => type === NORMAL_MAP_OPTIONS.CUSTOM,
      then: (schema) => schema.required({ keyText: "requiredField" }),
    }),
  })
  .required({ keyText: "requiredField" });

/**
 * @param {schema} schema name of schema validation to process on values object
 * @returns Yup Validation schema to process on values
 */
const _getValidationSchema = (schema) => {
  switch (schema) {
    case "signin":
      return Yup.object().shape({
        email: schemaMail,
        password: Yup.string().required({ keyText: "requiredField" }),
      });
    case "signinWithNewPassword":
      return Yup.object().shape({
        email: schemaMail,
        password: Yup.string().required({ keyText: "requiredField" }),
        newPassword: schemaNewPassword,
      });

    case "colorVariants":
      return Yup.object().shape({
        preview_path: Yup.object().shape({
          fileName: Yup.string(),
          previewUrl: Yup.string(),
        }),
        textures: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required({ keyText: "requiredField" }),
            size: Yup.number().min(0.01, { keyText: "requiredField" }),
            options: Yup.array().min(1, { keyText: "requiredField" }),
            file_path: Yup.object()
              .shape({
                fileName: Yup.string(),
                previewUrl: Yup.string(),
              })
              .required({ keyText: "requiredField" }),
            normal_map: normalMapSchema,
          })
        ),
        logos: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required({ keyText: "requiredField" }),
            width: Yup.number().min(0.01, { keyText: "requiredField" }),
            height: Yup.number().min(0.01, { keyText: "requiredField" }),
            position: Yup.string().required({ keyText: "requiredField" }),
            texture: Yup.object().shape({
              file_path: Yup.object()
                .shape({
                  fileName: Yup.string(),
                  previewUrl: Yup.string(),
                })
                .required({ keyText: "requiredField" }),
              normal_map: normalMapSchema,
            }),
          })
        ),
      });

    default:
      return null;
  }
};

/**
 * @param {object} validationError Error object returns by validateSync function
 * @returns Object of errors depending of Yup schema and data send to validate function
 */
const _getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner?.reduce((errors, error) => {
    let path = error.path;
    let firstError = error.errors[FIRST_ERROR];
    if (error.path.includes("pieces")) {
      path = "pieces";
      const regex = /pieces\[(\d+)\]\./;
      const match = error.path.match(regex);
      const index = match ? parseInt(match[1]) : null;
      firstError = { keyText: "throwPiecesError", pieceIndex: index };
    }

    return {
      ...errors,
      [path]: firstError,
    };
  }, {});
};

/**
 *
 * @param {object} values Object with values which must match with the key of the object of the YUP scheme
 * @returns An empty object if no errors or the objects with errors messages (key : name of value, value : first error message of Yup validation)
 */
export const yupValidation = (schemaName) => (values) => {
  const validationSchema = _getValidationSchema(schemaName);
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    return _getErrorsFromValidationError(error);
  }
};

export const yupIsValid = (schema, value) => {
  try {
    schema.validateSync(value, { abortEarly: false });
    return true;
  } catch (error) {
    return false;
  }
};
