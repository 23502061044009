import { useState } from "react";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import PoiCard from "components/cards/PoiCard";
import LinkTextButton from "components/buttons/LinkTextButton";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

const PoiAccordions = ({ highlightedPoiId, setHighlightedPoiId }) => {
  const {
    selectedDxfPiece,
    changeEditedPiece,
    switchToEditPoiCoordMode,
    getPieceError,
  } = useCurrentGarmentContext();
  const { t } = useTranslation();

  const [accordionOpened, setAccordionOpened] = useState(null);

  const handleAddPoi = () => {
    const upperIndexId = selectedDxfPiece.pois.reduce((acc, poi) => {
      const match = poi.id.match(/\d+$/);
      if (match) {
        return Math.max(acc, parseInt(match[0]));
      } else {
        throw new Error(
          ">>> handleAddPoi : error to generate new poi uniq id. Last index not found"
        );
      }
    }, 0);

    const newId = `${selectedDxfPiece.id}-poi${upperIndexId + 1}`;
    const defaultName = `JUNCTION-${upperIndexId + 1}`;

    const newPoi = {
      name: defaultName,
      id: newId,
      point: [null, null],
    };
    changeEditedPiece({
      pois: [...selectedDxfPiece.pois, newPoi],
    });
    setAccordionOpened(newPoi.id);
    switchToEditPoiCoordMode(newPoi.id, { mode: "creation" });
  };

  const errorPois = getPieceError("pois");

  return (
    <div className="flex flex-col flex-1 gap-y-2">
      {errorPois && (
        <div className="-mt-3 mb-3">
          <p className="text-red-500 text-sm">{t(errorPois.errorKeyText)}</p>
        </div>
      )}
      {selectedDxfPiece &&
        selectedDxfPiece.pois?.map((poi, i) => {
          return (
            <PoiCard
              {...poi}
              {...{ highlightedPoiId, setHighlightedPoiId }}
              key={`poi-${i}`}
              open={accordionOpened === poi.id}
              setOpen={setAccordionOpened}
            />
          );
        })}
      <LinkTextButton
        label={t("addPoi")}
        Icon={FiPlus}
        onClick={handleAddPoi}
      />
    </div>
  );
};

export default PoiAccordions;
