import colorVariantsClass from "./colorVariantsButtons";
import useNavigateTo from "./useNavigateTo";

const RoundedIconsButton = ({
  children,
  buttonRef,
  onClick: onClickHandler,
  to,
  replace,
  colorVariant = "greyDark",
  transparent,
  className,
  ...rest
}) => {
  const onClick = useNavigateTo(to, onClickHandler, replace);

  return (
    <button
      ref={buttonRef}
      className={`w-[32px] h-[32px] rounded-full p-0 flex justify-center items-center ${
        colorVariantsClass[colorVariant]
      } ${transparent && "hover:bg-opacity-80 bg-opacity-60"} ${className}`}
      {...{ onClick }}
      {...rest}
    >
      {children}
    </button>
  );
};

export default RoundedIconsButton;
