import { useEffect, useRef, useState } from "react";
import { useCurrentGarmentContext } from "context/currentGarment/CurrentGarmentProvider";
import { useR3fContext } from "context/R3fProvider";
import { Box } from "@react-three/drei";
import { throttle } from "lodash";
import { useFrame } from "@react-three/fiber";
import { Quaternion, Vector3 } from "three";
import {
  get2dRadRotatedPoints,
  getTopPositionRadRotation2D,
} from "utils/geometry";

const R3fPois = ({ setMessage }) => {
  const { stitchingFilters, sorted3dPieces, dxf_pieces, edited3dPieces } =
    useCurrentGarmentContext();
  const { offsetCentering } = useR3fContext();

  const lastExecutionTimeRef = useRef(0);

  const [piecesConfig, setPiecesConfig] = useState();
  const [hoveredPoi, setHoveredPoi] = useState();

  useEffect(() => {
    setMessage(hoveredPoi);
  }, [hoveredPoi]);

  useEffect(() => {
    // Get all pieces type from blenderObjectNames
    const filtered3dPieces = [];
    stitchingFilters?.forEach((blenderObjectName) => {
      const foundPiece3d = sorted3dPieces.positioned.find(
        (p) => p.blender_object_name === blenderObjectName
      );
      filtered3dPieces.push(foundPiece3d);
    });
    // Get All pois coordinates
    const poisCoordinatesByPiece = filtered3dPieces.reduce((acc, p3d) => {
      if (!p3d) {
        return acc;
      }
      const foundDxfPiece = dxf_pieces?.valid.find(
        (p) => p.id === p3d?.dxfPieceId
      );
      acc[p3d.blender_object_name] = {
        pois: foundDxfPiece.pois,
        topPosition: foundDxfPiece.top_position,
        parent3dPiece: p3d,
      };
      return acc;
    }, {});
    setPiecesConfig(poisCoordinatesByPiece);
  }, [stitchingFilters, dxf_pieces?.valid, sorted3dPieces?.positioned]);

  useFrame((state, delta) => {
    const currentTime = state.clock.getElapsedTime() * 1000;

    if (
      lastExecutionTimeRef.current === 0 ||
      currentTime - lastExecutionTimeRef.current > 300
    ) {
      const { scene } = state;

      // Find pieces object in scene
      const piecesGroup = scene.getObjectByName("pieces");

      // Update the position and rotation of the pois group
      piecesGroup?.children.forEach((piece) => {
        const piecePois = scene.getObjectByName(`pois-${piece.name}`);

        if (piecePois) {
          const globalPosition = new Vector3();
          globalPosition.setFromMatrixPosition(piece.children[0].matrixWorld);
          piecePois.position.copy(globalPosition);

          // Update rotation from shape object
          const rotatedGroup = piece.children[0].children[0];
          if (rotatedGroup) {
            const initialRotation = new Quaternion();
            piece.children[0].children[0].getWorldQuaternion(initialRotation);
            piecePois.rotation.setFromQuaternion(initialRotation);
          }

          piecePois.visible = true;
        }
      });

      // Update the last execution time
      lastExecutionTimeRef.current = currentTime;
    }
  });

  return (
    <>
      {piecesConfig &&
        Object.keys(piecesConfig).map((piece3dName) => {
          const poisCoordinates = piecesConfig[piece3dName].pois;
          if (
            edited3dPieces?.length > 0 &&
            !edited3dPieces.includes(piece3dName)
          ) {
            return null;
          } else {
            return (
              <group
                key={`pois-${piece3dName}`}
                name={`pois-${piece3dName}`}
                visible={false}
              >
                {poisCoordinates.map((poi) => {
                  return (
                    <R3fPoi
                      {...{ setHoveredPoi }}
                      key={`poi-${poi.name}`}
                      position={[
                        ...get2dRadRotatedPoints(
                          (poi.point[0] -
                            (offsetCentering[piece3dName]?.x || 0)) /
                            1000,
                          (poi.point[1] -
                            (offsetCentering[piece3dName]?.y || 0)) /
                            1000,
                          getTopPositionRadRotation2D(
                            piecesConfig[piece3dName].topPosition
                          )
                        ),
                        0,
                      ]}
                      name={poi.name}
                      id={poi.id}
                      parent3dPiece={piecesConfig[piece3dName].parent3dPiece}
                    />
                  );
                })}
              </group>
            );
          }
        })}
    </>
  );
};

export default R3fPois;

const R3fPoi = ({
  position,
  color = "limeGreen",
  name,
  id,
  parent3dPiece,
  setHoveredPoi,
  ...props
}) => {
  const { editedStitchId, selectPoiForStitch } = useCurrentGarmentContext();

  const pointRef = useRef();

  useEffect(() => {
    if (pointRef.current) {
      pointRef.current.geometry.center();
    }
  }, []);

  const handleRaycastThrottled = throttle(
    (event) => {
      const nearestDistance = event.intersections.find(
        (int) =>
          int.object.visible &&
          (int.object.parent?.parent?.parent?.name === "pieces" ||
            int.object.parent?.parent?.name.includes("pois"))
      )?.distance; // Nearest distance
      const nearestObjectsUuid = event.intersections
        .filter((obj) => {
          return obj.distance === nearestDistance;
        })
        .map((obj) => obj.object.uuid);
      if (nearestObjectsUuid.includes(event.object.uuid)) {
        setHoveredPoi(`${parent3dPiece.blender_object_name}  -  ${name}`);
        document.body.style.cursor = "url('/images/mouse-needle.png'), auto";
      }
    },
    500,
    { trailing: false }
  );

  return (
    <mesh
      ref={pointRef}
      name={name}
      position={position}
      scale={0.015}
      onPointerMove={handleRaycastThrottled}
      onPointerLeave={() => {
        handleRaycastThrottled.cancel();
        setHoveredPoi(null);
        document.body.style.cursor = "auto";
      }}
      onClick={
        editedStitchId
          ? () => selectPoiForStitch(id, name, parent3dPiece)
          : undefined
      }
      {...props}
    >
      <Box args={[1, 1, 1]}>
        <meshBasicMaterial color={color} />
      </Box>
    </mesh>
  );
};
