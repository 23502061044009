import React from "react";
import GarmentPagesWrapper from "layouts/contentPage/GarmentPagesWrapper";
import GarmentPageHeader from "layouts/headers/GarmentPageHeader";
import { R3fProvider } from "context/R3fProvider";
import PreviewSideBar from "layouts/preview/PreviewSideBar";
import PreviewView from "layouts/preview/PreviewView";

const GarmentPreview = () => {
  return (
    <GarmentPagesWrapper fullContentHeight>
      <GarmentPageHeader type="preview" />
      <div className="bg-white shadow-lg rounded-sm mb-8 flex flex-grow overflow-hidden">
        <div className="flex overflow-hidden flex-col w-full md:flex-row md:-mr-px">
          <R3fProvider>
            <PreviewSideBar />
            <PreviewView />
          </R3fProvider>
        </div>
      </div>
    </GarmentPagesWrapper>
  );
};

export default GarmentPreview;
