import Accordion from "components/accordion/Accordion";
import BodyShapeIcon from "components/icons/BodyShapeIcon";
import GenderIcon from "components/icons/GenderIcon";
import { useTranslation } from "react-i18next";
import { BsCheckLg } from "react-icons/bs";

const AvatarCard = ({
  id,
  preview,
  gender,
  bodyShape,
  heightCm,
  name,
  size,
  measurements,
  isSelected,
  setSelected,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col lg:flex-row item-stretch lg:items-start gap-4 w-full p-3 text-sm hover:shadow rounded-md border ${
        isSelected ? "border-black" : "border-gray-200"
      }  md:hover:border-blue-avumi-300`}
    >
      <button
        className="relative flex items-stretch rounded-md overflow-hidden"
        onClick={() => setSelected?.(id)}
      >
        <div className="absolute flex items-center justify-center top-2 left-2 w-[22px] h-[22px] rounded-[50%] border border-gray-700 bg-white">
          {Boolean(isSelected) && <BsCheckLg className="text-md" />}
        </div>
        <img
          className="w-[140px] h-[140px] object-cover lg:block"
          src={preview}
          alt={name}
        />
        <div className="flex-1  flex flex-col justify-center gap-2 bg-gray-50 items-center p-4">
          <div className="w-[20px] h-[20px]">
            <GenderIcon type={gender} />
          </div>
          <div className="w-[30px] h-[30px]">
            <BodyShapeIcon type={bodyShape} />
          </div>
          <div>{size.join(", ")}</div>
        </div>
      </button>
      <div className="flex-1">
        <div className="text-xl font-bold mb-2">{name}</div>
        <Accordion
          items={[
            {
              title: t("preview_measurements"),
              content: (
                <ul>
                  <li className="flex items-center justify-between text-xs px-5 py-3">
                    <span>{t("height_cm")}</span>
                    <span className="font-medium">{heightCm}</span>
                  </li>
                  {Object.entries(measurements).map(([key, item]) => (
                    <li
                      key={key}
                      className="flex items-center justify-between px-5 py-3 text-xs even:bg-gray-100"
                    >
                      <span>{t(key)}</span>
                      <span className="font-medium">{item}</span>
                    </li>
                  ))}
                </ul>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AvatarCard;
