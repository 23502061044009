import { garmentStatuses } from "constants/garments";
import { BrandIcon, PartnerIcon } from "constants/icons";
import { useGarments } from "context/GarmentsProvider";
import Badge from "components/badge/Badge";

const ReviewStatusFilters = () => {
  const { filters, changeFilters } = useGarments();

  const handleChange = (key) => {
    changeFilters(
      "reviewStatuses",
      filters.reviewStatuses.includes(key)
        ? filters.reviewStatuses.filter((f) => f !== key)
        : [...filters.reviewStatuses, key]
    );
  };

  return (
    <div className="inline-flex flex-wrap">
      {garmentStatuses.map((f) => (
        <Badge
          unselected={!filters.reviewStatuses.includes(f.key)}
          key={f.key}
          label={f.label}
          colorVariant={f.color}
          onClick={() => handleChange(f.key)}
        />
      ))}
      {filters.brand && (
        <Badge
          label={filters.brand.name}
          iconComponent={BrandIcon}
          colorVariant="grey"
          onDelete={() => changeFilters("brand", null)}
        />
      )}
    </div>
  );
};

export default ReviewStatusFilters;
