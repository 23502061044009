import colorVariantsBadge from "./colorVariantsBadge";
import { MdEdit } from "react-icons/md";
import { GrClose } from "react-icons/gr";

const Badge = ({
  onDelete,
  onEdit,
  onClick,
  label,
  iconComponent,
  colorVariant,
  small,
  reduced,
  unselected,
  disabled,
  onClickDisabled = () => {},
}) => {
  const Icon = iconComponent || null;

  return (
    <div
      onClick={disabled ? onClickDisabled : onEdit || onClick}
      className={`${reduced ? "m-0" : "m-1.5"} ${
        !disabled && (onEdit || onClick) ? "cursor-pointer" : ""
      } max-w-full select-none`}
    >
      <div
        className={`inline-flex ${
          small ? "h-6 px-2" : "h-8 px-3"
        } items-center text-sm border border-box font-medium rounded-full text-center w-max max-w-full ${
          disabled
            ? colorVariantsBadge.lightGrey
            : unselected
            ? colorVariantsBadge.lightGrey
            : colorVariantsBadge[colorVariant]
        } ${onDelete && "pr-2 border-[0.5px] border-solid border-gray-400"} ${
          iconComponent && "pl-2"
        }`}
      >
        {Icon && <Icon className="mr-2" />}
        <span className="truncate">{label}</span>
        {onDelete && (
          <button className="ml-2 " onClick={onDelete}>
            <GrClose className="w-3 h-3" />
          </button>
        )}
        {onEdit && (
          <div className="ml-2">
            <MdEdit className="w-4 h-4" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Badge;
